import moment from "moment/moment";

export function ValidateCuit(cuit) {
    
    if (cuit.includes('-'))
        cuit = cuit.replaceAll('-','');
    if (cuit.length !== 11)
        return false;
        
    let base = cuit.substring(0, cuit.length - 1);
    let digit = cuit.substring(cuit.length - 1);
    let sum = 0;

    for (let i = 0; i < base.length; i++) {
        sum += parseInt(base.charAt(base.length - i - 1)) * (2 + (i % 6));
    }

    let verif = 11 - (sum % 11);
    if (verif === 11) {
        verif = 0;
    } else if (verif === 10) {
        verif = 9;
    }

    return verif.toString() === digit;
}
 
export function ValidateToken(token) {
    const regexCuit = /^[a-zA-Z0-9]{32}$/g;

    return regexCuit.test(token);
}

export function ValidateOTP(token) {
    const regexCuit = /^\d{4}$/g;

    return regexCuit.test(token);
}
 
export function ValidateFilterDate(from, to) {
    const From = from !== "" ? new Date(from) : new Date("2020-01-01");
    const To = to !== "" ? new Date(to) : new Date("2150-12-31");

    return From <= To;
}

export function ValidateDateRange(dateFrom, dateTo){
    return dateFrom.isSameOrBefore(dateTo);
};

export function ValidatePassword(password) {
    const regexPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

    let isValidFormat = regexPassword.test(password);

    return isValidFormat;
}

export function ValidatePhone(phone) {
    const phoneRegex = /^\(?\d{2}\)?[\s-]?\d{4}[\s-]?\d{4}$/;
    return phoneRegex.test(phone);
}

export function ValidateEmail(email) {
    const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return emailRegex.test(email);
}

export function ValidateEmailAlternative(email) {
    const emailRegex= /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
    return emailRegex.test(email);
}

export const FormatCuil = (value) =>{
    const formattedValue = value.replace(/^(\d{2})(\d{8})(\d)$/, '$1-$2-$3');

    return formattedValue;
}

export const Mask = (e) =>{
    let numeric = e.target.value.replace(/[^\d]/g,"");
    return FormatCuil(numeric);
}

export const meses = [
    "ENE",
    "FEB",
    "MAR",
    "ABR",
    "MAY",
    "JUN",
    "JUL",
    "AGO",
    "SEP",
    "OCT",
    "NOV",
    "DIC",
];

export const CurrentDate = moment().subtract(10, 'months');

export const Perfiles = {
    INTERNO: 'Interno',
    PRESTADOR: 'Prestador',
    AFILIADO: 'Afiliado',
    EMPLEADO: 'Empleado'
};

export const ViewItemDashboard = (user) => {
    const isAdmin = user?.c_ROL_USUARIO === 'OSBA_PERSONA_ADMIN';
    const isRRHH = user?.c_ROL_USUARIO === 'OSBA_PERSONA_RRHH';

    const hasRequiredProfiles =
    user?.t_PERFIL === Perfiles.INTERNO ||
    user?.t_PERFIL === Perfiles.PRESTADOR ||
    user?.t_PERFIL === Perfiles.AFILIADO ||
    (user?.t_PERFILES?.includes(Perfiles.INTERNO) ||
        user?.t_PERFILES?.includes(Perfiles.AFILIADO) ||
        user?.t_PERFILES?.includes(Perfiles.PRESTADOR));

    if (isRRHH) {
        if (user?.t_PERFILES.length <= 2 && !user?.t_PERFILES?.includes(Perfiles.AFILIADO)) {
            return false;
        }
    } else if (user?.t_PERFILES.length <= 1 && user?.t_PERFIL === Perfiles.EMPLEADO) {
        return false;
    }

    return hasRequiredProfiles || isAdmin;
}
