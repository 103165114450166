import React from "react";
import { Controller } from "react-hook-form";
import {
  FormControl,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import Colors from "../../theme/colors";


const InputCheckBox = (props) => {
  const { name, defaultValue, control, label, register, ...otherProps } = props;
  return (
    <Controller
      defaultValue={false}
      name={name}
      control={control}
      render={({ field, value, onChange }) => (
        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                style={{ color: Colors.blue }}
                {...field}
                {...register}
                onChange={(e,data)=>{onChange(data)}}
                {...otherProps}
                checked={value || false}
              />
            }
            label={label || ""}
          />
        </FormControl>
      )}
    />
  );
};

export default InputCheckBox;
