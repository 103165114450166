import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_OBSBA_API_URL}/Usuario_Portal/newUser2`,
  prepareHeaders: (headers, { getState }) => {
    const token = localStorage.getItem("token_app");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const RegisterApi = createApi({
  reducerPath: "RegisterApi",
  baseQuery,
  endpoints: (builder) => ({
    createInternalUser: builder.mutation({
      query: (args) => {
        const data = args;
        const body = {
          T_PERFIL: 'Interno',
          T_DOCUMENTO: 'CUIL',
          N_DOCUMENTO: data.inputCUIT,
          C_USER: data.sigafUser,
          C_PASSWORD: data.password,
        };
        return {
          method: "POST",
          body: body,
          responseHandler: (response) => response.text(),
        };
      },
    }),
    createExternalUser: builder.mutation({
      query: (args) => {
        const data = args;
        const body = {
          T_PERFIL: data.profileType,
          T_DOCUMENTO: 'CUIL',
          N_DOCUMENTO: data.cuitCuil.replaceAll('-',''),
          XC_USER: data.fullName,
          XL_EMAIL: data.email,
          XL_DOMICILIO: data.street,
          N_TELEFONO: data.phone,
          C_PASSWORD: data.password,
        };
        return {
          method: "POST",
          body: body,
          responseHandler: (response) => response.text(),
        };
      },
    }),
    cuilExist: builder.query({
      query: (args) => {
        return {
          method: "GET",
          url: `${process.env.REACT_APP_OBSBA_API_URL}/Usuario_Portal/cuiLExist`, // Update the path
          params: args,
        };
      },
    }
    ),
    requestChangePassword: builder.mutation({
      query: (args) => {
        return {
          method: "PUT",
          url: `${process.env.REACT_APP_OBSBA_API_URL}/Usuario_Portal/RequestChangePassword`, // Update the path
          params: args,
          responseHandler: (response) => response.text(),
        };
      },
    }),
  }),
});

export const { useCuilExistQuery, useRequestChangePasswordMutation, useCreateInternalUserMutation, useCreateExternalUserMutation } = RegisterApi;