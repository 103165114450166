import { Box, Button, Checkbox, Divider, FormControl, FormControlLabel, Grid, InputAdornment, TextField, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { selectUser } from "../../../redux/slices/signInSlice";
import Colors from "../../../theme/colors";
import Styles from "../styles";
/* PAQUETE PARA FORMATO DE FECHAS ESPECIALES */
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
/* FIN */
/* Redux RTK Query Mutation */
import { commonAPI, useGetEstadosCarpetaQuery, useGetTipoCarpetaQuery } from "../../../api/Common";
import { useGetFolderQuery, useSendFolderMutation, useUpdateFolderMutation } from "../../../api/Folders";
import { esInterno } from "../../../helpers/UsuarioHelper";
import CreateFile from "./CreateFile";
import { setInformationAsync } from "../../../redux/slices/modalSlice";
import { CleanUpFolder, SetFilterFolder } from "../../../redux/slices/dashboardSlice";
import { breadcrumbs } from "../../../settings";
import { setBreadcrumbs } from "../../../redux/slices/generalSlice";
import { FormatCuil, Mask, ValidateCuit } from "../../../helpers/validators";

const FolderState = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  dispatch(setBreadcrumbs(breadcrumbs.principal));

  const { data: dataCarpeta, isLoading } = useGetFolderQuery({ anio: props.folderId.split(" ")[1], id: props.folderId.split(" ")[0] });
  const { data: dataEstadoCarpeta, isLoading: loadingEstadoCarpeta } = useGetEstadosCarpetaQuery();
  const { data: dataTipoCarpeta, isLoading: loadingTipoCarpeta } = useGetTipoCarpetaQuery();

  const [sendFolder, { isLoading: loadingSendFolderAsync }] = useSendFolderMutation();
  const [updateFolder, { isLoading: loadingUpdateAsync }] = useUpdateFolderMutation();

  const user = useSelector(selectUser).user;

  const { handleSubmit, register, control, errors, reset, watch, setValue, setError, clearErrors } = useForm({ mode: "onChange" });

  const style = Styles();
  
  const deletedFolder = dataCarpeta?.fhBaja != null;
  const visibilidadInterno = dataCarpeta?.estado != "En Ingreso" && esInterno(user) ? style.visibility_internal : style.visibility_nointernal;

  const userIDs = [user.internoId, user.empleadoId, user.afiliadoId, user.prestadorId];
  const isOwner = userIDs.includes(dataCarpeta?.usuarioId);
  
  const isEnIngresoOrRechazada = dataCarpeta?.estado === "En Ingreso" || dataCarpeta?.estado === "Rechazada";
  const canEditBenefitDataInternalOwner = esInterno(user) && user.n_DOCUMENTO == dataCarpeta?.nDocumento && isEnIngresoOrRechazada;
  const canEditBenefitDataInternal = esInterno(user) && !isEnIngresoOrRechazada;
  const canEditBenefitDataExternal = !esInterno(user) && user.n_DOCUMENTO === dataCarpeta?.nDocumento && isEnIngresoOrRechazada;
  
  const folderTypeStatus = useSelector(commonAPI.endpoints.getTipoCarpeta.select()).status;
  const folderStateStatus = useSelector(commonAPI.endpoints.getEstadosCarpeta.select()).status

  const render = folderTypeStatus === 'fulfilled' && folderStateStatus === 'fulfilled' 

  useEffect(() => {
    reset(dataCarpeta)
    dispatch(SetFilterFolder(null))
    if (!deletedFolder && esInterno(user) && (watch("observacionesObsba", "") == "" || watch("observacionesObsba", "") == null) && watch("estado", "") == "Rechazada")
        setError("observacionesObsba", { message: 'Debe ingresar una observación.' })
  }, [dataCarpeta])

  const onSubmit = async (data) => {
    await updateFolder({
      Titulo: data.titulo,
      Factura: data.factura,
      Amparo: data.amparo,
      Observaciones: data.observaciones,
      ObservacionesObsba: data.observacionesObsba,
      Anio: props.folderId.split(" ")[1],
      Id: props.folderId.split(" ")[0],
      FechaPrestacion: data.fechaPrestacion,
      CUITBenef: data.cuitBenef?.toString()?.replace(/-/g, ""),
      ImportePrestacion: data.importePrestacion,
      VisibilidadObservacionesObsba: data.VisibilidadObservacionesObsba,
      Estado: dataEstadoCarpeta.filter((opt) => opt.label == watch("estado", ""))[0].value,
      TipoCarpeta: dataTipoCarpeta.filter((opt) => opt.label == watch("tipoCarpeta", ""))[0].value
    }).then((e) => {
      navigate(-1)
    });
  }

  const onVolver = () => {
      dispatch(SetFilterFolder(null));
      dispatch(CleanUpFolder());
      navigate(-1);
  }

  const isFetching = isLoading || loadingEstadoCarpeta || loadingTipoCarpeta || !render
   
  const isDisabled = () => {
    const canInternal = esInterno(user) && user.n_DOCUMENTO == dataCarpeta?.nDocumento && (dataCarpeta.estado == "En Ingreso" || dataCarpeta.estado == "Rechazada")
    if (watch("tipoCarpeta", "") != "Deuda Flotante" && !deletedFolder) {
      if (!esInterno(user) && (dataCarpeta.estado == "En Ingreso" || dataCarpeta.estado == "Rechazada"))
        return false;

      if (esInterno(user) && (dataCarpeta.estado == "Ingresada" || dataCarpeta.estado == "Verificada") || canInternal)
        return false;
    }
    return true
  }

  const isDisabledBenefitFields = () =>
    watch("tipoCarpeta", "") != "Deuda Flotante" && !deletedFolder && (canEditBenefitDataInternal || canEditBenefitDataExternal || canEditBenefitDataInternalOwner) ? false : true;

  const puedeSubir = () => {
    if (!isFetching && esInterno(user) && (watch("estado", "") == "En Ingreso" || watch("estado", "") == "Rechazada") && user.n_DOCUMENTO == watch("nDocumento", ""))
      return true;


    if (!isFetching && esInterno(user) && (watch("estado", "") != "En Ingreso" && watch("estado", "") != "Rechazada"))
      return true;


    if (!isFetching && !esInterno(user) && (watch("estado", "") == "En Ingreso" || watch("estado", "") == "Rechazada"))
      return true;

    return false;
  }
  
  const isDisabledObservacionesObsba = () => {
    if (!deletedFolder && esInterno(user) && watch("estado", "") != "En Ingreso" && watch("tipoCarpeta", "") != "Deuda Flotante")
      return false;
    return true
  }

  const handleSendFolder = async () => {
    await sendFolder({
      Anio: props.folderId.split(" ")[1],
      Id: props.folderId.split(" ")[0],
      Estado: 'I',
      Titulo: '',
      TipoCarpeta: ''
    }).unwrap().then(async (e) => {
      await dispatch(setInformationAsync({
        title: 'La documentación se ha enviado exitosamente',
        description: `Lleva el siguiente número de expediente: ${props.folderId.split(" ")[0]}-${props.folderId.split(" ")[1]}. ¡Saludos y buena semana!`,
        modalAsync: true
      }))
    });
  }
  
  return (
    <>
      {isLoading || loadingUpdateAsync || loadingSendFolderAsync || loadingEstadoCarpeta || loadingTipoCarpeta || !render && <LoadingSpinner />}
      { render && dataCarpeta && dataEstadoCarpeta && dataTipoCarpeta &&
        <form
          // className={style.form}
          style={{
            display: "flex",
            justifyContent: "center",
            minWidth: "600px",
            width: "100%",
            marginTop: 10,
            marginBottom: 10,
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid
            container
            spacing={2}
            style={{
              display: "flex",
              justifyContent: "center",
              width: "600px",
              minWidth: "600px",
            }}
          >
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <label style={{ fontSize: "20px" }}>Administrar Carpeta</label>
            </Grid>

            {/* Autocomplentar Tipo de Carpeta */}
            <Grid item xs={6}>
              <Controller
                control={control}
                name="tipoCarpeta"
                render={({ value, onChange, field, ref }) =>
                  <Autocomplete
                    options={dataTipoCarpeta}
                    getOptionLabel={(option) => option.label}
                    disabled={isDisabled()}
                    onChange={(e, data) =>
                      onChange(data ? data.label : '')
                    }
                    value={value ? dataTipoCarpeta?.find((opt) => {
                      return value === opt.label
                    }) : null}
                    renderInput={(params) => (
                      <TextField
                        variant="outlined"
                        {...params}

                        {...field}
                        ref={ref}
                        label="Tipo de carpeta"
                        error={!!errors.folderType}
                        helperText={errors.folderType?.message}
                        inputRef={register}
                      />)} />}
              />
            </Grid>

            {/* Box de Numero de carpeta y Año */}
            <Grid container item xs={6} justifyContent="rigth">
              <Box
                sx={{
                  border: '1px solid #707070',
                  borderRadius: '11px',
                  width: 186,
                  height: 56
                }}
              >
                <Grid container item xs={12} justifyContent="center" alignContent="center">
                  <Typography align="center" style={{ color: '#926FAF', fontWeight: 'bold' }}>Numero Carpeta</Typography>
                  <Typography align="center">{watch("anio", "")}-{watch("id", "")}</Typography>
                </Grid>
              </Box>
            </Grid>

            {/* Autocomplentar Estado de Carpeta */}
            <Grid item xs={6}>
              <Controller
                control={control}
                name="estado"
                render={({ value, onChange, field, ref }) =>
                  <Autocomplete

                    options={dataEstadoCarpeta}
                    getOptionLabel={(option) => option.label}
                    disabled


                    onChange={(e, data) => onChange(data ? data.label : '')}
                    value={value ? dataEstadoCarpeta?.find((opt) => {
                      return value === opt.label
                    }) : null}
                    renderInput={(params) => (
                      <TextField

                        variant="outlined"
                        {...params}
                        {...field}
                        ref={ref}
                        label="Estado"
                        error={!!errors.folderType}
                        helperText={errors.folderType?.message}
                        inputRef={register}
                      />)} />}
              />
            </Grid>

            {/* Contiene Pago y Contiene amparo */}
            <Grid item xs={6}>
              <FormControl size={"small"} variant={"outlined"}>
                <FormControlLabel
                  label="Contiene Pago"
                  control={
                    <Controller
                      name="factura"
                      render={({ field, value }) => {
                        return (
                          <Checkbox
                            id="idChkContienePago"
                            disabled={isDisabled()}
                            checked={value == "S"}
                            onChange={() => {
                              if (value == "S")
                                setValue("factura", "N")
                              else
                                setValue("factura", "S")
                            }}
                            style={{color:'#325AA5'}}
                          />
                        );
                      }}
                      control={control}
                    />
                  }
                />
              </FormControl>

              <FormControl size={"small"} variant={"outlined"}>
                <FormControlLabel
                  label="Contiene Amparo"
                  control={
                    <Controller
                      name="amparo"
                      render={({ field, value }) => {
                        return (
                          <Checkbox
                            disabled={isDisabled()}
                            checked={value == "S"}
                            onChange={() => {
                              if (value == "S")
                                setValue("amparo", "N")
                              else
                                setValue("amparo", "S")
                            }}
                            style={{ color:'#325AA5'}}
                          />
                        );
                      }}
                      control={control}
                    />
                  }
                />

              </FormControl>
              {/* </FormGroup> * */}
            </Grid>

            {/* Divisor */}
            <Grid item xs={12}>
              <Divider />
            </Grid>

            {/* Tipo de perfil (tiene que ser el del dueño de la carpeta, ahora se muestra el del usuario logeado) */}
            <Grid item xs={12}>
              <Autocomplete
                multiple
                id="tags-standard"
                options={
                  user.t_PERFILES != null
                    ? user.t_PERFILES.map((item, index) => ({
                      label: item,
                      value: index,
                    }))
                    : [{}]
                }
                getOptionLabel={(option) => option.label}
                value={
                  user.t_PERFILES != null
                    ? user.t_PERFILES.map((item, index) => ({
                      label: item,
                      value: index,
                    }))
                    : [{}]
                }
                fullWidth
                disabled
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Tipo de perfil"
                    name="tipoPerfil"
                    id="tipoPerfil"
                    variant="outlined"
                    fullWidth
                  />
                )}
                style={{ marginBottom: "0", marginTop: "0" }}
              />
            </Grid>

            {/* Nombre del dueño de la carpeta*/}
            <Grid item xs={6}>
              <Controller
                name="usuario"
                control={control}
                render={({ field, value }) => {
                  return (
                    <TextField

                      variant="outlined"
                      label="Apellido / Nombre / Razon Social"
                      fullWidth
                      disabled
                      className={style.textField}
                      value={value}
                    />
                  )
                }}
              />
            </Grid>

            {/* Numero de proveedor - No se esta usando */}
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                id="userNumber"
                label="N° Proveedor / Afiliado"
                autoComplete="userNumber"
                fullWidth
                disabled
                className={style.textField}
              // value={numeroAfiliado}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                name="nDocumento"
                control={control}
                render={({ field, value }) => {
                  return (
                    <TextField

                      variant="outlined"
                      label="CUIT / CUIL"
                      fullWidth
                      disabled
                      className={style.textField}
                      value={value}
                    />
                  )
                }}
              />
            </Grid>

            {/* Fecha Baja */}
            <Grid item xs={6}>
              <Controller
                name="fhBaja"
                control={control}
                render={({ field, value, onChange }) => {
                  return (
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        disabled
                        {...field}
                        value={value ? moment(value) : null}
                        onChange={(e) => onChange(e)}
                        label="Fecha de baja"
                        sx={{ width: "100%" }}
                      />
                    </LocalizationProvider>
                  )
                }}
              />
            </Grid>


            {/* Fecha Alta */}
            <Grid item xs={6}>
              <Controller
                name="fhAlta"
                control={control}
                render={({ field, value, onChange }) => {
                  return (
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        disabled
                        {...field}
                        value={value ? moment(value) : null}
                        onChange={(e) => onChange(e)}
                        label="Fecha de alta"
                        sx={{ width: "100%" }}
                        inputRef={errors.fechaPrestacion}
                      />
                    </LocalizationProvider>
                  )
                }}
              />
            </Grid>

            {/* Fecha de ingreso */}
            <Grid item xs={6}>
              <Controller
                name="fhIngreso"
                control={control}
                render={({ field, value, onChange }) => {
                  return (
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        disabled
                        {...field}
                        value={value ? moment(value) : null}
                        onChange={(e) => onChange(e)}
                        label="Fecha de Ingreso"
                        sx={{ width: "100%" }}
                      />
                    </LocalizationProvider>
                  )
                }}
              />
            </Grid>

            {/* Si contiene pago esa tildado, tambien depende del tipo de carpeta */}
            {watch("factura", "") == "S" && watch("tipoCarpeta", "") != "Reintegro"
              && moment(watch("fhAlta", "")) >= moment("2023-05-04") && (isOwner || esInterno(user)) &&
              <>
                <Grid item xs={6}>
                  <Controller
                    name="cuitBenef"
                    control={control}
                    rules={{
                      required: "Campo obligatorio",
                      validate: (value) =>{
                        return !ValidateCuit(value.toString()) ? "CUIT/CUIL inválido" : null;
                      }
                    }}
                    render={({ field, value, onChange }) => {
                      return (
                        <TextField
                          disabled={isDisabledBenefitFields()}
                          variant="outlined"
                          id="idInpCuit"
                          label="CUIT Beneficiario/a"
                          fullWidth
                          className={style.textField}
                          error={!!errors.cuitBenef}
                          helperText={errors.cuitBenef?.message}
                          value={value && FormatCuil(value.toString())}
                          inputProps={{
                            maxLength: 13,
                            type: 'text',
                          }}
                          onChange={(e) => {
                            onChange(Mask(e));
                          }}
                        />
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="importePrestacion"
                    control={control}
                    rules={{
                      required: "Campo obligatorio",
                      valueAsNumber: true,
                      min: {
                          value: 1,
                          message: "Rango entre $1 y $999.999.999.999,99",
                      },
                      max: {
                          value: 1000000000000,
                          message: "Rango entre $1 y $999.999.999.999,99",
                      },
                      pattern: {
                          value: /^\d+(\.\d{0,2})?$/,
                          message: "Rango entre $1 y $999.999.999.999,99",
                      },
                  }}
                    render={({ field, value, onChange }) => {
                      return (
                        <TextField
                          disabled={isDisabledBenefitFields()}
                          id="idInpImporte"
                          variant="outlined"
                          label="Importe de Prestación"
                          type="number"
                          fullWidth
                          className={style.textField}
                          value={value}
                          error={!!errors.importePrestacion}
                          helperText={errors.importePrestacion?.message}
                          onChange={onChange}
                          inputprops={{ disableUnderline: true }}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">$</InputAdornment>
                            ),
                        }}
                        onKeyPress={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                        />
                      )
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Controller
                    name="fechaPrestacion"
                    rules={{
                      required: "Campo obligatorio"
                    }}
                    control={control}
                    render={({ field, value, onChange }) => {
                      return (<>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DatePicker
                            {...field}
                            disabled={isDisabledBenefitFields()}
                            id="idInpFechaPrestacion"
                            value={value ? moment(value) : null}
                            views={["year", "month"]}
                            format="MM-YYYY"
                            onChange={(e) => onChange(e)}
                            label="Fecha de Prestación"
                            sx={{
                              width: "100%",
                              border: `1px solid ${errors.fechaPrestacion ? "#f44336" : "#ccc"}`,
                              borderRadius: "4px",
                            }}
                            error={!!errors.fechaPrestacion}
                          />
                        </LocalizationProvider>
                        <p style={{
                          color: "#f44336",
                          fontSize: 12,
                          margin: "3px 14px 0px",
                        }}>{errors.fechaPrestacion?.message}</p>
                      </>
                      )
                    }}
                  />

                </Grid>
              </>
            }
            {/* Divisor */}
            <Grid item xs={12}>
              <Divider />
            </Grid>

            {/* Titulo de carpeta */}
            <Grid item xs={12}>
              <Controller
                name="titulo"
                rules={{
                  required: "Campo obligatorio",
                  validate: (value) => {
                    return !/^\s*$/.test(value) ? null : 'No se aceptan solo espacios en blanco.';
                  }
                }}
                control={control}
                disabled={isDisabled}
                render={({ field, value, onChange }) => {
                  return (
                    <TextField
                      id="idInpTitulo"
                      disabled={isDisabled()}
                      variant="outlined"
                      label="Titulo de carpeta"
                      fullWidth
                      onChange={onChange}
                      className={style.textField}
                      value={value}
                      error={!!errors.titulo}
                      helperText={errors.titulo?.message}
                    />
                  )
                }}
              />
            </Grid>

            {/* Descripcion de carpeta */}
            <Grid item xs={12}>
              <Controller
                name="observaciones"
                control={control}
                render={({ field, value, onChange }) => {
                  return (
                    <TextField
                      id="idInpDescripcion"
                      disabled={isDisabled()}
                      onChange={onChange}
                      variant="outlined"
                      label="Descripción de carpeta"
                      fullWidth
                      className={style.textField}
                      value={value}
                    />
                  )
                }}
              />
            </Grid>

            {/* Observaciones Obsba */}

            <Grid item xs={11}>
              <Controller
                name="observacionesObsba"
                control={control}
                rules={{

                  validate: (value) => {
                    if (esInterno(user) && (watch("visibilidadObservacionesObsba", "") == 'S' && value == "") && watch("estado", "") == "Rechazada")
                      return 'Debe ingresar una observación.';

                    if (esInterno(user) && (watch("visibilidadObservacionesObsba", "") == 'N' || watch("visibilidadObservacionesObsba", "") == null) && watch("estado", "") == "Rechazada")
                      return 'Por favor marque el check de visibilidad pública para la observación.';
                  }
                }}
                render={({ field, value, onChange }) => {
                  return (
                    <TextField
                      {...field}
                      variant="outlined"
                      label="Observaciones Obsba"
                      id="idInpObservaciones"
                      fullWidth
                      onChange={onChange}
                      disabled={isDisabledObservacionesObsba()}
                      className={style.textField}
                      value={value}
                      error={!!errors.observacionesObsba}
                      helperText={errors.observacionesObsba?.message}
                    />
                  )
                }}
              />
            </Grid>

            {/* Check Visible Observaciones Obsba*/}
            <Grid item xs={1}>
              <FormControl size={"small"} variant={"outlined"} className={visibilidadInterno}>
                {/* <FormLabel component="legend">Contiene Amparo</FormLabel> */}
                <FormControlLabel
                  label="Visible"
                  control={
                    <Controller
                      name="visibilidadObservacionesObsba"
                      render={({ field, value }) => {
                        return (
                          <Checkbox
                            {...field}
                            disabled={deletedFolder}
                            style={{color: '#325AA5' }}
                            checked={value == "S" ? true : false}
                            onChange={() => {
                              if (value == "S")
                                {
                                  setValue("visibilidadObservacionesObsba", "N")
                                  setError("observacionesObsba", {message:"Por favor marque el check de visibilidad pública para la observación."})
                                }
                              else
                                {
                                  setValue("visibilidadObservacionesObsba", "S")
                                  clearErrors("observacionesObsba")
                                }
                            }}
                          // onChange={() => handleSelect(option.value)}
                          />
                        );
                      }}
                      control={control}
                    />
                  }
                // label={option.label}
                // key={option.value}
                />

              </FormControl>
            </Grid>

            {/* Boton Volver */}
            <Grid item xs={6}>
              <Button
                onClick={onVolver}
                size="large"
                type="button"
                variant="outlined"
                style={{
                  margin: 0,
                  backgroundColor: Colors.white,
                  border: "2px solid",
                  borderColor: Colors.blue,
                  color: Colors.blue,
                  maxHeight: "50px",
                  minHeight: "50px",
                }}
                fullWidth
              >VOLVER</Button>
            </Grid>

            {/* Boton Guardar - Con cierta logica para que se dibuje el boton la carpeta tiene que ser distinto del tipo Deuda Flotante */}
            {!deletedFolder && (!isDisabled() || !isDisabledObservacionesObsba() || !isDisabledBenefitFields()) &&
              <Grid item xs={6}>
                <Button
                  size="large"
                  type="submit"

                  variant="outlined"
                  style={{
                    margin: 0,
                    backgroundColor: Colors.blue,
                    border: "2px solid",
                    borderColor: Colors.blue,
                    color: Colors.white,
                    maxHeight: "50px",
                    minHeight: "50px",
                  }}
                  fullWidth
                >GUARDAR</Button>
              </Grid>}

            {/* Boton Enviar - Logica puedeEnviar S / N Depende del perfil del usuario y del estado de la carpeta*/}
            {watch("puedeEnviar", "") == "S" && !deletedFolder &&
              <Grid item xs={12}>
                <Button
                  onClick={() => handleSendFolder()}
                  size="large"
                  type="button"
                  variant="outlined"
                  style={{
                    margin: 0,
                    backgroundColor: Colors.blue,
                    border: "2px solid",
                    borderColor: Colors.blue,
                    color: Colors.white,
                    maxHeight: "50px",
                    minHeight: "50px",
                  }}
                  fullWidth
                >ENVIAR</Button>
              </Grid>}
          </Grid>
        </form>
      }
      {puedeSubir() && watch("tipoCarpeta", "") != "Deuda Flotante" && !deletedFolder && <CreateFile folderId={props.folderId.split(" ")[0]} yearId={props.folderId.split(" ")[1]} />}
    </>
  );

}

export default FolderState;