import React from 'react';
import { IMaskInput } from "react-imask"
import PropTypes from 'prop-types';

export const CuitMask = React.forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    TextMaskCustom.propTypes = {
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
      };
    return (
      <IMaskInput
        {...other}
        mask="00-00000000-0"
        definitions={{
          '#': /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  });

  