import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { api } from "../../api";


// -----------------------------------------------------------
// ----------------------- DATASETS --------------------------
// -----------------------------------------------------------

export const profileTypeOptions = [
  { label: 'Interno', value: 'I', id: 'idLiInterno' },
  { label: 'Empleado', value: 'E', id: 'idLiEmpleado' },
  { label: 'Afiliado', value: 'A', id: 'idLiAfiliado' },
  { label: 'Prestador', value: 'P', id: 'idLiPrestador' }
];

// -----------------------------------------------------------
// ----------------------- REGISTER --------------------------
// -----------------------------------------------------------



export const registerUserAsync = createAsyncThunk("/register", async (data, thunkAPI) => {
  try {
    const response = await api.post("/Usuario_Portal/newUser2", {
      t_DOCUMENTO: data.profileType =='Interno'?'CUIL':'CUIT',  
      n_DOCUMENTO: Number(data.cuitcuil),
      t_PERFIL: data.profileType,
      n_TELEFONO: data.employee_phone || data.external_phone,  
      xl_EMAIL: data.email,
      xL_DOMICILIO: data.street,
      xc_USER:data.internal_sigafUser || data.external_fullName,
      c_USER: data.profileType =='Interno'?data.internal_sigafUser:null,
      c_PASSWORD: data.internal_password || data.employee_password || data.external_password

    });
    return response.data;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});



export const cuilExist = createAsyncThunk("/register/cuilExist", async (data, dispatch) => {
  try {
    const response = await api.get("/Usuario_Portal/cuilExist?cuil=" + data);
    dispatch.dispatch(existSuccess(response));
    return response.data.cuitcuil;
  } catch (e) {
    return console.error(e.message);
  }
});

export const sigafExist = createAsyncThunk("/register/sigafExist", async (data, dispatch) => {
  try {
    const response = await api.get("/Usuario_Portal/sigafExist?user=" + data);
    dispatch.dispatch(existSigafSuccess(response));
    return response.data;
  } catch (e) {
    return dispatch.rejectWithValue(e);
  }
});

export const registerInternal = createAsyncThunk("/register/internal", async ({ cuitCuil, sigafUser, password }, thunkAPI) => {
  try {
    const response = await api.post("/Usuario_Portal/newUser2/", {
      t_PERFIL: 'Interno',
      t_DOCUMENTO: 'CUIL',
      n_DOCUMENTO: cuitCuil,
      c_USER: sigafUser,
      c_PASSWORD: password,
    });
    return response.data;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});


export const registerExternal = createAsyncThunk("/register/external", async (data, thunkAPI) => {
  try {
    const response = await api.post("/Usuario_Portal/newUser2/", {
        t_PERFIL: data.profileType,
        t_DOCUMENTO: 'CUIL',
        n_DOCUMENTO: data.cuitcuil,
        xc_USER: data.fullName,
        xL_EMAIL: data.email,
        xL_DOMICILIO: data.street,
        n_TELEFONO: data.phone,
        c_PASSWORD: data.password,
        //c_USER: data.sigafUser.toUpperCase(),
    });
    return response.data;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const registerEmployee = createAsyncThunk("/register/employee", async (data, thunkAPI) => {
  try {
    const response = await api.post("/Usuario_Portal/newUser2/", {
      t_PERFIL: data.profileType,
      t_DOCUMENTO: 'CUIL',
      n_DOCUMENTO: data.cuitcuil,
      xc_USER: data.fullName,
      xL_EMAIL: data.email,
      xL_DOMICILIO: data.street,
      n_TELEFONO: data.phone,
      c_PASSWORD: data.password,
      //c_USER: data.sigafUser.toUpperCase(),
    });
    return response.data;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const registerAffiliate = createAsyncThunk("/register/affiliate", async (data, thunkAPI) => {
  try {
    const response = await api.post("/Usuario_Portal/newUser2/", {
      t_PERFIL: data.profileType,
      t_DOCUMENTO: 'CUIL',
      n_DOCUMENTO: data.cuitcuil,
      xc_USER: data.fullName,
      xL_EMAIL: data.email,
      xL_DOMICILIO: data.street,
      n_TELEFONO: data.phone,
      c_PASSWORD: data.password,
      //c_USER: data.sigafUser.toUpperCase(),
    });
    return response.data;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});


export const validateRegister = createAsyncThunk("/register/validateRegister", async (data, dispatch) => {
  try {
    const response = await api.get("/Usuario_Portal/GetUserTokenOk?idPersona=" + data.cuit + "&token=" + data.token);
    dispatch.dispatch(validateSuccess(response));
    return response.data.cuitcuil;
  } catch (e) {
    return console.error(e.message);
  }
});

// -----------------------------------------------------------
// ------------------------- SLICE ---------------------------
// -----------------------------------------------------------

const initialState = {
  loading: false,
  register: {
    data: [],
    loading: "idle",
    error: null,
    success: false,
  },
  exist: false,
  existSigaf: false,
  error: null,
  success: false,
};

const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    registerSuccess: (state, action) => {
      state.register = action.payload.data;
    },
    existSuccess: (state, action) => {
      state.exist = action.payload.data;
    },
    existSigafSuccess: (state, action) => {
      state.existSigaf = action.payload.data;
      state.register.error = state.existSigaf ? "Usuario SIGAF ya existe" : null;
    },
    validateSuccess: (state, action) => {
      state.exist = action.payload.data;
    },
    clearData: () => initialState,

  },
  extraReducers: (builder) => {
    builder
    .addCase(cuilExist.pending,  (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(cuilExist.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = null;
    })
    .addCase(cuilExist.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    })
    .addCase(sigafExist.pending,  (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(sigafExist.fulfilled,  (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = null;
    })
    .addCase(sigafExist.rejected,  (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    })
    .addCase(validateRegister.pending,  (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(validateRegister.fulfilled,  (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = null;
    })
    .addCase(validateRegister.rejected,  (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    })
        .addCase(registerInternal.pending, (state) => {
          state.register.loading = "pending";
        })
        .addCase(registerInternal.fulfilled, (state, action) => {
          state.register.data = action.payload;
          state.register.loading = "idle";
          state.register.success = true;
        })
        .addCase(registerInternal.rejected, (state, action) => {
          state.register.error = action.payload.response.data;
          state.register.loading = "idle";
        })
        .addCase(registerExternal.pending, (state) => {
          state.register.loading = "pending";
        })
        .addCase(registerExternal.fulfilled, (state, action) => {
          state.register.data = action.payload;
          state.register.loading = "idle";
          state.register.success = true;
        })
        .addCase(registerExternal.rejected, (state, action) => {
          state.register.error = action.payload.response.data;
          state.register.loading = "idle";
        })
        .addCase(registerEmployee.pending, (state) => {
          state.register.loading = "pending";
        })
        .addCase(registerEmployee.fulfilled, (state, action) => {
          state.register.data = action.payload;
          state.register.loading = "idle";
          state.register.success = true;
        })
        .addCase(registerEmployee.rejected, (state, action) => {
          state.register.error = action.payload.response.data;
          state.register.loading = "idle";
        })
        .addCase(registerAffiliate.pending, (state) => {
          state.register.loading = "pending";
        })
        .addCase(registerAffiliate.fulfilled, (state, action) => {
          state.register.data = action.payload;
          state.register.loading = "idle";
          state.register.success = true;
        })
        .addCase(registerAffiliate.rejected, (state, action) => {
          state.register.error = action.payload.response.data;
          state.register.loading = "idle";
        })
  }
});

export const { registerSuccess, existSuccess, validateSuccess, existSigafSuccess, clearData } = registerSlice.actions;

export const selectRegister = (state) => state.register;

export default registerSlice.reducer;
