import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../api";

// -----------------------------------------------------------
// ----------------------- DATASETS --------------------------
// -----------------------------------------------------------

export const userBlocked = "Usuario Bloqueado, Comuníquese con el Administrador";
export const userFailed = "Usuario y/o Contraseña incorrectos";
export const userInhabilitado = "Usuario dado de baja";

// -----------------------------------------------------------
// ------------------------- LOGIN ---------------------------
// -----------------------------------------------------------

export const login = createAsyncThunk("/usuario_portal/login",
  async (data, thunkAPI) => {
    try {
      const response = await api.post("/usuario_portal/login", {
        cuit: data.username,
        password: data.password,
      });

      return response.data
    } 
    catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const RefreshDataUser = createAsyncThunk("/usuario_portal/refreshdatauser",
  async (data, thunkAPI) => {
    try {
      const response = await api.get("/usuario_portal/refreshdatauser");
      return response.data
    } 
    catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);


// -----------------------------------------------------------
// ------------------------- SLICE ---------------------------
// -----------------------------------------------------------

const initialState = {
  loading: false,
  user: null,
  error: null,
  success: false,
};

const logInSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.user = action.payload.data.usuario;
      api.defaults.headers.Authorization =
        "Bearer " + action.payload.data.token;
    },
    successUserUpdate: (state, action) => {
      const { data } = action.payload;
      const { xL_DOMICILIO, xC_USER, xL_EMAIL, n_TELEFONO, n_DOCUMENTO, t_DOCUMENTO } = data;  // Extrae solo las propiedades que deseas actualizar
      state.user = {
        ...state.user,
        xL_DOMICILIO,
        xC_USER,
        xL_EMAIL,
        n_TELEFONO,
        n_DOCUMENTO,
        t_DOCUMENTO
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(RefreshDataUser.pending, (state)=>{
        state.loading = "pending"
      })
      .addCase(RefreshDataUser.fulfilled, (state,action) =>{
        state.user = action.payload;
        state.loading="idle"
        state.error=null
        state.success=true
      })
      .addCase(RefreshDataUser.rejected, (state, payload) =>{
          state.error = 'fallo'
      })
      .addCase(login.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(login.fulfilled, (state, action) => {
        state.user = action.payload.usuario;
        state.loading = "idle";
        state.success = true;
        state.error = null;

        api.defaults.headers.Authorization = "Bearer " + action.payload.token;
      })
      .addCase(login.rejected, (state, action) => {
        state.user = null;
        state.error = action.payload.response.data;
        state.loading = "idle";
      });
  }
});

export const { loginSuccess, successUserUpdate } = logInSlice.actions;

export const selectUser = (state) => state.user;
export default logInSlice.reducer;
