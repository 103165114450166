import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

const DialogConfirm = (props)=>{
    return(
        <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        open={props.isOpen}
        onClose={()=>props.handleClose()}
      >
        <DialogTitle id="alert-dialog-title">
            ¡Atención!
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
              ¿Desea dar de alta al usuario { props.dataToModify.n_DOCUMENTO } con perfil { props.dataToModify.t_PERFIL }?
            </DialogContentText>
        </DialogContent>
        <DialogActions>
           <Button onClick={()=>props.handleClose()}>
            CANCELAR
           </Button>
           <Button onClick={()=>props.action()}>
            CONFIRMAR
           </Button>
        </DialogActions>
      </Dialog>
    )
};

export default DialogConfirm;