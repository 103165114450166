import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Grid, Typography, Hidden, TextField, } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import styles from "../styles";
import { useCuilExistQuery, useRequestChangePasswordMutation } from "../../../api/Usuarios_Portal";
import { SetInformation } from "../../../redux/slices/modalSlice";
import { FormatCuil, ValidateCuit } from "../../../helpers/validators";
import { TemplateNewPassword } from "../../../components/FormComponents";

const ForgotPassword = () => {
  const classes = styles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { handleSubmit, control, watch, formState: { errors } } = useForm({
    mode: "onChange",
    defaultValues: {
      cuil: ''
    }
  });

  const cuil = watch('cuil').replaceAll("-", "");
  const isValidCuil = !ValidateCuit(cuil);

  const { data: cuilExistData } = useCuilExistQuery({ cuil: cuil }, { skip: isValidCuil });
  const [requestChangePassword] = useRequestChangePasswordMutation();

  const onSubmit = async (data) => {
    try {
      if (cuilExistData) {
        let requestChangePasswordPromise = requestChangePassword({ idPersona: cuil });
        await handleRequestChangePassword(requestChangePasswordPromise);
      }
      else {
        dispatch(
          SetInformation({
            title: 'Error',
            description: 'CUIT/CUIL no registrado.'
          })
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleRequestChangePassword = async (requestChangePasswordPromise) => {
    const result = await requestChangePasswordPromise;
    if (result.error) {
      throw result;
    } else {
      navigate("/recoverPassword/" + cuil);
    }
  };

  return (
    <Grid container component="main" className={classes.root}>
      <Grid
        container
        item
        xs={12}
        md={6}
        elevation={6}
        justifyContent="center"
        alignContent="center"
      >
        <div className={classes.paper}>
          <form
            className={classes.form}
            style={{ width: "30%" }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <TemplateNewPassword />
            <Typography
              className={classes.label}
              style={{ textAlign: "center", fontSize: 16, letterSpacing: 0.16 }}
            >
              Ingresá el CUIT/CUIL asociado y te enviaremos un código de
              recuperación
            </Typography>
            <Grid
              container
              spacing={2}
              direction="row"
              style={{ marginTop: "10px" }}
            >
              <Controller
                name="cuil"
                control={control}
                rules={{
                  required: "Campo obligatorio",
                  validate: (value) => {
                    return !ValidateCuit(value) ? "CUIT/CUIL inválido" : null;
                  }
                }}
                render={({ field, ref, onChange, value }) => (
                  <TextField
                    {...field}
                    label="CUIT/CUIL"
                    id="cuil"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      type: 'text',
                      maxLength: 13,
                    }}
                    value={FormatCuil(value.toString())}
                    onChange={(e) => {
                      let numericValue = e.target.value.replace(/[^\d]/g, '');
                      let formattedValue = FormatCuil(numericValue.toString());
                      onChange(formattedValue);
                    }}
                    error={!!errors.cuil}
                    helperText={errors.cuil?.message}
                  />
                )}
              />

              <Button
                type="submit"
                variant="outlined"
                color="primary"
                className={classes.button}
                fullWidth
              >
                Continuar
              </Button>
            </Grid>
            <Grid
              container
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginTop: "5px",
                marginBottom: "40px",
              }}
            >
              <Grid item>
                <Typography className={classes.label}>
                  ¿Ya tenés cuenta?
                </Typography>
              </Grid>
              <Grid item>
                <Link
                  to="/"
                  variant="body2"
                  className={classes.link}
                  style={{
                    textDecoration: "none",
                    fontWeight: "bold",
                    marginLeft: "5px",
                  }}
                >
                  Iniciar sesión
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </Grid>

      <Hidden smDown>
        <Grid md={6} className={classes.image}>
        </Grid>
      </Hidden>
    </Grid>
  );
}

export default ForgotPassword;