import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  message:{
    title:'',
    description:''
  },
  modalAsync:false,
  open:false
};

export const setInformationAsync = createAsyncThunk(
  "/modal",
  async (data, thunkAPI) => {
    try {
      return data;  
    } catch (e) {
      return  thunkAPI.rejectWithValue(e);
    }
  }
);


const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    SetInformation :  (state, action) =>{
      state.message.title =  action.payload.title
      state.message.description = action.payload.description
      state.open = true
    },
    SetClose: (state)=>{
      state.open = false
      state.modalAsync = false;
    }
  },
  extraReducers: (builder)=>{
    builder
    .addCase(setInformationAsync.fulfilled, (state,action) =>{
      state.message.title = action.payload.title;
      state.message.description = action.payload.description;
      state.modalAsync = action.payload.modalAsync;
      state.open = true;
    })
  }
});

export const {
  SetInformation,
  SetError,
  SetClose
} = modalSlice.actions;

export default modalSlice.reducer;