import { Box, Grid, Hidden, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { profileTypeOptions } from "../../../redux/slices/registerSlice";
import styles from "../styles";
import RegisterInterno from "./RegisterInterno.jsx";
import RegisterNoInterno from "./RegisterNoInterno.jsx";
const obsBALogo = require("../../../static/color-horizontal.png");

const RegisterAccount = () => {

  const classes = styles();
  const [profileType, setProfileType] = useState("Interno");

  return (
    <Grid container component="main" className={classes.root}>
      <Grid container item xs={12} md={6} elevation={6} justifyContent="center" alignContent="center" >
        <Grid container justifyContent="center" alignItems="center">
          <Grid item>
            <Box>
              <img src={obsBALogo} alt="obsBA Logo" className={classes.logo} />
            </Box>
            <Typography className={classes.loginSubtitle}>
              Portal de Gestión de Carpeta Digital
            </Typography>
            <Box display="flex" justifyContent="center">
              <Typography component="h1" variant="h4" style={{ marginTop: "15px", marginBottom: "15px" }} >
                Registro
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container style={{ marginBottom: '1rem', width: '60%' }}>
          <Grid item md={6}>
            <Autocomplete fullWidth defaultValue={profileType} options={profileTypeOptions}
              onChange={(event, newValue) => {
                newValue && setProfileType(newValue.label)
              }}
              renderClearIndicator={(autocomplete) => (
                <div id="idSvgTipoPerfil">
                  <i className="material-icons">arrow_drop_down</i>
                </div>
              )}
              renderInput={(params) => (
                <TextField {...params} id="idSelTipoPerfil" label="Tipo de perfil" required placeholder="Tipo de perfil" variant="outlined" />
              )} />
          </Grid>
        </Grid>

        {profileType == "Interno" && <RegisterInterno profile={profileType} />}
        {profileType != "Interno" && <RegisterNoInterno profile={profileType} />}

        <Grid container style={{ display: "flex", justifyContent: "center" }} >
          <Grid item xs={12} sm={12} md={6}>
            <Grid container style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "5px", }} >
              <Grid item>
                <Typography className={classes.label}>
                  ¿Ya tenés cuenta?
                </Typography>
              </Grid>
              <Grid item>
                <Link to="/" variant="body2" className={classes.link} style={{
                  textDecoration: "none", fontWeight: "bold", marginLeft: "5px",
                }}
                >
                  Iniciar sesión
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box >
          <Typography
            color="primary"
            align="center"
            style={{ padding: "10px" }}
          >
            Soporte ObSBA: asis.soporte@obsba.org.ar
          </Typography>
          <Typography color="primary" align="center">
            Teléfono: 0800-348-1014
          </Typography>
        </Box>
      </Grid>
      <Hidden smDown>
        <Grid item md={6} className={classes.image}></Grid>
      </Hidden>
    </Grid>
  );
}

export default RegisterAccount;