import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Grid } from "@material-ui/core";
import Styles from "../styles";

import { ManageUsers } from "./ManagerUsers";
import { selectManageUser } from "../../../redux/slices/usersSlice";
import { GetDataByCuil } from "../../../redux/slices/manageuserSlice";
import LoadingSpinner from "../../../components/LoadingSpinner";

export function UserManageUser() {

  const style = Styles();
  const dispatch = useDispatch();
  const userData = useSelector(selectManageUser).data;
  const isDashboardFileLoading = useSelector(state => state.dashboard.file.loading) === "pending";
  const isManageUserLoading = useSelector(selectManageUser).loading === "pending";
  const o_usuario = useParams();

  useEffect(() => {
    dispatch(GetDataByCuil({ id: o_usuario }));
  }, [])


  return (
    <Grid container component="main" className={style.root}>
      {(isDashboardFileLoading || isManageUserLoading) ? <LoadingSpinner /> :
        <Grid item className={style.paper}>
          <ManageUsers data={userData} />
        </Grid>
      }
    </Grid>
  );

}