import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  Button,
  Grid,
  Typography,
  Box,
  CircularProgress,
} from "@material-ui/core";
import styles from "../../pages/public/styles";

export function ShowErrorPage(params) {
  const classes = styles();
  const navigate = useNavigate();
  const { handleSubmit } = useForm();

  const { code } = useParams();

  const leyenda =
    code != null && code === "0"
      ? "Token expirado, vuelva a iniciar sesión"
      : params.msg;

  const onSubmit = (data) => {
    navigate("/");
  };

  return (
    <>
      
      <Grid container >
        <Grid container item className={classes.fondoNavbar}>
          <Grid container item xs={12} alignContent="center">
            <div className={classes.logo2} />
          </Grid>
        </Grid>
      </Grid>

      <Grid container style={{ marginTop: 72}}>
            <Grid item xs={12}>
                <div className={classes.paper2}>
                    <form className={classes.form} style={{width: '30%'}} onSubmit={handleSubmit(onSubmit)}>
                        <Typography className={classes.textErrorBox}>
                            Página no
                        </Typography>
                        <Typography className={classes.textErrorBox}>
                            Encontrada
                        </Typography>
                        <Grid container className={classes.errorBox}>
                            <Grid id="top-row" container spacing={24} className={classes.headerBox}>
                                    <Grid item xs={4} className={classes.errorHeadDots}/> 
                                    <Grid item xs={4}/> 
                                    <Grid item xs={4} className={classes.errorHeadImage}/>
                            </Grid>
                            <Grid id="bottom-row" container spacing={24}>
                                <Grid item xs={10}> 
                                    <Typography className={classes.errorMensaje}>
                                        ERROR
                                    </Typography>
                                    <Typography className={classes.error404}>
                                        404
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} className={classes.errorVertical}>
                                    ERROR
                                </Grid>
                            </Grid>
                        </Grid>
                        <Typography className={classes.label} style={{textAlign: 'center', marginTop: '5px'}}>
                            {leyenda} 
                            <Typography style={{fontWeight: 'bold'}}>0800-348-1014 </Typography>
                            <Typography variant="caption" color="primary" align="center" style={{fontWeight: 'bold'}}> Soporte ObSBA: </Typography> <span style={{ color:'#000000' }}>asis.soporte@obsba.org.ar</span>
                        </Typography>

                        <Grid container 
                            spacing={2}
                            direction="row"
                            style={{marginTop: '2px', 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center'}}>
                            <Button
                                type="submit"
                                variant="outlined"
                                color="primary"
                                className={classes.buttonInicio}
                                fullWidth>
                                INICIO
                            </Button>
                        </Grid>
                    </form>
                </div>
            </Grid>            
        </Grid>
    </>
  );
}

export function LoadingSpinner() {
  return (
    <span
      style={{
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "#33333355",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100%",
        zIndex: 999
      }}
    >
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <CircularProgress />
      </Box>
    </span>
  );
}