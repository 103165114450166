import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const commonAPI = createApi({
  reducerPath: "commonApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_OBSBA_API_URL}/commons`,
    prepareHeaders: (headers, {getState}) =>{
        const token = localStorage.getItem("token_app")
            if(token)
              headers.set('Authorization', `Bearer ${token}`)
            return headers
      }
  }),
  endpoints: (builder) => ({
    getTipoCarpeta: builder.query({
      query: () => {
        return { url: `/get-tipocarpeta`
        };
      }
    }),
    getEstadosCarpeta: builder.query({
        query: () => {
          return { url: `/get-estadoscarpeta`
        };
        }
      }),
    getSector: builder.query({
        query: () => {
          return { url: `/get-sector`
          };
        }
      }),
    getTipoDocumento: builder.query({
        query: () => {
          return { url: `/get-tipodocumento`
           };
        }
      }),
  }),
});

export const { useGetEstadosCarpetaQuery, 
               useGetSectorQuery, 
               useGetTipoCarpetaQuery, 
               useGetTipoDocumentoQuery } = commonAPI;
