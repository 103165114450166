import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Styles from "../styles";
import Colors from '../../../theme/colors';
import { Grid, Button, TextField, Divider } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CuitMask } from '../../../helpers/maskedImputs';
import { ValidateCuit } from '../../../helpers/validators';
import { SetFilterData, userTypes } from '../../../redux/slices/usersSlice';
import UsersTable from './UsersTable';
import { breadcrumbs } from "../../../settings";
import { setBreadcrumbs } from "../../../redux/slices/generalSlice";

export function QueryUsers() {
    const style = Styles();
    const dispatch = useDispatch();
    const { handleSubmit, register, reset } = useForm({});
    const [error, setError] = useState(false);
    const [leyenda, setLeyenda] = useState("");
    const [tipoUsuario, setTipoUsuario] = useState('');
    const [tipoValue, setTipoValue] = useState('');
    const [errorCUIT, setErrorCUIT] = useState(false);
    const [leyendaCUIT, setLeyendaCUIT] = useState("");
    const [nDocumento, setNDocumento] = useState('');
    dispatch(setBreadcrumbs(breadcrumbs.adminUser));
    const onSubmit = (data) => {
        setError(false);
        setLeyenda('');
        const userFilter = {
            razonSocial: data.fullName === "" ? null : data.fullName,
            tipoUsuario: data.userType === "" ? null : data.userType,
            nombreUsuario: data.userName === "" ? null : data.userName,
            intentosFallidos: data.failedAttempts === "" ? null : data.failedAttempts,
            nroDocumento: nDocumento === "" ? null : Number(nDocumento.replaceAll('-', '')),
            fechaDesde: data.dateFrom === "" ? null : data.dateFrom,
            fechaHasta: data.dateTo === "" ? null : data.dateTo,
            pageNumber: 1,
            pageSize: 5
        }
        dispatch(SetFilterData(userFilter));
    };

    const onClearData = () => {
        reset();
        setTipoUsuario('');
        setTipoValue('');
        setErrorCUIT('');
        setLeyendaCUIT('');
        setNDocumento('');
        const emptyFilter = {
            pageNumber: 1,
            pageSize: 5
        }
        dispatch(SetFilterData(emptyFilter));
    };

    const onCuitChange = (e) => {
        if (e.target.value && !ValidateCuit(e.target.value)) {
            setErrorCUIT(true);
            setLeyendaCUIT("CUIT/CUIL inválido");
        } else {
            setErrorCUIT(false);
            setLeyendaCUIT("");
        }
        setNDocumento(e.target.value)
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', justifyContent: 'center', minWidth: '600px', width: '100%', padding: 0, marginBottom: '40px' }}>
                <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'center', width: '600px', minWidth: '600px' }}>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                        <label style={{ fontSize: '20px' }}>Consulta Usuarios</label>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            id="fullName"
                            label="Apellido / Nombre / Razon Social"
                            name="fullName"
                            fullWidth
                            className={style.textField}
                            inputprops={{ style: { fontSize: 14 } }}
                            InputLabelProps={{ style: { fontSize: 14 } }}
                            inputRef={register}
                            {...register('fullName')}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Autocomplete
                            id="userTypeAutocomplete"
                            options={userTypes}
                            getOptionLabel={(option) => option.label || ''}
                            getOptionSelected={(option, value) => option.value === value.value}
                            value={tipoUsuario == '' ? null : { label: tipoUsuario, value: tipoValue }}
                            onChange={(event, value) => {
                                setTipoUsuario(value ? value.label : '');
                                setTipoValue(value ? value.value : '')
                            }}
                            fullWidth
                            autoFocus
                            renderInput={(param) => (
                                <TextField
                                    {...param}
                                    label="Tipo usuario"
                                    name="userType"
                                    id='userType'
                                    placeholder="Tipo de usuario"
                                    variant="outlined"
                                    fullWidth
                                    inputRef={register}
                                />
                            )}
                            style={{ marginBottom: '0', marginTop: '0' }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            variant="outlined"
                            id="userName"
                            label="Nombre de Usuario"
                            name="userName"
                            fullWidth
                            className={style.textField}
                            inputprops={{ style: { fontSize: 14 } }}
                            InputLabelProps={{ style: { fontSize: 14 } }}
                            inputRef={register}
                            {...register('userName')}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            variant="outlined"
                            id="failedAttempts"
                            label="Intentos Fallidos"
                            name="failedAttempts"
                            fullWidth
                            className={style.textField}
                            inputprops={{ style: { fontSize: 14 } }}
                            InputLabelProps={{ style: { fontSize: 14 } }}
                            inputRef={register}
                            {...register('failedAttempts')}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            variant="outlined"
                            id="nDocumento"
                            label="CUIT/CUIL"
                            name="nDocumento"
                            autoComplete="nDocumento"
                            fullWidth
                            className={style.textField}
                            value={nDocumento}
                            onChange={onCuitChange}
                            error={errorCUIT}
                            helperText={leyendaCUIT}
                            InputLabelProps={{
                                shrink: true,
                                style: { fontSize: 14 }
                            }
                            }
                            inputRef={register}
                            InputProps={{
                                inputComponent: CuitMask,
                                style: { fontSize: 14 }
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="dateFrom"
                            name="dateFrom"
                            label="Fecha desde"
                            variant="outlined"
                            inputRef={register}
                            type="date"
                            fullWidth
                            error={error}
                            helperText={leyenda}
                            className={style.textField}
                            inputprops={{
                                disableUnderline: true,
                            }}
                            InputLabelProps={{
                                shrink: true,
                                style: {
                                    borderRadius: "4px",
                                    border: "1px solid",
                                    backgroundColor: Colors.white,
                                },
                            }}
                            {...register('dateFrom')}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="dateTo"
                            name="dateTo"
                            label="Fecha hasta"
                            variant="outlined"
                            inputRef={register}
                            type="date"
                            fullWidth
                            error={error}
                            helperText={leyenda}
                            className={style.textField}
                            inputprops={{
                                disableUnderline: true,
                            }}
                            InputLabelProps={{
                                shrink: true,
                                style: {
                                    borderRadius: "4px",
                                    border: "1px solid",
                                    backgroundColor: Colors.white,
                                },
                            }}
                            {...register('dateTo')}
                        />
                    </Grid>

                    <Grid item xs={12} style={{ marginTop: 15, marginBottom: 15 }}>
                        <Divider />
                    </Grid>

                    <Grid item xs={6} style={{ width: '100%', textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
                        <Button
                            size="large"
                            type="button"
                            variant="outlined"
                            onClick={onClearData}
                            style={{
                                margin: 0,
                                backgroundColor: Colors.white,
                                border: '2px solid',
                                borderColor: Colors.blue,
                                color: Colors.blue,
                                maxHeight: '50px',
                                minHeight: '50px'
                            }}
                            fullWidth>
                            Limpiar
                        </Button>
                    </Grid>
                    <Grid item xs={6} style={{ width: '100%', textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
                        <Button
                            size="large"
                            type="submit"
                            variant="outlined"
                            style={{
                                margin: 0,
                                backgroundColor: Colors.blue,
                                border: '2px solid',
                                borderColor: Colors.blue,
                                color: Colors.white,
                                maxHeight: '50px',
                                minHeight: '50px'
                            }}
                            fullWidth>
                            Buscar
                        </Button>
                    </Grid>
                </Grid>
            </form>

            <UsersTable />
        </>
    );
}