import React, { useState } from "react";
import { IdleTimerProvider } from 'react-idle-timer'
import { Divider, Typography, Backdrop, Fade, Box, Modal } from "@material-ui/core";
import { modalStyles } from "../components/styles";
import Colors from "../theme/colors"
import { useSelector } from "react-redux";
import { selectTimerActive } from "../redux/slices/dashboardSlice";

const UseIdleTimer = (prop) => {
    const [open, setOpen] = useState(false);
    const timer = useSelector(selectTimerActive);

    const onPrompt = () => {
        setOpen(true)
    }

    const onIdle = () => {
        setOpen(true)
    }

    const handleClose = () => {
        localStorage.clear();
        setOpen(false);
        window.location.reload(true);
    };

    

    return (
        <IdleTimerProvider
            startManually={timer}
            timeout={1000 * 60 * 15}
            onPrompt={onPrompt}
            onIdle={onIdle}
        >
            {prop.children}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                onClick={() => handleClose()}
            >
                <Fade in={open}>
                    <Box sx={modalStyles}>
                        <Typography id="transition-modal-title" variant="h6" component="h2" style={{ marginBottom: '5px', fontSize: '20px' }}>
                            Ha excedido el tiempo de inactividad en la plataforma.
                        </Typography>
                        <Divider style={{ marginBottom: '15px', backgroundColor: Colors.blue }} />
                        <Typography id="transition-modal-description" sx={{ mt: 2 }} style={{ fontSize: '15px', fontWeight: '300' }}>
                            Vuelva a iniciar sesión
                        </Typography>
                    </Box>
                </Fade>
            </Modal>
        </IdleTimerProvider>
    )
}

export default UseIdleTimer;