import { Box, CircularProgress } from "@material-ui/core";

const LoadingSpinner = () => {
    return (
      <span
        style={{
          position: "fixed",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "#33333355",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100%",
          zIndex: 999
        }}
      >
        <Box
          sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        >
          <CircularProgress />
        </Box>
      </span>
    );
  }

  export default LoadingSpinner;