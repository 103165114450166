import { configureStore } from "@reduxjs/toolkit";
import logInReducer from "../slices/signInSlice";
import registerReducer from "../slices/registerSlice";
import recoveryReducer from "../slices/passwordSlice";
import dashboardReducer from "../slices/dashboardSlice";
import profileReducer from "../slices/profileSlice";
import usersReducer from "../slices/usersSlice"
import { generalReducer, modalReducer } from "../slices/index"
import { fileAPI } from "../../api/Files"; 
import { folderAPI } from "../../api/Folders";
import { RegisterApi } from "../../api/Usuarios_Portal";
import { commonAPI } from "../../api/Common";

export default configureStore({
  reducer: {
    user: logInReducer,
    register: registerReducer,
    dashboard: dashboardReducer,
    recovery: recoveryReducer,
    profile: profileReducer,
    users: usersReducer,
    modal: modalReducer,
    general: generalReducer,
    [fileAPI.reducerPath] : fileAPI.reducer,
    [folderAPI.reducerPath] : folderAPI.reducer,
    [RegisterApi.reducerPath] : RegisterApi.reducer,
    [commonAPI.reducerPath] : commonAPI.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([folderAPI.middleware, fileAPI.middleware, RegisterApi.middleware, commonAPI.middleware]),
});
