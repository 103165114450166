import { Button, Grid, IconButton, Tooltip } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {
    DataGrid,
    GridFooterContainer,
    GridPagination,
} from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { BsExclamationSquare, BsFolder, BsFolderCheck, BsInfoSquare, BsPen } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { CleanFoldersPendientes, GetFolders, cleanExcelExport, downloadExcel } from "../../../redux/slices/dashboardSlice";
import { selectUser } from "../../../redux/slices/signInSlice";
import Colors from "../../../theme/colors";
import CustomPagination from "../../../components/Table/CustomPagination";
import { SetInformation } from "../../../redux/slices/modalSlice";

let optionsDate = { year: "numeric", month: "short", day: "numeric" };
let optionsHour = { hour: "2-digit", minute: "2-digit" };

const FolderTable = () => {
    const [paginacion, setPaginacion] = useState({
        page: 0,
        pageSize: 5
    })

    const dispatch = useDispatch();

    const allData = useSelector((state) => state.dashboard.folder.data);
    const usercuit = useSelector(selectUser).user;
    const filterData = useSelector((state) => state.dashboard.filterFolder);
    const folderStatus = useSelector((state) => state.dashboard.folder.loading);
    const exportStatus = useSelector(
        (state) => state.dashboard.downloadFile.loading
    );
    const downloadError = useSelector((state) => state.dashboard.downloadFile.message);
    const user = useSelector((state) => state.user.user);

    useEffect(() => {
        if (downloadError) {
            dispatch(SetInformation(
                {
                    title: 'Error',
                    description: downloadError || "Ha ocurrido un error"
                }
            ))
            dispatch(cleanExcelExport());
        }
    }, [downloadError])

    const handleButtonClick = () => {
        dispatch(CleanFoldersPendientes());
    };
    const CustomFooter = () => {
        return (
            <GridFooterContainer >
                <Grid item xs={2}>
                    <Button
                        size="large"
                        type="button"
                        variant="outlined"
                        onClick={exportExcel}
                        style={{
                            margin: 15,
                            backgroundColor: Colors.blue,
                            border: "2px solid",
                            borderColor: Colors.blue,
                            color: Colors.white,
                            maxHeight: "50px",
                            minHeight: "50px",
                        }}
                        fullWidth
                    >
                        EXPORTAR RESULTADO
                    </Button>
                </Grid>
                {/* <CustomPagination /> */}

                <GridPagination labelRowsPerPage={'Filas por página'} />
            </GridFooterContainer>
        );
    }

    const columnsGrid = [
        {
            field: "icon",
            headerName: "",
            type: "actions",
            renderCell: (params) => (
                <Grid>
                    <Tooltip
                        title={
                            <label
                                style={{ color: "white", fontSize: "12px", fontWeight: "300" }}
                            >
                                {params.row.tieneDocumento == 'S' ? "Con Documentos" : "Sin Documentos"}
                            </label>
                        }
                    >
                        <Link

                            to={`/dashboard/folder/${params.row.id} ${params.row.anio}`}>
                            <IconButton

                            >
                                {params.row.tieneDocumento == 'S' ? <BsFolderCheck size={20} /> : <BsFolder size={20} />}
                            </IconButton>
                        </Link>
                    </Tooltip>
                </Grid>
            ),
        },
        { field: "folder", headerName: "Carpeta", minWidth: 100, flex: 1 },
        { field: "fac", headerName: "Fac", width: 50 },
        { field: "folderType", headerName: "Tipo Carpeta", minWidth: 150, flex: 1 },
        { field: "initMember", headerName: "Iniciador", flex: 1 },
        {
            field: "initData",
            headerName: "Iniciación",
            flex: 1,
            renderCell: (params) => {
                return params.row.initData
                    ? new Date(params.row.initData).toLocaleDateString(
                        "es-AR",
                        optionsDate
                    ) +
                    ", " +
                    new Date(params.row.initData).toLocaleTimeString(
                        "es-AR",
                        optionsHour
                    )
                    : "";
            },
        },
        // {
        //   field: "cuitBenef",
        //   headerName: "CUIT Beneficiario",
        //   align: "center",
        //   width: 150,
        //   renderCell: (params) => {
        //     return params.row.cuitBenef
        //       ? params.row.cuitBenef
        //           .toString()
        //           .replace(/^(\d{2})(\d{8})(\d{1})$/, "$1-$2-$3")
        //       : "N/A";
        //   },
        // },

        // {
        //   field: "dateFinal",
        //   headerName: "Fecha de Prestacion",
        //   align: "center",
        //   width: 150,
        //   renderCell: (params) => {
        //     return params.row.dateFinal
        //       ? new Date(params.row.dateFinal).getMonth() + 1 < 10
        //         ? `0${new Date(params.row.dateFinal).getMonth() + 1}-${new Date(
        //             params.row.dateFinal
        //           ).getFullYear()}`
        //         : new Date(params.row.dateFinal).getMonth() +
        //           1 +
        //           "-" +
        //           new Date(params.row.dateFinal).getFullYear()
        //       : "N/A";
        //   },
        // },
        // {
        //   field: "prestacion",
        //   headerName: "Importe Prestación",
        //   align: "center",
        //   width: 150,
        //   renderCell: (params) => {
        //     return params.row.prestacion ? toArs(params.row.prestacion) : " N/A ";
        //   },
        // },
        {
            field: "actualizationDate",
            headerName: "Última Actualización",
            flex: 1,
            renderCell: (params) => {
                return (
                    new Date(params.row.actualizationDate).toLocaleDateString(
                        "es-AR",
                        optionsDate
                    ) +
                    ", " +
                    new Date(params.row.actualizationDate).toLocaleTimeString(
                        "es-AR",
                        optionsHour
                    )
                );
            },
        },
        {
            field: "fechaBaja",
            headerName: "Baja",
            flex: 1,
            renderCell: (params) => {
                if (params.row.fechaBaja != null && params.row.state != "En Ingreso") {
                    return (
                        new Date(params.row.fechaBaja).toLocaleDateString(
                            "es-AR",
                            optionsDate
                        ) 
                    );
                    
                } else {
                    return "";
                }
            },
        },
        { field: "state", headerName: "Estado", minWidth: 150, flex: 1 },
        {
            field: "sector",
            headerName: "Sector",
            minWidth: 150,
            flex: 1,
            renderCell: (params) => {
                return (
                    params.row.state.toUpperCase() == 'EN INGRESO' ? 'Portal Web' : params.row.sector
                );
            },
        },

        {
            field: "observaAll",
            headerName: "Observaciones",
            flex: 1,
            type: "actions",
            renderCell: (params) => {
                const observaciones =
                    params.row.observa != null && params.row.observa !== "" ? (
                        <Tooltip
                            title={
                                <label
                                    style={{
                                        color: "white",
                                        fontSize: "12px",
                                        fontWeight: "300",
                                    }}
                                >
                                    Observaciones: {params.row.observa}
                                </label>
                            }
                        >
                            <IconButton>
                                <BsInfoSquare size={20} />
                            </IconButton>
                        </Tooltip>
                    ) : (
                        ""
                    );

                const observacionesObsba =
                    params.row.observaOSBA != null && params.row.observaOSBA !== "" && params.row.visibilidadObservacionesObsba === "S" ? (
                        <Tooltip
                            title={
                                <label
                                    style={{
                                        color: "white",
                                        fontSize: "12px",
                                        fontWeight: "300",
                                    }}
                                >
                                    Observaciones ObSBA: {params.row.observaOSBA}
                                </label>
                            }
                        >
                            <IconButton>
                                <BsInfoSquare size={20} color={Colors.blue} />
                            </IconButton>
                        </Tooltip>
                    ) : (
                        ""
                    );

                const observacionesFactura =
                    params.row.fac == "S" && params.row.tieneFactura == "N" ? (
                        <>
                            <Tooltip
                                title={
                                    <label
                                        style={{
                                            color: "white",
                                            fontSize: "12px",
                                            fontWeight: "300",
                                        }}
                                    >
                                        Esta carpeta requiere al menos un documento del tipo Factura
                                    </label>
                                }
                            >
                                <IconButton>
                                    <BsExclamationSquare size={20} color={Colors.red} />
                                </IconButton>
                            </Tooltip>
                        </>
                    ) : (
                        ""
                    );
                return (
                    <>
                        {observacionesFactura} {observaciones} {observacionesObsba}
                    </>
                );
            },
        },
        {
            field: "folderId",
            headerName: "Acciones",
            flex: 1,
            type: "actions",
            renderCell: (params) => (
                <Grid>
                    <Tooltip
                        title={
                            <label
                                style={{ color: "white", fontSize: "12px", fontWeight: "300" }}
                            >
                                Administrar Carpeta
                            </label>
                        }
                    >
                        <IconButton
                            component={Link}
                            to={`/dashboard/folder/${params.row.id} ${params.row.anio}`}
                            onClick={handleButtonClick}
                        >
                            <BsPen size={20} />
                        </IconButton>
                    </Tooltip>

                    {params.row.tieneDocumento == 'S' && <Tooltip
                        title={
                            <label
                                style={{ color: "white", fontSize: "12px", fontWeight: "300" }}
                            >
                                Previsualización
                            </label>
                        }
                    >
                        <IconButton
                            component={Link}
                            to={`/dashboard/preview/${params.row.id} ${params.row.anio}`}
                        >
                            <VisibilityIcon size={20} />
                        </IconButton>
                    </Tooltip>}

                </Grid>
            ),
        },
    ];

    const exportExcel = () => {
        let folderFilter = null;
        if (filterData != null) {
            folderFilter = {
                usercuit: user,
                Anio: filterData.Anio,
                TipoCarpeta: filterData.TipoCarpeta,
                CodCarpeta: filterData.CodCarpeta,
                Estado: filterData.Estado,
                FechaDesde: filterData.FechaDesde,
                FechaHasta: filterData.FechaHasta,
                CUIT: filterData.CUIT,
            };
        } else {
            folderFilter = {
                usercuit: user,
            };
        }
        dispatch(downloadExcel(folderFilter))
    }


    useEffect(() => {
        if (filterData)
            dispatch(GetFolders({
                usercuit,
                Estado: filterData.Estado,
                TipoCarpeta: filterData.TipoCarpeta,
                CodCarpeta: Number(filterData.CodCarpeta),
                Anio: Number(filterData.Anio),
                CUIT: Number(filterData.CUIT),
                FechaDesde: new Date(filterData.FechaDesde),
                FechaHasta: new Date(filterData.FechaHasta),
                ContieneAmparo: filterData.ContieneAmparo,
                PageNumber: paginacion.page,
                PageSize: paginacion.pageSize
            }));
        else
            dispatch(GetFolders({
                usercuit,
                PageNumber: paginacion.page,
                PageSize: paginacion.pageSize
            }));
    }, [paginacion, filterData]);

    return (
        <>
            <DataGrid
                getRowHeight={() => 'auto'}
                autoHeight
                rows={allData.data}
                rowCount={allData.recordsCount}
                getRowId={(row) => row?.id + row?.anio}
                columns={columnsGrid}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 5,
                        },
                    },
                }}
                pageSizeOptions={[5, 10, 25, 50, 100]}
                disableSelectionOnClick
                localeText={{
                    footerRowSelected: CustomPagination,
                    noRowsLabel: 'Sin datos',
                    noResultsOverlayLabel: 'Sin resultados'
                }}
                components={{
                    Footer: CustomFooter,
                }}
                paginationMode="server"
                paginationModel={paginacion}
                onPaginationModelChange={setPaginacion}
            />
            {folderStatus === "pending" || exportStatus === "pending" ? (
                <LoadingSpinner />
            ) : (
                <Grid container mt={2}>
                </Grid>
            )}

        </>
    );
}

export default FolderTable;
