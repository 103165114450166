import { GridFooterContainer, GridPagination } from "@mui/x-data-grid";

const CustomFooter = () => {
    return (
      <GridFooterContainer style={{ justifyContent: "flex-end" }}>
        <GridPagination labelRowsPerPage={'Filas por página'} />
      </GridFooterContainer>
    );
  }

  export default CustomFooter;