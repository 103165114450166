import { Grid, Box, Typography, Divider, Backdrop, Fade, Modal } from "@material-ui/core";
import styles from "../../pages/private/styles";
import { useState } from "react";
import { modalStyles } from "../../components/styles";
import Colors from "../../theme/colors";
import PropTypes from 'prop-types';

export const TemplateNewPassword = () => {
  const classes = styles();
  const obsBALogo = require("../../static/color-horizontal.png");

  return (
    <Grid container justifyContent='center' alignItems='center'>
      <Grid item>
        <Box>
          <img src={obsBALogo} alt="obsBA Logo" className={classes.logo} />
        </Box>
        <Typography className={classes.loginSubtitle}>
          Portal de Gestión de Carpeta Digital
        </Typography>
        <Box display='flex' justifyContent='center'>
          <Typography component="h1" variant="h4" style={{ marginTop: '15px', marginBottom: '15px' }}>
            ¿Olvidaste tu contraseña?
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}

export const CustomModal = ({ titleError, messageError, onClose, onClick }) => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  const hanldeClick = () => {
    setOpen(false);
    if (onClick) {
      onClick();
    }
  };

  CustomModal.propTypes = {
    titleError: PropTypes.string.isRequired,
    messageError: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      onClick={hanldeClick}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 1500,
      }}
    >
      <Fade in={open}>
        <Box sx={modalStyles}>
          <Typography
            id="transition-modal-title"
            variant="h6"
            component="h2"
            style={{ marginBottom: "5px", fontSize: "20px" }}
          >
            {titleError}
          </Typography>
          <Divider
            style={{ marginBottom: "15px", backgroundColor: Colors.blue }}
          />
          <Typography
            id="transition-modal-description"
            sx={{ mt: 2 }}
            style={{ fontSize: "15px", fontWeight: "300" }}
          >
            {messageError}
          </Typography>
        </Box>
      </Fade>
    </Modal>
  );
};