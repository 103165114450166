const Colors = {
    white: '#FFFFFF',
    blue: '#325AA5',
    lightblue: '#F9FAFC',
    hoverblue: '#4686B4',
    green: '#1AC0B3',
    darkGreen: '#07AB9E',
    black: '#000000DE',
    detailBlack: '#373737',
    grey: '#0000001F',
    darkGrey: '#00000099',
    red: '#FF3A3A'
};

export default Colors;