import React from "react";
import { Controller } from "react-hook-form";
import TextField from "@material-ui/core/TextField";

const InputTextField = (props) => {
  const { defaultValue,rules,name,control, ...otherProps } = props;
  return (
    <Controller
      name={name?name:''}
      rules={rules}
      control={control}
      render={({ field, value, onChange, ref }) => (
        <TextField 
          inputRef={ref} 
          {...field} 
          onChange={onChange} 
          {...otherProps} 
          value={value || ""}/>
          
      )}
    />
  );
};

export default InputTextField;
