import {  Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { DeleteProfileUser } from "../../redux/slices/usersSlice";
import {SetInformation} from "../../redux/slices/modalSlice";
import { useNavigate } from "react-router-dom";

const DialogDeleteProfile = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [message, setMessage] = useState();

  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      open={props.isOpen}
      onClose={() => props.handleClose()}
    >
      <DialogTitle id="alert-dialog-title">¡Atención!</DialogTitle>
      <DialogContent>
        ¿Está seguro de dar de baja el perfil {props.dataToModify.PERFIL}?
        Al eliminar un perfil, debera reingresar al sistema.
        
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.handleClose()}>CANCELAR</Button>

        <Button onClick={()=>{
            dispatch(DeleteProfileUser({
              CUIT: props.dataToModify.CUIT,
              Perfil:props.dataToModify.PERFIL 
            })).then((e) => {
              setMessage(e.payload);
              dispatch(SetInformation(
                {
                  title:'Operación realizada exitosamente',
                  description: message
                }
              ));
              props.handleClose();
            }).catch((e)=>{
              setMessage("Ha ocurrido un error al realizar la operación, por favor intente de nuevo.");
              dispatch(SetInformation(
                {
                  title:'Error',
                  description:message
                }
              ));
              navigate('/error');
            })
        }}
            
          >CONFIRMAR</Button>
      </DialogActions>
    </Dialog>
  );
};
export default DialogDeleteProfile;
