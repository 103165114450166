import axios from "axios";

export let api = axios.create({
  baseURL: process.env.REACT_APP_OBSBA_API_URL,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    "Authorization": `Bearer ${localStorage.getItem("token_app")}`
  },
});

export let apiPayslips = axios.create({
  baseURL: process.env.REACT_APP_META_API_URL,
  headers: {
    "client_id": "9ba5db38",
    "client_secret": "f8aba6a3dd8d5b1b6e1b85ea92d88d74"
  },
});
