import React, {  } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  CssBaseline,
  Grid,
  Typography,
  Box,
  Hidden,
} from "@material-ui/core";

import Copyright from "../../../components/Copyrigth";
import styles from "../styles";

const Logo = require("../../../static/img_ban.png")

const NewPasswordConfirmation = () => {
  const classes = styles();

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={12} sm={8} md={6}>
        <div className={classes.paper}>
          <form className={classes.form} noValidate style={{ width: "30%" }}>
            <div className={classes.logo} />
            <Typography className={classes.loginSubtitle}>
              Portal de Gestión de Carpeta Digital
            </Typography>
            <Typography
              component="h1"
              variant="h4"
              style={{ marginTop: "15px", marginBottom: "15px" }}
            >
              Cambio de contraseña
            </Typography>

            <Typography
              className={classes.label}
              style={{ textAlign: "center" }}
            >
              Ya puede loguear con su nueva contraseña
            </Typography>

            <Link to="/" style={{ width: "100%", textDecoration: "none" }}>
              <Button
                type="submit"
                variant="outlined"
                color="primary"
                className={classes.button}
                fullWidth
              >
                Volver
              </Button>
            </Link>

            <Grid
              container
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginTop: "5px",
              }}
            >
              <Grid item>
                <Typography className={classes.label}>
                  ¿Ya tenés cuenta?
                </Typography>
              </Grid>
              <Grid item>
                <Link
                  to="/"
                  variant="body2"
                  className={classes.link}
                  style={{
                    textDecoration: "none",
                    fontWeight: "bold",
                    marginLeft: "5px",
                  }}
                >
                  Iniciar sesión
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
      <Hidden smDown>
        <Grid xs={false} md={6}>
          <img style={{ width: '100%', height: '100%' }} src={Logo} />
        </Grid>
      </Hidden>
    </Grid>
  );
}
export default NewPasswordConfirmation;