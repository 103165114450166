import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import store from "./redux/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { MuiThemeProvider } from "@material-ui/core";
import theme from "./theme";
import { ErrorBoundary } from "./components/ErrorBoundary/index"
import UseIdleTimer from "./hook/UseIdleTimer"
import ModalProvider from "./components/Modal";
import { BrowserRouter } from "react-router-dom";

ReactDOM.render(
  <Provider store={store}>

    
      <MuiThemeProvider theme={theme}>
        <BrowserRouter>
        <ModalProvider>
          <UseIdleTimer>
            <ErrorBoundary>
              <App />
            </ErrorBoundary>
          </UseIdleTimer>
          </ModalProvider>
        </BrowserRouter>
      </MuiThemeProvider>
    

  </Provider>,

  document.getElementById("root")
);

serviceWorker.unregister();
