import {
  Button,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import React, { useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import InputTextField from "../../../components/Forms/input";
import InputAutoComplete from "../../../components/Forms/inputAutocomplete";
import InputCheckBox from "../../../components/Forms/inputCheckbox";
import { FormatCuil, ValidateCuit } from "../../../helpers/validators";
import {
  CreateFolderRequest,
  SetFilterFolder,
  dateDefaultValues,
} from "../../../redux/slices/dashboardSlice";
import {
  SetInformation,
  setInformationAsync,
} from "../../../redux/slices/modalSlice";
import { selectUser } from "../../../redux/slices/signInSlice";
import Colors from "../../../theme/colors";
import Styles from "../styles";
import { useNavigate } from "react-router-dom";
import FolderTable from "../FolderFilter/FolderTable";
import { breadcrumbs } from "../../../settings";
import { setBreadcrumbs } from "../../../redux/slices/generalSlice";
import {
  useGetEstadosCarpetaQuery,
  useGetTipoCarpetaQuery,
} from "../../../api/Common";
import LoadingSpinner from "../../../components/LoadingSpinner";

const CreateFolder = (props) => {
  const style = Styles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectUser).user;
  const { data: dataEstadoCarpeta, isLoading: loadingEstadoCarpeta } =
    useGetEstadosCarpetaQuery();
  const { data: dataTipoCarpeta, isLoading: loadingTipoCarpeta } =
    useGetTipoCarpetaQuery();
  const tipoCarpetaRef = useRef(null);

  dispatch(setBreadcrumbs(breadcrumbs.principal));
  const {
    handleSubmit,
    register,
    reset,
    control,
    watch,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      cuitBenef: FormatCuil(user.n_DOCUMENTO.toString()),
      date: dateDefaultValues.today,
      importePrestacion: 0,
      folderTitle: "",
      folderDescription: "",
      checkPago: false,
      checkAmparo: false,
      folderType: "",
    },
    mode: "onChange",
  });

  let checkPago = watch("checkPago", "");
  let checkBenef = watch("checkBenef", "");
  let tipoCarpeta = watch("folderType", "");

  useEffect(() => {
    const tiposRequierenPago = [
      "Prestador Común",
      "Bien Salud",
      "Sanatorio",
      "Fondos Permanentes",
      "F Con Cargos A Rend.",
    ];
    const hasPayment =
      tiposRequierenPago.includes(tipoCarpeta) &&
      (user.t_PERFILES.includes("Prestador") ||
        user.t_PERFILES.includes("Interno"));
    if (hasPayment) {
      setValue("checkPago", true);
      dispatch(
        SetInformation({
          title: "Información",
          description:
            "En caso que la carpeta cargada NO contenga pago, deberá quitar la tilde de 'CONTIENE PAGO'",
        })
      );
    } else {
      setValue("checkPago", false);
    }
  }, [tipoCarpeta]);

  useEffect(() => {
    if (!checkBenef) {
      if (!ValidateCuit(watch("cuitBenef", "").toString()))
        clearErrors("cuitBenef");
      setValue("cuitBenef", FormatCuil(user.n_DOCUMENTO.toString()));
    }
  }, [checkBenef]);

  const onSubmit = (data) => {
    dispatch(
      CreateFolderRequest({
        Anio: new Date().getFullYear(),
        Titulo: data.folderTitle,
        TipoCarpeta: dataTipoCarpeta.filter(
          (e) => e.label == data.folderType
        )[0].value,
        ImportePrestacion: data.importePrestacion,
        UsuarioId: user.o_USUARIO,
        Observaciones: data.folderDescription,
        Factura: data.checkPago ? "S" : "N",
        Amparo: data.checkAmparo ? "S" : "N",
        Estado: "E",
        FechaPrestacion: data.fechaPrestacion
          ? new Date(
              `${data.fechaPrestacion._d.getFullYear()}-${
                data.fechaPrestacion._d.getMonth() + 1
              }-01`
            )
          : null,
        CUITBenef: data.cuitBenef?.toString()?.replace(/-/g, "")
      })
    ).then(async (e) => {
      dispatch(SetFilterFolder(null));
      await dispatch(
        setInformationAsync({
          title: "Información",
          description:
            "Estimado usuario, le informamos que su carpeta ha sido guardada con éxito. Si desea agregar documentación, por favor diríjase a Carpeta > Nuevo Documento. ¡Gracias por su colaboración!",
          modalAsync: true,
        })
      );
    });
  };

  const onClearData = (data) => {
    const t = tipoCarpetaRef.current.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (t) t.click();
    reset();
  };

  return (
    <>
      {loadingEstadoCarpeta || (loadingTipoCarpeta && <LoadingSpinner />)}
      {dataEstadoCarpeta && dataTipoCarpeta && (
        <>
          <Grid container justifyContent={"center"}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid
                container
                spacing={2}
                style={{ width: "400px", minWidth: "400px" }}
              >
                <Grid item xs={12}>
                  <Typography style={{ fontSize: "20px" }} align={"center"}>
                    Alta Carpeta
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    control={control}
                    rules={{
                      required: "Campo Obligatorio",
                    }}
                    name="folderType"
                    render={({ value, onChange, field, ref }) => (
                      <Autocomplete
                        ref={tipoCarpetaRef}
                        options={dataTipoCarpeta.filter(
                          (e) => e.label !== "Deuda Flotante"
                        )}
                        getOptionLabel={(option) => option.label}
                        getOptionSelected={(option, value) =>
                          option.value === value.value
                        }
                        onChange={(e, data) => onChange(data ? data.label : "")}
                        renderInput={(params) => (
                          <TextField
                            name="folderType"
                            variant="outlined"
                            {...params}
                            {...field}
                            label="Tipo de carpeta"
                            error={!!errors.folderType}
                            helperText={errors.folderType?.message}
                            inputRef={register}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <InputAutoComplete
                    defaultValue={{ label: "En Ingreso", value: "E" }}
                    name="folderState"
                    control={control}
                    options={dataEstadoCarpeta}
                    label="Estado"
                    placeholder="Estado"
                    disabled={true}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputTextField
                    className={style.textField}
                    fullWidth
                    name="date"
                    type={"date"}
                    variant="outlined"
                    control={control}
                    label="Fecha"
                    disabled
                  />
                </Grid>

                <Grid item xs={6}>
                  <InputCheckBox
                    control={control}
                    className={`${style.textField} ${style.checkBox}`}
                    id="idChkContienePago"
                    name="checkPago"
                    label={
                      <Typography style={{ fontSize: 14 }}>
                        Contiene pago
                      </Typography>
                    }
                  />
                  <InputCheckBox
                    control={control}
                    name="checkAmparo"
                    className={style.checkBox}
                    label={
                      <Typography style={{ fontSize: 14 }}>
                        Contiene Amparo
                      </Typography>
                    }
                  />
                </Grid>

                {checkPago &&
                  tipoCarpeta != "" &&
                  !tipoCarpeta.includes("Reintegro") && (
                    <>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12}>
                        <InputCheckBox
                          control={control}
                          name="checkBenef"
                          label={
                            <Typography style={{ fontSize: 14 }}>
                              No soy beneficiario/a del pago
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          name="cuitBenef"
                          control={control}
                          rules={{
                            required: "Campo obligatorio",
                            validate: (value) => {
                              return !ValidateCuit(value)
                                ? "CUIT/CUIL inválido"
                                : null;
                            },
                          }}
                          render={({ field, ref, onChange, value }) => (
                            <InputTextField
                              disabled={checkBenef == false ? true : false}
                              className={style.textField}
                              fullWidth
                              name="cuitBenef"
                              id="idInpCuit"
                              variant="outlined"
                              control={control}
                              value={FormatCuil(value.toString())}
                              onChange={(e) => {
                                let numericValue = e.target.value.replace(
                                  /[^\d]/g,
                                  ""
                                );
                                let formattedValue = FormatCuil(numericValue.toString());
                                onChange(formattedValue);
                              }}
                              label="CUIT/CUIL Benef. Pago"
                              error={!!errors.cuitBenef}
                              helperText={errors.cuitBenef?.message}
                              inputProps={{
                                maxLength:13
                              }}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Controller
                          name="fechaPrestacion"
                          control={control}
                          rules={{
                            required: "Campo obligatorio",
                          }}
                          render={({ field, value, onChange }) => (
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                              <DatePicker
                                {...field}
                                id="idInpFechaPrestacion"
                                value={value ? moment(value) : null}
                                onChange={(e) => onChange(e)}
                                views={["year", "month"]}
                                format="MM-YYYY"
                                label="Fecha de Prestación"
                                sx={{
                                  width: "100%",
                                  border: `1px solid ${
                                    errors.fechaPrestacion ? "#f44336" : "#ccc"
                                  }`,
                                  borderRadius: "4px",
                                }}
                                inputRef={errors.fechaPrestacion}
                              />
                            </LocalizationProvider>
                          )}
                        />
                        {errors?.fechaPrestacion && (
                          <p
                            style={{
                              color: "#f44336",
                              fontSize: 12,
                              margin: "3px 14px 0px",
                            }}
                          >
                            {" "}
                            {errors.fechaPrestacion.message}{" "}
                          </p>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <InputTextField
                          type={"number"}
                          className={style.textField}
                          fullWidth
                          name="importePrestacion"
                          id="idInpImporte"
                          variant="outlined"
                          control={control}
                          label="Importe de Prestación"
                          error={!!errors.importePrestacion}
                          helperText={errors.importePrestacion?.message}
                          rules={{
                            validate: (value) => {
                              return value != "" ? null : "Campo obligatorio";
                            },
                            valueAsNumber: true,
                            min: {
                              value: 1,
                              message: "Rango entre $1 y $999.999.999.999,99",
                            },
                            max: {
                              value: 1000000000000,
                              message: "Rango entre $1 y $999.999.999.999,99",
                            },
                            pattern: {
                              value: /^\d+(\.\d{0,2})?$/,
                              message: "Rango entre $1 y $999.999.999.999,99",
                            },
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            )
                          }}
                          onKeyPress={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                        />
                      </Grid>
                    </>
                  )}
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <InputTextField
                    className={style.textField}
                    fullWidth
                    name="folderTitle"
                    id="idInpTitulo"
                    variant="outlined"
                    control={control}
                    label="Titulo de Carpeta"
                    rules={{
                      required: "Campo obligatorio",
                      validate: (value) => {
                        return !/^\s*$/.test(value)
                          ? null
                          : "No se aceptan solo espacios en blanco.";
                      },
                    }}
                    error={!!errors.folderTitle}
                    helperText={errors.folderTitle?.message}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputTextField
                    className={style.textField}
                    fullWidth
                    name="folderDescription"
                    id="idInpDescripcion"
                    variant="outlined"
                    control={control}
                    label="Descripcion de Carpeta"
                    rules={{
                      required: "Campo obligatorio",
                    }}
                    error={!!errors.folderDescription}
                    helperText={errors.folderDescription?.message}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider style={{ marginBottom: "15px" }} />
                </Grid>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    onClick={() => onClearData()}
                    style={{
                      backgroundColor: Colors.white,
                      border: "2px solid",
                      borderColor: Colors.blue,
                      color: Colors.blue,
                      maxHeight: "50px",
                      minHeight: "50px",
                    }}
                  >
                    Limpiar
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    type="submit"
                    fullWidth
                    style={{
                      backgroundColor: Colors.blue,
                      border: "2px solid",
                      borderColor: Colors.blue,
                      color: Colors.white,
                      maxHeight: "50px",
                      minHeight: "50px",
                    }}
                  >
                    Guardar
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    onClick={() => navigate(-1)}
                    fullWidth
                    style={{
                      backgroundColor: Colors.blue,
                      border: "2px solid",
                      borderColor: Colors.blue,
                      color: Colors.white,
                      maxHeight: "50px",
                      minHeight: "50px",
                    }}
                  >
                    Volver
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Divider style={{ marginBottom: "15px" }} />
                </Grid>
              </Grid>
            </form>
          </Grid>
          <FolderTable />
        </>
      )}
    </>
  );
};

export default CreateFolder;
