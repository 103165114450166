export const appRoutes =
    {
        private:{
            createFolder: '/dashboard/createfolder'
        },
        public:{
            login: '/login',
            register: '/register',
            registerConfirmation: '/register/confirmation',
            registerValidationToken: '/register/complete/:cuit/:token',
            forgotPassword: '/forgotPassword',
            newPassword: '/newPassword/:id',
            recoverPassword: '/recoverPassword/:id',
            recoverPasswordConfirmation: '/recoverPasswordConfirmation',
            error: '/error',
            errorCode: '/error/:code',
            maintenance: '/enmantenimiento'
        }
    };  

export const breadcrumbs = {
    principal: 'ObSBA Carpeta Digital',
    myProfile: 'Mi Perfil',
    adminUser: 'ObSBA Administración de Usuarios del Portal Web',
    salary: 'ObSBA Recibos de Sueldo'
}