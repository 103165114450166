import { Button, Checkbox, Divider, FormControlLabel, Grid, TextField, Tooltip, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { CuitMask } from "../../../helpers/maskedImputs";
import { ValidateCuit, ValidateFilterDate } from "../../../helpers/validators";
import { SetFilterFolder, folderTypesBDescriptor, stateTypesBDescriptor } from "../../../redux/slices/dashboardSlice";
import { selectUser } from "../../../redux/slices/signInSlice";
import Colors from "../../../theme/colors";
import Styles from "../styles";
import FolderTable from "./FolderTable";
import { FolderNotSend } from "./FolderNotSend";
import { useNavigate } from "react-router-dom";
import { appRoutes, breadcrumbs } from "../../../settings";
import { setBreadcrumbs } from "../../../redux/slices/generalSlice";
import { IoMdHelpCircleOutline } from "react-icons/io";

const FolderFilter = () => {
  const style = Styles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleSubmit, register, reset } = useForm({});
  const FolderDataset = useSelector((state) => state.dashboard.folder.data);
  const user = useSelector(selectUser).user;
  const folderTypes = useSelector(folderTypesBDescriptor).data;
  const folderStates = useSelector(stateTypesBDescriptor).data;
  const [error, setError] = useState(false);
  const [cuitError, setCuitError] = useState(false);
  const [leyenda, setLeyenda] = useState("");
  const [leyendaCuit, setLeyendaCuit] = useState("");
  const [checked, setChecked] = useState(false);
  const tipoCarpeta = useRef(null);
  const estadoCarpeta = useRef(null);
  const [username, setUsername] = useState("");
  const [folderId, setFolderId] = useState(null);
  const [folderYear, setFolderYear] = useState(null);


  dispatch(setBreadcrumbs(breadcrumbs.principal));

  const onClearData = () => {
    reset();
    setCuitError('');
    setLeyendaCuit('');
    setUsername('');
    setFolderId(null);
    setFolderYear(null);

    const t = tipoCarpeta.current.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (t) t.click();

    const e = estadoCarpeta.current.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (e) e.click();

    setChecked(false);

    document.activeElement.blur();

    const folderFilter = {
      usercuit: user,
      Anio: 0,
      TipoCarpeta: "",
      CodCarpeta: "",
      Estado: "",
      FechaDesde: "",
      FechaHasta: "",
      CUIT: 0,
      ContieneAmparo: "",
      PageNumber: 1,
      PageSize: 5,
    };

    dispatch(SetFilterFolder(folderFilter));
  };

  const onSubmit = (data) => {
    if (!ValidateFilterDate(data.dateFrom, data.dateTo)) {
      setError(true);
      setLeyenda("Fecha desde debe ser menor a Fecha hasta");
    } else {
      setError(false);
      setLeyenda("");
      const folderFilter = {
        usercuit: user,
        Anio: data.folderYear,
        TipoCarpeta: data.folderType,
        CodCarpeta: data.folderNumber,
        Estado: data.folderState,
        FechaDesde: data.dateFrom,
        FechaHasta: data.dateTo,
        CUIT: username.replace(/-/g, ""),
        ContieneAmparo: data.contieneAmparo? "S" : "",
        PageNumber: 1,
        PageSize: 5,
      };
      dispatch(SetFilterFolder(folderFilter));
      
    }
  
  };

  const onCuitChange = (e) => {
    if (e.target.value && !ValidateCuit(e.target.value)) {
      setCuitError(true);
      setLeyendaCuit("CUIT/CUIL inválido");
    } else {
      setCuitError(false);
      setLeyendaCuit("");
    }

    setUsername(e.target.value);
  };

  useEffect(() => {
    return () => {
      onClearData()
    }
  }, [])
  
  

  return (
    <>
      {FolderDataset && <FolderNotSend data={FolderDataset} /> }
      
        <form
          className={style.form}
          style={{
            display: "flex",
            justifyContent: "center",
            minWidth: "400px",
            width: "100%",
            marginTop: 10,
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid
            container
            spacing={2}
            style={{
              display: "flex",
              justifyContent: "center",
              width: "800px",
              minWidth: "400px",
            }}
          >
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Typography style={{ fontSize: "20px" }}>Consulta Carpeta</Typography>
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                id="folderTypeAutocomplete"
                options={folderTypes}
                getOptionLabel={(option) => option.label}
                getOptionSelected={(option, value) =>
                  option.value === value.value
                }
                fullWidth
                autoFocus
                ref={tipoCarpeta}
                className={style.textField}
                renderInput={(param) => (
                  <TextField
                    {...param}
                    id="folderType"
                    label="Tipo de carpeta"
                    name="folderType"
                    placeholder="Tipo de carpeta"
                    variant="outlined"
                    className={style.textField}
                    inputRef={register}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="folderYear"
                name="folderYear"
                placeholder="Año de carpeta"
                variant="outlined"
                className={style.textField}
                inputRef={register}
                inputProps={{
                  maxLength: 4,
                  type: 'text',
                }}
                onChange={(e) => {
                  let value = e.target.value;
                  let input = value.replace(/[^\d]/g, '');
                  setFolderYear(input);
                }}
                value={folderYear}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                id="folderStateAutocomplete"
                options={folderStates}
                getOptionLabel={(option) => option.label}
                getOptionSelected={(option, value) =>
                  option.value === value.value
                }
                fullWidth
                className={style.textField}
                ref={estadoCarpeta}
                renderInput={(param) => (
                  <TextField
                    {...param}
                    id="folderState"
                    label="Estado de carpeta"
                    name="folderState"
                    placeholder="Estado de carpeta"
                    variant="outlined"
                    inputRef={register}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="folderNumber"
                name="folderNumber"
                fullWidth
                placeholder="Número de carpeta"
                variant="outlined"
                className={style.textField}
                inputRef={register}
                inputProps={{
                  maxLength: 9,
                  type: 'text',
                }}
                onChange={(e) => {
                  let inputValue = e.target.value.replace(/[^\d]/g, '');
                  setFolderId(inputValue);
                }}
                value={folderId}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="dateFrom"
                name="dateFrom"
                label="Fecha desde"
                variant="outlined"
                type="date"
                fullWidth
                error={error}
                helperText={leyenda}
                inputRef={register}
                className={style.textField}
                inputprops={{
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="dateTo"
                name="dateTo"
                label="Fecha hasta"
                variant="outlined"
                type="date"
                fullWidth
                error={error}
                helperText={leyenda}
                inputRef={register}
                className={style.textField}
                inputprops={{
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                margin="normal"
                id="username"
                label="CUIT/CUIL"
                name="username"
                fullWidth
                className={style.textField}
                onChange={onCuitChange}
                value={username}
                inputRef={register}
                error={cuitError}
                helperText={leyendaCuit}
                InputProps={{
                  inputComponent: CuitMask,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                style={{ marginTop: "7px" }}
                control={
                  <Checkbox
                    style={{ color: Colors.blue }}
                    inputRef={register}
                    id="contieneAmparo"
                    name="contieneAmparo"
                    checked={checked}
                    onChange={(e) => setChecked(e.target.checked)}
                  />
                }
                label={
                  <Typography style={{ fontSize: 14 }}>
                    Contiene Amparo
                  </Typography>
                }
              />
            </Grid>
            <Grid
              item
              xs={4}
              style={{
                width: "100%",
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Button
                id="idBtnLimpiar"
                size="large"
                type="button"
                variant="outlined"
                onClick={onClearData}
                style={{
                  margin: 0,
                  backgroundColor: Colors.white,
                  border: "2px solid",
                  borderColor: Colors.blue,
                  color: Colors.blue,
                  maxHeight: "50px",
                  minHeight: "50px",
                }}
                fullWidth
              >
                Limpiar
              </Button>
            </Grid>
            <Grid
              item
              xs={4}
              style={{
                width: "100%",
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Button
                size="large"
                type="submit"
                id="idBtnBuscar"
                variant="outlined"
                style={{
                  margin: 0,
                  backgroundColor: Colors.blue,
                  border: "2px solid",
                  borderColor: Colors.blue,
                  color: Colors.white,
                  maxHeight: "50px",
                  minHeight: "50px",
                }}
                fullWidth
              >
                Buscar
              </Button>
            </Grid>
            <Grid
              item
              xs={8}
              style={{
                width: "100%",
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Button
                onClick={()=>{navigate(appRoutes.private.createFolder)}}
                size="large"
                id="idBtnNuevaCarpeta"
                type="button"
                variant="outlined"
                style={{
                  margin: 0,
                  backgroundColor: Colors.blue,
                  border: "2px solid",
                  borderColor: Colors.blue,
                  color: Colors.white,
                  maxHeight: "50px",
                  minHeight: "50px",
                }}
                fullWidth
              >
                NUEVA CARPETA
              </Button>
              <Tooltip
                title={
                  <Typography
                    style={{
                      color: "white",
                      fontSize: "12px",
                      fontWeight: "300",
                    }}
                  >
                    Videotutorial de gestión de Carpetas
                  </Typography>
                }
              >
                <Button
                  component="a"
                  href="https://www.youtube.com/watch?v=CM8ljqgo_fo"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "grey",
                  }}
                >
                  <IoMdHelpCircleOutline size={35} />
                </Button>
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <Divider style={{ marginBottom: "15px" }} />
            </Grid>
          </Grid>

        </form>
      
      <FolderTable />
    </>
  );
}

export default FolderFilter;