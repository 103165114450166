import { ViewItemDashboard } from "./validators";

export const soloEmpleado = (data) =>{
    return data.t_PERFIL == "Empleado" && data.t_PERFILES.length == 1;
}

export const soloInterno = (data) => {
    return data.t_PERFIL == "Interno" && data.t_PERFILES.length == 1;
}

export const esUsuarioRRHH = (data) =>{
    return data.c_ROL_USUARIO === 'OSBA_PERSONA_RRHH';
}

export const esUsuarioADMIN = (data) =>{
    return data.c_ROL_USUARIO === 'OSBA_PERSONA_ADMIN';
}

export const esInterno = (data) => {
    return data.t_PERFIL == "Interno" || data.t_PERFILES.includes("Interno");
}

export const esInternoEmpleado = (data) =>{
    return data.t_PERFILES.includes("Interno") && data.t_PERFILES.includes("Empleado") && data.t_PERFILES.length == 2;
}

export const esEmpleado = (data) => {
  return data.t_PERFIL == "Empleado" || data.t_PERFILES.includes("Empleado");
}

export const NavegarHome = (data) =>{
    if (soloEmpleado(data)) 
        return "/dashboard/payslips"
    else if (ViewItemDashboard(data))
        return "/dashboard";
    else if (esUsuarioRRHH(data))
        return "/dashboard/payslipsmanager";
    else 
        return "/dashboard";
}