import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  breadcrumbs: null,
  actualFolderId: null
};

const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    setBreadcrumbs :  (state, action) =>{
      state.breadcrumbs =  action.payload
    },
    setActualFolderId: (state, action) =>{
      state.actualFolderId = action.payload
    }
  }
});

export const {
  setBreadcrumbs,
  setActualFolderId
} = generalSlice.actions;

export const selectBreadcrumbs = (state) => state.general.breadcrumbs;
export const selectActualFolderId = (state) => state.general.actualFolderId;
export default generalSlice.reducer;