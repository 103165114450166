
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../api";



// -----------------------------------------------------------
// ------------------------ USER --------------------------
// -----------------------------------------------------------

// export const GetDataByCuil = createAsyncThunk("/recovery/cuilexist", async (data, dispatch) => {
//     try {
//       const response = await api.get("/Usuario_Portal/cuil?cuil=" + data.cuit.n_documento);
      
//       return response.data;
//     } catch (e) {
//       return console.error(e.message);
//     }
//   });

export const GetDataByCuil = createAsyncThunk("/recovery/cuilexist", async (data, dispatch) => {
  try {
    const response = await api.get(`/usuario_portal/cuil/${Number(data.id.n_documento)}`);
    return response.data;
  } catch (e) {
    return console.error(e.message);
  }
});

export const updateUserData = createAsyncThunk("/userData", async (data, dispatch) => {
    try {
      const response = await api.put("/Usuario_Portal/ChangeDataUserAdmin", {
        user: data.o_usuario,
        o_USUARIO: data.o_usuario,
        xC_USER: data.xc_user,
        t_PERFIL: data.t_perfil.label,
        c_USER: data.c_user,
        t_DOCUMENTO: data.t_documento,
        n_DOCUMENTO: data.n_documento,
        n_TELEFONO: data.n_telefono,
        xL_DOMICILIO: data.xl_domicilio,
        xL_EMAIL: data.xl_email,
        n_LOGIN_FALLIDO: data.n_login_fallido,
        xl_MOTIVO_BAJA: data.xl_motivo_baja,
        fh_BAJA: data.fh_baja
      });
      return response.data;
    } catch (e) {
      return dispatch.rejectWithValue(e)
    }
  });

  // -----------------------------------------------------------
// ------------------------- SLICE ---------------------------
// -----------------------------------------------------------

const initialState = {
    loading: false,
    user: null,
    exist: false,
    error: null,
    success: false,
  };
  
  const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
      updateUserDataSuccess: (state, action) => {
        state.register = action.payload.data;
      },
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetDataByCuil.pending, (state) => {
                state.manageuser.loading = "pending";
            })
            .addCase(GetDataByCuil.fulfilled, (state, action) => {
                state.manageuser.data = action.payload;
                state.manageuser.loading = "idle";
                state.manageuser.success = true;
            })
            .addCase(GetDataByCuil.rejected, (state, action) => {
                state.manageuser.error = 'Token Expirado';
                state.manageuser.loading = "idle";
            })
            .addCase(updateUserData.pending, (state) => {
                state.updateUser.loading = "pending";
                state.updateUser.error = null;
            })
            .addCase(updateUserData.fulfilled, (state, action) => {
                state.updateUser.data = action.payload;
                state.updateUser.loading = "idle";
                state.updateUser.success = true;
                state.updateUser.error = null;
            })
            .addCase(updateUserData.rejected, (state, action) => {
                state.error = action.payload.message;
                state.updateUser.loading = "idle";
            })
    }
  });
  
  export const { updateUserDataSuccess } = userSlice.actions;
  
  export const selectUser = (state) => state.user;
  export const selectManageUser = (state) => state.manageuser;
  
  export default userSlice.reducer;
  