
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Tooltip,
  IconButton,
  Grid,
  Typography,
} from "@material-ui/core";
import {
  BsPen,
  BsInfoSquare,
  BsFillPersonFill,
  BsFillTrashFill,
  BsCheckCircle,
  BsFillStarFill
} from "react-icons/bs";
import Colors from "../../../theme/colors";

import {
  DataGrid
} from "@mui/x-data-grid";
import  DialogConfirm  from "../../../components/DialogConfirm";
import { CreateUser, GetUsers } from "../../../redux/slices/usersSlice";
import { SetInformation } from "../../../redux/slices/modalSlice";
import LoadingSpinner from "../../../components/LoadingSpinner";
import DialogDeleteUser from "../../../components/DialogDeleteUser";
import CustomPagination from "../../../components/Table/CustomPagination";
import CustomFooter from "../../../components/Table/CustomFooter";


let optionsDate = { year: "numeric", month: "short", day: "numeric" };
let optionsHour = { hour: "2-digit", minute: "2-digit" };

const UsersTable = () => {
  const cantidadTotal2 = useSelector((state) => state.users.users.data.cantidadTotal);
  const loading = useSelector((state) => state.users.users.loading);
  const userFilter = useSelector((state) => state.users.usersFiltered);
  const dispatch = useDispatch();
  
  const [row, setRow] = useState([]);

  const [paginacion, setPaginacion] = useState({
    page: 0,
    pageSize: 5
  })
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [dataToModify, setDataToModify] = useState({});

  useEffect(()=>{
    dispatch(GetUsers({
      pageNumber: paginacion.page,
      pageSize: paginacion.pageSize
    })).then((e)=>{
        setRow(e.payload.listaResultado);
    })
  },[])

  useEffect(()=>{
    if(userFilter!=null)
      dispatch(GetUsers({
        razonSocial: userFilter.razonSocial,
        tipoUsuario: userFilter.tipoUsuario,
        nombreUsuario: userFilter.nombreUsuario,
        intentosFallidos: userFilter.intentosFallidos,
        nroDocumento: userFilter.nroDocumento,
        fechaDesde: userFilter.fechaDesde,
        fechaHasta: userFilter.fechaHasta,
        pageNumber: paginacion.page,
        pageSize: paginacion.pageSize
      })).then((e)=>{
        setRow(e.payload.listaResultado);
    })
    else
    dispatch(GetUsers({
      pageNumber: paginacion.page,
      pageSize: paginacion.pageSize
    })).then((e)=>{
        setRow(e.payload.listaResultado);
    })
  
  }, [paginacion, userFilter])

  const columnsGrid2 = [
    {
      field: "icon",
      headerName: "",
      flex: 1,
      type: "actions",
      renderCell: (params) => (
        <Grid>
          <Tooltip
            title={
              <Typography
                style={{ color: "white", fontSize: "12px", fontWeight: "300" }}
              >
                Usuario
              </Typography>
            }
          >
            <IconButton
              component={Link}
              to={`/manageUser/${params.row.o_USUARIO}`}
            >
              <BsFillPersonFill size={20} />
            </IconButton>
          </Tooltip>
        </Grid>
      ),
    },
    { field: "xC_USER", headerName: "Usuario", minWidth: 150, flex: 1,
      renderCell: (params) =>{
        return (  
            params.row.t_PERFIL == 'Interno' && params.row.c_ROL_USUARIO == 'OSBA_PERSONA_ADMIN'?
             (<Typography>  {params.row.xC_USER} <BsFillStarFill size={20}/></Typography>):
            params.row.xC_USER
        )
      } 
    },
    { field: "t_PERFIL", headerName: "Perfil", minWidth: 100, flex: 1 },
    { field: "n_DOCUMENTO", headerName: "CUIT/CUIL", minWidth: 150, flex: 1 },
    { field: "c_USER", headerName: "Usu SIGAF", minWidth: 100, flex: 1 },
    { field: "n_TELEFONO", headerName: "Telefono", minWidth: 100, flex: 1},
    { field: "xL_EMAIL", headerName: "Email", minWidth: 100, flex: 1 },
    { field: "n_LOGIN_FALLIDO", headerName: "Int. Fall.", flex: 1 },
    {
      field: "f_ALTA",
      headerName: "FH Alta",
      minWidth: 100, flex: 1,
      renderCell: (params) => {
        return (
          new Date(params.row.fH_ALTA).toLocaleDateString(
            "es-AR",
            optionsDate
          ) +
          ", " +
          new Date(params.row.fH_ALTA).toLocaleTimeString("es-AR", optionsHour)
        );
      },
    },
    {
      field: "f_BAJA",
      headerName: "FH Baja",
      minWidth: 100, flex: 1,
      renderCell: (params) => {
        return params.row.fH_BAJA
          ? new Date(params.row.fH_BAJA).toLocaleDateString(
              "es-AR",
              optionsDate
            ) +
              ", " +
              new Date(params.row.fH_BAJA).toLocaleTimeString(
                "es-AR",
                optionsHour
              )
          : "";
      }
    },
    {
      field: "reasonLow",
      headerName: "Motivo Baja",
      flex: 1,
      type: "actions",
      renderCell: (params) =>
        params.row.xL_MOTIVO_BAJA != null &&
        params.row.xL_MOTIVO_BAJA != "" && (
          <Grid>
            <Tooltip
              title={
                <label
                  style={{
                    color: "white",
                    fontSize: "12px",
                    fontWeight: "300",
                  }}
                >
                  {params.row.xL_MOTIVO_BAJA}
                </label>
              }
            >
              <IconButton>
                <BsInfoSquare size={20} color={Colors.red} />
              </IconButton>
            </Tooltip>
          </Grid>
        ),
    },
    {
      field: "userId",
      headerName: "Acciones",
      flex: 1,
      type: "actions",
      renderCell: (params) => {
        return (
        <Grid>
          {params.row.fH_BAJA == null && 
          <Tooltip
            title={
              <Typography
                style={{ color: "white", fontSize: "12px", fontWeight: "300" }}
              >
                Administrar Usuario
              </Typography>
            }
          >
            <IconButton
              component={Link}
              to={`/manageUser/${params.row.o_USUARIO}`}
            >
              <BsPen size={20} />
            </IconButton>
          </Tooltip>}

          {( params.row.fH_BAJA == null  &&  params.row.c_ROL_USUARIO != 'OSBA_PERSONA_ADMIN' && params.row.t_PERFIL == 'Interno') &&
             (
              <Tooltip
              title={
                <Typography
                  style={{
                    color: "white",
                    fontSize: "12px",
                    fontWeight: "300",
                  }}
                >
                  Dar de baja
                </Typography>
              }
            >
              <IconButton
                onClick={()=>{
                  setDataToModify({
                    o_USUARIO: params.row.o_USUARIO,
                    n_DOCUMENTO: params.row.n_DOCUMENTO,
                    t_PERFIL: params.row.t_PERFIL,
                  });
                  setIsOpenDelete(true);
                }}
              >
                <BsFillTrashFill size={20} />
              </IconButton>
            </Tooltip>
             )
             }

          { ( params.row.fH_BAJA == null  &&  params.row.t_PERFIL != 'Interno' ) && (
          <Tooltip
              title={
                <Typography
                  style={{
                    color: "white",
                    fontSize: "12px",
                    fontWeight: "300",
                  }}
                >
                  Dar de baja
                </Typography>
              }
            >
              <IconButton
                onClick={()=>{
                  setDataToModify({
                    o_USUARIO: params.row.o_USUARIO,
                    n_DOCUMENTO: params.row.n_DOCUMENTO,
                    t_PERFIL: params.row.t_PERFIL,
                  });
                  setIsOpenDelete(true);
                }}
              >
                <BsFillTrashFill size={20} />
              </IconButton>
            </Tooltip>
          )}

          {params.row.fH_BAJA != null && (
            <Tooltip
              title={
                <Typography
                  style={{
                    color: "white",
                    fontSize: "12px",
                    fontWeight: "300",
                  }}
                >
                  Dar de Alta
                </Typography>
              }
            >
              <IconButton
                onClick={() => {
                  setDataToModify({
                    o_USUARIO: params.row.o_USUARIO,
                    n_DOCUMENTO: params.row.n_DOCUMENTO,
                    t_PERFIL: params.row.t_PERFIL,
                  });
                  setIsOpenCreate(true);
                }}
              >
                <BsCheckCircle size={20} />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
        )
      },
    },
  ];

  return (
    <>
      <div style={{ minHeight: '100%', width: "100%" }}>
        <DataGrid
          getRowHeight={() => 'auto'}
          autoHeight={true}
          rows={row}
          getRowId={(row) => row?.o_USUARIO + row?.n_DOCUMENTO}
          columns={columnsGrid2}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          disableSelectionOnClick
          localeText={{
            footerRowSelected: CustomPagination,
            noRowsLabel: "Sin datos",
            noResultsOverlayLabel: "Sin resultados",
          }}
          components={{
            Footer: CustomFooter,
          }}
          paginationMode="server"
          paginationModel={paginacion}
          onPaginationModelChange={setPaginacion}
          rowCount={cantidadTotal2}
        />
      </div>
      
      {loading == "pending" ? (
        <LoadingSpinner />
      ) : (
        <></>
      )}

      {/* DAR DE ALTA USUARIO */}
      <DialogConfirm
        isOpen={isOpenCreate}
        handleClose={() => {
          setIsOpenCreate(false);
        }}
        dataToModify={dataToModify}
        action={() => {
          dispatch(CreateUser(dataToModify)).then((e) => {
            dispatch(SetInformation(
              {
                title:'Operación realizada exitosamente',
                description: e.payload
              }
            ));
            setPaginacion({
              page:0,
              pageSize: 5
            })
            const emptyFilter = {
              pageNumber: 0,
              pageSize: 5,
            };
            dispatch(GetUsers(emptyFilter)).then((e) => {
              setRow(e.payload.listaResultado);
            });
          });
            
          setIsOpenCreate(false);
          
        }}
      />

      {/* DAR DE BAJA USUARIO */}
      <DialogDeleteUser
        isOpen={isOpenDelete}
        handleClose={() => { setIsOpenDelete(false) }}
        dataToModify={dataToModify}
        action={setRow}
        setPaginacion={setPaginacion}

      />
    </>
  );
}

export default UsersTable;
