import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const fileAPI = createApi({
  reducerPath: "fileApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_OBSBA_API_URL}/files`,
    prepareHeaders: (headers, {getState}) =>{
      const token = localStorage.getItem("token_app")
          if(token)
            headers.set('Authorization', `Bearer ${token}`)
          return headers
    }
  }),
  endpoints: (builder) => ({
    getFiles: builder.query({
      query: (args) => {
        const { yearid, idfolder, pageNumber = 1, pageSize = 5 } = args;
        const token = localStorage.getItem("token_app");
        return { url: `/getFiles/${yearid}/${idfolder}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
          headers:{"Authorization": `Bearer ${token}` }
         };
      },
      providesTags:["files.get"]
    }),
    postCreateFile: builder.mutation({
      query: (args) => {
        const data = args;
        const formData = new FormData();
        formData.append("AnioCarpeta", Number(data.data.Anio));
        formData.append("CarpetaId", Number(data.data.CarpetaId));
        formData.append("Titulo", data.data.fileTitle);
        formData.append("Descripcion", data.data.fileDescription);
        formData.append("TipoDocumento", data.data.tipoDocumento);
        formData.append("Usuario", data.data.Usuario);
        formData.append("Visibilidad", data.data.visibilidad);
        formData.append("UsuarioId", data.data.UsuarioId);
        for (const file of data.data.file) {
          formData.append("FormFile", file);
        }
        return {          
          method: "POST",
          body: formData,
          responseHandler: (response) => response.text(),
        };
      },
      invalidatesTags: ["files.get"],
    }),
    deleteFile: builder.mutation({
      query: (args) =>{
        return({
          method: "DELETE",
          url: `${args}`
        })
      },
      invalidatesTags: ["files.get"]
    })
  }),
});

export const { useGetFilesQuery, usePostCreateFileMutation, useDeleteFileMutation } = fileAPI;
