import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  TextField,
  Grid,
  Typography,
  Box,
  Hidden,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import styles from "../styles";

import Colors from "../../../theme/colors";
import { validateSendedToken } from "../../../redux/slices/passwordSlice";
import Copyright from "../../../components/Copyrigth";


const Logo = require("./../../../static/img_ban.png");
const obsBALogo = require("./../../../static/color-horizontal.png")

const RecoverPassword = () => {
  const classes = styles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { handleSubmit, register } = useForm();
  const { id } = useParams();
  const [otp, setOtp] = useState(new Array(4).fill(""));
  const [leyenda, setLeyenda] = useState("");

  const handleChange = (element, index) => {
    setLeyenda("");
    if (isNaN(element.value)) return false;

    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const onSubmit = (data) => {
    if (leyenda == "") {
      const d = { username: id, token: data.token };
      dispatch(validateSendedToken(d)).then((r) => {
        if (r.type == "/recovery/validateToken/fulfilled")
          navigate("/newPassword/" + id);
        else {
          setLeyenda("Error en la validación del OTP");
        }
      });
    }
  };

  return (
    <Grid container component="main" className={classes.root}>
      {/* <CssBaseline/> */}
      <Grid item xs={12} md={6}>
        <Grid container justifyContent='center'>
          <Grid container justifyContent='center' alignItems='center'>
            <Grid item>
              <Box>
                <img src={obsBALogo} alt="obsBA Logo" className={classes.logo} />
              </Box>
              <Typography className={classes.loginSubtitle}>
                Portal de Gestión de Carpeta Digital
              </Typography>
              <Box display='flex' justifyContent='center'>
                <Typography component="h1" variant="h4" style={{ marginTop: '15px', marginBottom: '15px' }}>
                Recuperación de contraseña
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <div className={classes.paper}>
          <form
            className={classes.form}
            style={{ width: "30%" }}
            onSubmit={handleSubmit(onSubmit)}
          >

            <Typography
              className={classes.label}
              style={{ textAlign: "center" }}
            >
              A continuación ingresá el código de recuperación que te enviamos a
              tu correo electrónico
            </Typography>
            <Grid
              container
              spacing={2}
              direction="row"
              style={{ marginTop: "10px" }}
            >
              <div
                style={{
                  width: "1000%",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                  marginBottom: "15px",
                }}
              >
                <div
                  style={{
                    width: "80%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {otp.map((data, index) => {
                    return (
                      <input
                        className={classes.otpField}
                        type="text"
                        maxLength="1"
                        key={index}
                        value={data}
                        onChange={(e) => handleChange(e.target, index)}
                        onFocus={(e) => e.target.select()}
                        autoFocus={index === 0}
                        required
                      ></input>
                    );
                  })}
                </div>
                <TextField
                  name="token"
                  id="token"
                  style={{ display: "none" }}
                  value={otp.join().replaceAll(",", "")}
                  inputRef={register}
                />
              </div>
              <span
                style={{
                  color: Colors.red,
                  fontSize: "0.75rem",
                  fontWeight: "400",
                  marginLeft: "30px",
                }}
              >
                {leyenda}
              </span>

              <Button
                type="submit"
                variant="outlined"
                color="primary"
                className={classes.button}
                fullWidth
              >
                Continuar
              </Button>
            </Grid>
            <Box mt={5} style={{ marginTop: "40px" }}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
      <Hidden smDown>
        <Grid xs={false} md={6}>
          <img style={{ width: '100%' }} src={Logo} />
        </Grid>
      </Hidden>
    </Grid>
  );
}

export default RecoverPassword;