import { Button, Divider, Grid, IconButton, InputAdornment, Switch, TextField } from '@material-ui/core';
import { Visibility, VisibilityOff } from "@material-ui/icons";
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import DialogDeleteProfile from '../../../components/DialogDeleteProfile';
import { errorMessages } from '../../../helpers/Errors';
import { CuitMask } from '../../../helpers/maskedImputs';
import { ValidateCuit, ValidateEmail } from '../../../helpers/validators';
import { SetInformation } from '../../../redux/slices/modalSlice';
import { documentTypes, updateUserData } from '../../../redux/slices/profileSlice';
import { selectUser } from "../../../redux/slices/signInSlice";
import Colors from '../../../theme/colors';
import Styles from '../styles';
import { unwrapResult } from '@reduxjs/toolkit';
import { breadcrumbs } from "../../../settings";
import { setBreadcrumbs } from "../../../redux/slices/generalSlice";
const MyProfile = () => {
  const style = Styles();
  const user = useSelector(selectUser).user;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  dispatch(setBreadcrumbs(breadcrumbs.myProfile));
  const { handleSubmit, register } = useForm({
    defaultValues: {
      fullName: user.xC_USER,
      profileType: user.t_PERFIL,
      userName: user.c_USER,
      tipoDocumento: user.t_DOCUMENTO,
      nDocumento: user.n_DOCUMENTO.toString(),
      nTelefono: user.n_TELEFONO,
      address: user.xL_DOMICILIO,
      email: user.xL_EMAIL
    }
  });

  const [checked, setChecked] = useState(false);
  const [nombreUsuario, setNombreUsuario] = useState('');
  const [nDocumento, setNDocumento] = useState('');
  const [tipoUsuario, setTipoUsuario] = useState([]);
  const [tipoDocumento, setTipoDocumento] = useState({});
  const [labelDocumento, setLabelDocumento] = useState('');
  const [perfiles, setPerfiles] = useState([]);
  const [error, setError] = useState(false);
  const [leyenda, setLeyenda] = useState("");
  const [errorCUIT, setErrorCUIT] = useState(false);
  const [leyendaCUIT, setLeyendaCUIT] = useState("");
  const [errorEmail, setErrorEmail] = useState(false);
  const [leyendaEmail, setLeyendaEmail] = useState("");
  const [values, setValues] = React.useState({ password: "", showPassword: false });
  const [modalDelete, setModelDelete] = useState(false);
  const [dataToModify, setDataToModify] = useState({});

  const osbaPersonaAdmin = user.c_ROL_USUARIO != null ? true : false;

  useEffect(() => {
    if (user != null && nombreUsuario !== user.c_USER) {
      setNombreUsuario(user.c_USER);

      setNDocumento(user.n_DOCUMENTO.toString() || '');
      setTipoUsuario(user.t_PERFIL);



      let _tipoDocumento = {
        label: user.t_DOCUMENTO.trim(),
        value: user.t_DOCUMENTO.trim().slice(-1)
      };
      setTipoDocumento(_tipoDocumento);
      setLabelDocumento(user.t_DOCUMENTO.trim());
      if (osbaPersonaAdmin)
        setPerfiles(['Administrador']);
      else
        user.t_PERFILES ? setPerfiles(user.t_PERFILES) : setPerfiles([user.t_PERFIL]);
    }

  }, [])

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const ValidadorGeneral = (data) => {
    let errors = [];

    if (labelDocumento == null)
      errors.push(errorMessages.cuit.valid);
     
    if (data.fullName.trim().length === 0 || /^\s*$/.test(data.fullName)) {
      errors.push(errorMessages.nombreApellido.empty);
    } else if (data.fullName.length > 90) {
      errors.push(errorMessages.nombreApellido.maxLength);
    }

    if (!/^\d+$/.test(data.nTelefono)) {
      errors.push(errorMessages.telefono.invalidFormat);
    } else if (data.nTelefono.toString().length > 20) {
      errors.push(errorMessages.telefono.maxLength);
    }

    if (data.address.trim().length === 0 || /^\s*$/.test(data.address)) {
      errors.push(errorMessages.domicilio.empty);
    } else if (data.address.length > 90) {
      errors.push(errorMessages.domicilio.maxLength);
    }
    if (!ValidateEmail(data.email)) {
      errors.push(errorMessages.email.valid);
    }

    if (checked) {
      if (data.newPassword !== data.repeatPassword) {
        setError(true);
        setLeyenda("Las contraseñas no coinciden");
        return true;
      } else {
        setError(false);
        setLeyenda("");
      }
    } else {
      setError(false);
      setLeyenda("");
    }

    return errors.length > 0 ? ModelErrorsList(errors) : false;
  }

  const ModelErrorsList = (errors) => {
    dispatch(SetInformation({
      title: 'Error',
      description: (
        <div>
          <ul>
            {errors && errors.map((error, i) => (
              <li key={Number(i)}>{error}</li>
            ))}
          </ul>
        </div>
      )
    }));
    return true
  }
  const onSubmit = (data) => {
    let d = {};
    d.ousuario = user.o_USUARIO;
    d.userName = data.fullName;
    d.email = data.email;
    d.phone = data.nTelefono;
    d.street = data.address;
    d.password = data.newPassword;
    d.profile = tipoUsuario;
    d.tdoc = labelDocumento;
    d.cuit = parseInt(nDocumento.replaceAll("-", ""));
    if (ValidadorGeneral(data))
      return;

    dispatch(updateUserData(d))
      .then(unwrapResult)
      .then((resp) => {
        dispatch(SetInformation(
          {
            title: 'Datos de usuario actualizados',
            description: "Operación exitosa"
          }
        ));
      }).catch(() => {
        dispatch(SetInformation(
          {
            title: 'Datos de usuario no actualizados',
            description: "Vuelva a intentarlo más tarde"
          }));
      });
  };

  function onVolver() {
    navigate(-1);
  };

  const onSwitchChange = (event) => {
    setChecked(event.target.checked);
  };

  const onCuitChange = (e) => {
    if (e.target.value && !ValidateCuit(e.target.value)) {
      setErrorCUIT(true);
      setLeyendaCUIT("CUIT/CUIL inválido");
    } else {
      setErrorCUIT(false);
      setLeyendaCUIT("");
    }
    setNDocumento(e.target.value);
  };

  const onEmailChange = (e) => {
    if (!ValidateEmail(e.target.value)) {
      setErrorEmail(true);
      setLeyendaEmail("Ingrese un email válido");
    } else {
      setErrorEmail(false);
      setLeyendaEmail("");
    }
  };

  const onChangeUserType = (typesUser) => {
    let noPerfiles = typesUser.map(e => e.label);
    if (noPerfiles.length < 3) {
      setModelDelete(true);
      setDataToModify({
        CUIT: user.n_DOCUMENTO,
        PERFIL: perfiles.filter(e => !noPerfiles.includes(e))[0]
      });
    }

  };

  const onChangeDocumentType = (documentType) => {
   
    if (documentType == null)
      setLabelDocumento(null)
    else
      setLabelDocumento(documentType.label);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className={style.form} style={{ display: 'flex', justifyContent: 'center', minWidth: '600px', width: '100%', padding: 0 }}>
        <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'center', width: '600px', minWidth: '600px' }}>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <label style={{ fontSize: '20px' }}>Mi Perfil</label>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              id="fullName"
              label="Apellido / Nombre / Razon Social"
              name="fullName"
              autoComplete="fullName"
              fullWidth
              className={style.textField}
              inputProps={{
                style: { fontSize: 14 },
                onBlur: (event) => {
                  event.target.value = event.target.value.trim();
                },
              }}
              InputLabelProps={{ style: { fontSize: 14 } }}
              inputRef={register}
              {...register('fullName')}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              multiple
              id="tags-outlined"
              // options={userTypes}
              // getOptionLabel={(option) => option.label}
              freeSolo
              options={[]}
              getOptionLabel={(option) => option.label}
              value={perfiles != null ? perfiles.map((item, index) => ({ label: item, value: index })) : [{}]}
              //defaultValue={myTypes}
              filterSelectedOptions={false}
              disabled={user.c_ROL_USUARIO == "OSBA_PERSONA_ADMIN" || user.c_ROL_USUARIO == "OSBA_PERSONA_RRHH" ? true : false}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tipo de perfil"
                  name="profileType"
                  id='profileType'
                  // variant="standard"
                  fullWidth
                  inputRef={register}
                  {...register('profileType')}


                // InputProps={{
                //     endAdornment: (
                //       <InputAdornment position="start"></InputAdornment>
                //     ),
                //   }}
                />
              )}

              onChange={(e, value) => onChangeUserType(value)}
              style={{ marginBottom: '0', marginTop: '0' }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              disabled
              variant="outlined"
              id="userName"
              label="Nombre de Usuario"
              name="userName"
              autoComplete="userName"
              fullWidth
              className={style.textField}
              inputprops={{ style: { fontSize: 14 } }}
              InputLabelProps={{ style: { fontSize: 14 } }}
              inputRef={register}
              {...register('userName')}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              id="tags-standard"
              options={documentTypes}
              getOptionLabel={(option) => option.label}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tipo Documento"
                  name="tipoDocumento"
                  id='tipoDocumento'
                  variant="outlined"
                  fullWidth
                //inputRef={register}
                />
              )}
              style={{ marginBottom: '0', marginTop: '0' }}
              defaultValue={{
                label: user.t_DOCUMENTO.trim(),
                value: user.t_DOCUMENTO.trim().slice(-1)
              }}
              onChange={(e, value) => onChangeDocumentType(value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              id="nDocumento"
              label="CUIT/CUIL"
              name="nDocumento"
              disabled
              autoComplete="nDocumento"
              fullWidth
              className={style.textField}
              value={nDocumento}
              onChange={onCuitChange}
              error={errorCUIT}
              helperText={leyendaCUIT}
              InputLabelProps={{
                shrink: true,
                style: { fontSize: 14 }
              }
              }
              InputProps={{
                inputComponent: CuitMask,
                style: { fontSize: 14 }
              }}
            />

          </Grid>

          <Grid item xs={6}>
            <TextField
              variant="outlined"
              id="nTelefono"
              label="Nº Teléfono"
              name="nTelefono"
              autoComplete="nTelefono"
              type="tel"
              fullWidth
              className={style.textField}
              inputProps={{
                style: { fontSize: 14 },
                maxLength: 20,
              }}
              InputLabelProps={{ style: { fontSize: 14 } }}
              inputRef={register}
              {...register('nTelefono')}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              id="address"
              label="Domicilio"
              name="address"
              autoComplete="address"
              fullWidth
              className={style.textField}
              inputProps={{
                style: { fontSize: 14 },
                onBlur: (event) => {
                  event.target.value = event.target.value.trim();
                },
              }}
              InputLabelProps={{ style: { fontSize: 14 } }}
              inputRef={register}
              {...register('address')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              fullWidth
              className={style.textField}
              inputprops={{ style: { fontSize: 14 } }}
              InputLabelProps={{ style: { fontSize: 14 } }}
              inputRef={register}
              {...register('email')}
              onChange={onEmailChange}
              error={errorEmail}
              helperText={leyendaEmail}
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: 15, marginBottom: 15 }}>
            <Divider />
          </Grid>
          <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>

          </Grid>
          <Grid item xs={8} style={{ display: 'flex', justifyContent: 'center' }}>
            <label style={{ fontSize: '20px' }}>Cambio de Contraseña</label>
          </Grid>
          <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
            <Switch
              checked={checked}
              onChange={onSwitchChange}
              inputProps={{ 'aria-label': 'controlled' }}
              color='primary'
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: 10 }}>
            <TextField
              variant="outlined"
              margin="normal"
              name="newPassword"
              label="Nueva contraseña"
              type={values.showPassword ? "text" : "password"}
              id="newPassword"
              autoComplete="current-password"
              fullWidth
              required
              disabled={!checked}
              inputRef={register}
              onChange={handlePasswordChange("newPassword")}
              error={error}
              helperText={leyenda}
              inputprops={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              name="repeatPassword"
              label="Repita contraseña"
              type={values.showPassword ? "text" : "password"}
              id="repeatPassword"
              autoComplete="current-password"
              fullWidth
              required
              disabled={!checked}
              inputRef={register}
              style={{ marginTop: '-10px' }}
              onChange={handlePasswordChange("repeatPassword")}
              error={error}
              helperText={leyenda}
              inputprops={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: 15, marginBottom: 15 }}>
            <Divider />
          </Grid>
          <Grid item xs={6} style={{ width: '100%', textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
            <Button
              size="large"
              type="button"
              variant="outlined"
              onClick={onVolver}
              style={{
                margin: 0,
                backgroundColor: Colors.white,
                border: '2px solid',
                borderColor: Colors.blue,
                color: Colors.blue,
                maxHeight: '50px',
                minHeight: '50px'
              }}
              fullWidth>
              Volver
            </Button>
          </Grid>
          <Grid item xs={6} style={{ width: '100%', textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
            <Button
              size="large"
              type="submit"
              variant="outlined"
              style={{
                margin: 0,
                backgroundColor: Colors.blue,
                border: '2px solid',
                borderColor: Colors.blue,
                color: Colors.white,
                maxHeight: '50px',
                minHeight: '50px'
              }}
              fullWidth>
              Guardar
            </Button>
          </Grid>
        </Grid>
      </form>

      <DialogDeleteProfile perfiles={perfiles} setPerfiles={setPerfiles} isOpen={modalDelete} dataToModify={dataToModify} handleClose={() => setModelDelete(false)} />

    </>
  );
}

export default MyProfile;