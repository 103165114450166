import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../api";

import { successUserUpdate } from "../slices/signInSlice";

// -----------------------------------------------------------
// ----------------------- DATASETS --------------------------
// -----------------------------------------------------------

export const profileTypeOptions = [
  { label: 'Interno', value: 'I' },
  { label: 'Empleado', value: 'E' },
  { label: 'Afiliado', value: 'A' },
  { label: 'Prestador', value: 'P' }
];

export const documentTypes = [
  //{ label: 'DNI', value: 'I' },
  { label: 'CUIL', value: 'L' },
  { label: 'CUIT', value: 'T' }
];

// -----------------------------------------------------------
// ------------------------ PROFILE --------------------------
// -----------------------------------------------------------

export const updateUserData = createAsyncThunk("/userData", async (data, dispatch) => {
  try {
    const response = await api.put("/Usuario_Portal/ChangeDataUser", {
      O_USUARIO: data.ousuario,
      T_DOCUMENTO: data.tdoc,
      N_DOCUMENTO: data.cuit,
      T_PERFIL: data.profile,
      T_PERFILES: data.profiles,
      xC_USER: data.userName,
      xL_EMAIL: data.email,
      xL_DOMICILIO: data.street,
      n_TELEFONO: data.phone,
      c_PASSWORD: data.password
    });
    dispatch.dispatch(successUserUpdate(response));
    return response.data;
  } catch (e) {
    return dispatch.rejectWithValue(e)
  }
});

// -----------------------------------------------------------
// ------------------------- SLICE ---------------------------
// -----------------------------------------------------------

const initialState = {
  loading: false,
  profile: null,
  exist: false,
  error: null,
  success: false,
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    updateUserDataSuccess: (state, action) => {
      state.register = action.payload.data;
    },
  },
  extraReducers: {
    [updateUserData.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateUserData.fulfilled]: (state, action) => {
      state.profile = action.payload;
      state.loading = false;
      state.success = true;
      state.error = null;
    },
    [updateUserData.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload.message;
    }
  }
});

export const { updateUserDataSuccess } = profileSlice.actions;

export const selectProfile = (state) => state.profile;

export default profileSlice.reducer;
