import React from "react";
import { Link} from "react-router-dom";

import {
  Grid,
  Box,
  Typography,
  Button,
  Hidden,
} from "@material-ui/core";


import styles from "../styles";

const obsBALogo = require("../../../static/color-horizontal.png");


const RegisterConfirmation = () => {
    const classes = styles();
  
    const leyenda = "Revise su bandeja de correo electrónico para confirmar el registro";
  
    return (
      <Grid container className={classes.root}>
        <Grid
          container
          item
          xs={12}
          md={6}
          elevation={6}
          justifyContent="center"
          alignContent="center"
        >
          <Grid container justifyContent="center" alignItems="center">
            <Grid item>
              <Box>
                <img src={obsBALogo} alt="obsBA Logo" className={classes.logo} />
              </Box>
              <Typography className={classes.loginSubtitle}>
                Portal de Gestión de Carpeta Digital
              </Typography>
              <Box display="flex" justifyContent="center">
                <Typography
                  component="h1"
                  variant="h4"
                  style={{ marginTop: "15px", marginBottom: "15px" }}
                >
                  Registro
                </Typography>
              </Box>
              <div className={classes.paper}>
              <form className={classes.form} noValidate style={{ width: "30%" }}>
                <Typography
                  className={classes.label}
                  style={{ textAlign: "center" }}
                >
                  {leyenda}
                </Typography>
                <Typography
                  className={classes.label}
                  style={{
                    textAlign: "center",
                    marginTop: "15px",
                    marginBottom: "15px",
                  }}
                >
                  En caso de verificar un ingreso de correo erróneo, por favor
                  registrese nuevamente
                </Typography>
  
                <Link to="/" style={{ width: "100%", textDecoration: "none" }}>
                  <Button
                    type="submit"
                    variant="outlined"
                    color="primary"
                    className={classes.button}
                    fullWidth
                  >
                    Volver
                  </Button>
                </Link>
  
                <Grid
                  container
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginTop: "5px",
                  }}
                >
                  <Grid item>
                    <Typography className={classes.label}>
                      ¿Ya tenés cuenta?
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Link
                      to="/"
                      variant="body2"
                      className={classes.link}
                      style={{
                        textDecoration: "none",
                        fontWeight: "bold",
                        marginLeft: "5px",
                      }}
                    >
                      Iniciar sesión
                    </Link>
                  </Grid>
                </Grid>
              </form>
              </div>
              
            </Grid>
          </Grid>
        </Grid>
        <Hidden smDown>
          <Grid md={6} className={classes.image}></Grid>
        </Hidden>
      </Grid>
    );
}

export default RegisterConfirmation;