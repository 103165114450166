import { Button, Checkbox, Divider, FormControlLabel, FormGroup, Grid, TextField, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { usePostCreateFileMutation } from "../../../api/Files";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { docTypesBDescriptor } from "../../../redux/slices/dashboardSlice";
import { SetInformation } from "../../../redux/slices/modalSlice";
import { selectUser } from "../../../redux/slices/signInSlice";
import Colors from "../../../theme/colors";
import Styles from "../styles";
import PropTypes from 'prop-types';

import { folderAPI } from "../../../api/Folders";

const CreateFile = ({ yearId, folderId }) => {
  const [createFile, { isLoading }] = usePostCreateFileMutation();

  const style = Styles();

  const dispatch = useDispatch();

  const user = useSelector(selectUser).user;

  const fileTypes = useSelector(docTypesBDescriptor).data;
  const internalOnly = user.t_PERFIL.trim() === "Interno" || (user.t_PERFILES != null && user.t_PERFILES.length > 0 && user.t_PERFILES.some((perfil) => perfil.trim() == "Interno")) ? true : false;
  const visibilidadInterno = internalOnly ? style.visibility_internal : style.visibility_nointernal;
  const { handleSubmit, register, reset } = useForm({
    mode: 'onChange'
  });
  const [file, setFile] = useState(null);
  const [leyendaFile, setLeyendaFile] = useState("");
  const [fileTitle, setFileTitle] = useState("Subir Archivo");
  const [visibilidadDocumento, setVisibilidadDocumento] = useState(!internalOnly);
  const validExtensions = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/pdf'];

  function isValidFile(file) {
    return validExtensions.includes(file.type);
  }

  function onUploadFile(e) {
    const uploadedFiles = Array.from(e.target.files);
    const invalidFiles = uploadedFiles.filter(file => !/^[a-zA-Z0-9\s()\-_]*\.[a-zA-Z0-9\s()\-_]*$/.test(file.name));

    if (invalidFiles.length > 0) {
      dispatch(SetInformation(
        {
          title: 'Error',
          description: "Lo siento, la entrada que proporcionaste contiene caracteres no permitidos. Por favor, asegúrate de que el nombre de tu archivo solo incluya letras del alfabeto (mayúsculas y minúsculas), números, espacios, paréntesis, guiones, guiones bajos y puntos. Los caracteres especiales como la 'ñ' no son admitidos en este contexto. Por favor, revisa tu entrada y elimina cualquier caracter no permitido. ¡Gracias!"
        }
      ));
    } else {
      setLeyendaFile("");
      setFile(uploadedFiles);
      const allowedSize =
        uploadedFiles.reduce((acc, file) => acc + file.size, 0) >
        25 * 1024 * 1024;
      const validFiles = uploadedFiles.filter(isValidFile);
      const sizeInMb = (
        uploadedFiles.reduce((acc, file) => acc + file.size, 0) /
        (1024 * 1024)
      ).toFixed(2);

      
      const leyendaFile = ValidateFiles(uploadedFiles,allowedSize,validFiles);
      setLeyendaFile(leyendaFile);
      setFileTitle(uploadedFiles.length ? `${sizeInMb} MB adjuntos` : "Adjuntar archivos");
    }
  }

  const ValidateFiles = (uploadedFiles, allowedSize,validFiles)=>{
    if (uploadedFiles.length > 10 || allowedSize)
      return "El máximo permitido son 10 archivos o 25 MB combinados";
    if (validFiles.length !== uploadedFiles.length)
      return "Extensiones compatibles: .pdf, .xlsx, .xls"
    return "";
  }
  const onSubmit = async (data) => {

    if (/^\s*$/.test(data.fileTitle)) {
      dispatch(SetInformation(
        {
          title: 'Error',
          description: 'No se aceptan solo espacios en blanco'
        }
      ));
      return
    }
    

    if (file == null || leyendaFile != "") return;
    data.Usuario = user.c_USER;
    data.file = file;
    data.UsuarioId = user.o_USUARIO;
    data.CarpetaId = folderId;
    data.Anio = yearId;
    if(!internalOnly){
      data.visibilidad = "P";
    }else{
      data.visibilidad = visibilidadDocumento ? "P" : "R";
    }
    data.tipoDocumento = fileTypes.filter(opt => opt.label == data.fileType)[0].value

    await createFile({ data }).unwrap().then((e) => {
      dispatch(folderAPI.util.invalidateTags(['folder.get']))
      dispatch(SetInformation(
        {
          title: 'Archivo subido correctamente',
          description: 'Estimado usuario, le informamos que los documentos se han adjuntado con éxito. Por favor, no se olvide de presionar el botón "Enviar". ¡Gracias por su colaboración!'
        }
      ));
    }).finally(() => {
      onClear();
    });
  }

  const setBorderColor = () => {
    if (file == null)
      return Colors.blue; 
    return leyendaFile != "" ? Colors.red : Colors.green
  } 
  const tipoDocumento = useRef(null);

  function onClear() {
    reset();
    const t = tipoDocumento.current.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (t) t.click();
    setFile(null);
    setFileTitle("Subir Archivo");
    setVisibilidadDocumento(false);
  }
  return (
    <>
      {isLoading && <LoadingSpinner />}
      <form
        className={style.form}
        style={{
          display: "flex",
          justifyContent: "center",
          minWidth: "600px",
          width: "100%",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid
          container
          spacing={2}
          style={{
            display: "flex",
            justifyContent: "center",
            width: "600px",
            minWidth: "600px",
          }}
        >
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Typography style={{ fontSize: "20px" }}>Nuevo Documento</Typography>
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              options={fileTypes}
              getOptionLabel={(option) => option.label}
              getOptionSelected={(option, value) =>
                option.value === value.value
              }
              fullWidth
              autoFocus
              ref={tipoDocumento}
              className={style.textField}
              renderInput={(param) => (
                <TextField
                  {...param}
                  id="fileType"
                  label="Tipo de documento"
                  name="fileType"
                  placeholder="Tipo de documento"
                  variant="outlined"
                  required
                  inputRef={register}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <FormGroup className={visibilidadInterno}>
              <FormControlLabel
                style={{ marginTop: "7px" }}
                control={
                  <Checkbox
                    style={{ color: Colors.blue }}
                    checked={visibilidadDocumento}
                    onChange={(e) =>
                      setVisibilidadDocumento(e.target.checked)
                    }
                  />
                }
                label={
                  <Typography style={{ fontSize: 14 }}>Visible</Typography>
                }
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              id="fileTitle"
              label="Titulo"
              name="fileTitle"
              autoComplete="fileTitle"
              fullWidth
              required
              inputRef={register}
              className={style.textField}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              id="fileDescription"
              label="Observaciones"
              name="fileDescription"
              autoComplete="fileDescription"
              fullWidth
              multiline
              required
              inputRef={register}
              className={style.textField}
            />
          </Grid>
          <Grid item xs={12}>
            <label htmlFor="upload-file">
              <TextField
                id="upload-file"
                name="upload-file"
                type="file"
                variant="outlined"
                style={{ display: "none" }}
                inputProps={{
                  accept:
                    "application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                  multiple: true,
                }}
                inputRef={register}
                onChange={onUploadFile}
              >
                {file}
              </TextField>
              <Button
                size="large"
                component="span"
                variant="outlined"
                color="primary"
                style={{
                  margin: 0,
                  backgroundColor: Colors.white,
                  border: "2px solid",
                  borderColor: setBorderColor(),
                  color:
                    file != null && leyendaFile != ""
                      ? Colors.red
                      : Colors.blue,
                  maxHeight: "80px",
                  minHeight: "80px",
                }}
                fullWidth
              >
                {fileTitle}
              </Button>
              <span
                style={{
                  color: Colors.red,
                  fontSize: "0.75rem",
                  fontWeight: "400",
                  marginLeft: "12px",
                }}
              >
                {leyendaFile}
              </span>
            </label>
          </Grid>
          <Grid item xs={6}>
            <Button
              size="large"
              type="button"
              variant="outlined"
              color="primary"
              onClick={() => onClear()}
              style={{
                margin: 0,
                backgroundColor: Colors.white,
                border: "2px solid",
                borderColor: Colors.blue,
                color: Colors.blue,
                maxHeight: "50px",
                minHeight: "50px",
              }}
              fullWidth
            >
              Limpiar
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              size="large"
              type="submit"
              variant="outlined"
              color="primary"
              disabled={file == null || leyendaFile !== ""}
              style={{
                margin: 0,
                backgroundColor: Colors.blue,
                border: "2px solid",
                borderColor: Colors.blue,
                color: Colors.white,
                maxHeight: "50px",
                minHeight: "50px",
              }}
              fullWidth
            >
              Adjuntar
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Divider style={{ marginBottom: "15px" }} />
          </Grid>
        </Grid>
      </form>
    </>
  );
}
CreateFile.propTypes = {
  yearId: PropTypes.string.isRequired,
  folderId: PropTypes.string.isRequired,
};
export default CreateFile;
