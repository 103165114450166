import {makeStyles} from "@material-ui/styles";
import Colors from "../../theme/colors";
import logoIcon from "../../static/logo_bco_obsba.png";

export default makeStyles((theme) => ({
    paper: {
        width: '95%',
        minHeight: '800px',
    },  
    root: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '0px',
        minWidth: '1070px'
    },
    fullbar: {
        width: '100%',
        minHeight: '110px',
        marginBottom: 10
    },
    bar: {
        width: '100%', 
        height: '70px', 
        backgroundColor: Colors.blue,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    barContainer: {
        width: '95%',
    },
    logo: {
        backgroundImage: "url(" + logoIcon + ")",
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'left',
        width: '100%',
        height: '40px',
        zIndex: "101",
    },
    barContent: {
        width: '100%',
        height: '40px',
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
    },
    barTitle: {
        fontSize: '20px',
        fontFamily: 'Sans-Serif',
        color: Colors.white,
        fontWeight: '400',
    },
    link: {
        color: Colors.white,
        fontWeight: '400',
        fontSize: '14px',
        marginLeft: '10px',
        textDecoration: 'none', 
    },



    barRoute: {
        width: '100%', 
        height: '40px',
        minHeight: 50,
        backgroundColor: Colors.white,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: `0 4px 2px -2px ${Colors.grey}`,
        margin: '0 !important',
        padding: '0 !important'
    },
    barRouteContainer: {
        width: '95%',
    },
    linkRoute: {
        color: Colors.black,
        fontWeight: '400',
        fontSize: '12px',
        textDecoration: 'none'
    },
    lineRoute: {
        color: Colors.detailBlack, 
        backgroundColor: Colors.detailBlack, 
        height: '0.5', 
        width: '100%'
    }
}));