import { colors, createTheme } from "@material-ui/core";
import shadow from "./shadow";
import typography from "./typography";
import { green, red } from "@material-ui/core/colors";
import Colors from "./colors";
const theme = createTheme({
  root:{
    "& .MuiButtonBase-root .MuiPaginationItem-root .MuiPaginationItem-sizeMedium .MuiPaginationItem-text .MuiPaginationItem-circular .MuiPaginationItem-textPrimary .MuiPaginationItem-page .css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root": {
      backgroundColor: Colors.darkGreen
    }
  },
  palette: {
    background: {
      default: colors.common.white,
      paper: colors.common.white,
    },
    primary: {
      main: "#325AA5",
      light: "#5e92f3",
      dark: "#003c8f",
      contrastText: "#fff",
    },
    secondary: {
      main: "#d81b60",
      light: "#ff5c8d",
      dark: "#a00037",
      contrastText: "#fff",
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600],
    },
    warning: {
      main: "#ffc071",
      dark: "#ffb25e",
    },
    error: {
      xLight: red[50],
      main: red[500],
      dark: red[700],
    },
    
    success: {
      xLight: green[50],
      main: green[500],
      dark: green[700],
    },
   
  },
  shadow,
  typography,
});

export default theme;