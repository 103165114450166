import React from "react";
import { Controller } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

const InputAutoComplete = (props) => {
  const {
    disabled,
    rules,
    name,
    options,
    control,
    error,
    helperText,
    defaultValue,
    ...otherProps
  } = props;

  return (
    <Controller
      rules={rules}
      control={control}
      name={name}
      render={({ field, ref, onChange, value }) => (
        <Autocomplete
          options={options}
          onChange={(_, data) => {
            if (data != null) onChange(data.label)
            else onChange(null)
          }}
          {...field}
          ref={ref}
          disabled={disabled}
          defaultValue={defaultValue? defaultValue:''}
          getOptionLabel={option => option.label}
          getOptionSelected={(option, value) => option.value === value.value}
          inputprops={{ style: { fontSize: 14 } }}
          inputlabelprops={{ style: { fontSize: 14 } }}
          renderInput={(param) =>{ return(
            <TextField  
              inputRef={ref}
             fullWidth
              variant="outlined"
              {...param}
              {...otherProps}             
            />
          )}}
        />
      )}
    />
  );
};

export default InputAutoComplete;
