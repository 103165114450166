import Colors from "../theme/colors";

export const modalStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    boxShadow: 24,
    p: 4,
    
    color: Colors.white,
    border: `2px solid ${Colors.detailBlack}`,
    backgroundColor: Colors.detailBlack,
};