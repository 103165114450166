import { Typography } from "@material-ui/core";

const Copyright = () => {
  return (
    <>
      <Typography  variant="caption" color="primary" align="center">
        {"Copyright © "} {new Date().getFullYear()}
        {" derechos reservados"}
      </Typography>
      <br></br>
      
    </>
  );
}

export default Copyright;