import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { api } from "../../api";
import { GetDataByCuil } from "./manageuserSlice";

export const userTypes = [
    { label: "Interno", value: 'I' },
    { label: "Afiliado", value: 'A' },
    { label: "Prestador", value: 'P' },
    { label: "Empleado", value: 'E' },
  ];

// -----------------------------------------------------------
// ------------------------ USERS ---------------------------
// -----------------------------------------------------------

export const GetUsers = createAsyncThunk("/usuario_portal/where", async (data, thunkAPI) => {
    try {
      const response = await api.post("/usuario_portal/where?pageNumber=" + data.pageNumber + "&pageSize=" + data.pageSize, data);   ///usuario_portal/getall"
      return response.data.content;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);


export const CreateUser = createAsyncThunk("/usuario_portal/active/", async (data, thunkAPI) => {
 
  try {
    const response = await api.get("/usuario_portal/active/"+data.o_USUARIO); 
    return response.data;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
}
);


export const DeleteUser = createAsyncThunk("/usuario_portal/active/", async (data, thunkAPI) => {
  
  try {
    const response = await api.post("/usuario_portal/deactive ", data); 
    return response.data;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
}
);

export const DeleteProfileUser = createAsyncThunk("/usuario_portal/deactive/", async (data, thunkAPI) => {
  
  try {
    const response = await api.post("/usuario_portal/delete ", data); 
    return response.data;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
}
);


// -----------------------------------------------------------
// ------------------------- SLICE ---------------------------
// -----------------------------------------------------------

const initialState = {
  loading: false,
  error: null,
  success: false,
  users: {
    data: {
      listaResultado: null,
      cantidadResultado: null,
      cantidadTotal: null
    },
    error: {},
    loading: "idle",
    success: false,
  },
  usermanage: {
    data: null,
    error: {},
    loading: "idle",
    success: false,
  },
  usersFiltered: null,
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    SetFilterData: (state, action) => {
      state.usersFiltered = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetUsers.pending, (state) => {
        state.users.loading = "pending";
      })
      .addCase(GetUsers.fulfilled, (state, action) => {
        state.users.data = action.payload;
        state.users.loading = "idle";
        state.users.success = true;
      })
      .addCase(GetUsers.rejected, (state, action) => {
        state.users.error = 'Token Expirado';
        state.users.loading = "idle";
      })
      .addCase(GetDataByCuil.pending, (state) => {
        state.usermanage.loading = "pending";
      })
      .addCase(GetDataByCuil.fulfilled, (state, action) => {
        state.usermanage.data = action.payload;
        state.usermanage.loading = "idle";
        state.usermanage.success = true;
      })
      .addCase(GetDataByCuil.rejected, (state, action) => {
        state.usermanage.error = 'Token Expirado';
        state.usermanage.loading = "idle";
      })
  }
});

export const { SetFilterData } = usersSlice.actions;

export const selectUsers = (state) => state.users;
export const selectManageUser = (state) => state.users.usermanage;

export default usersSlice.reducer;