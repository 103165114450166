import { Button, Grid, IconButton, InputAdornment, TextField } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCreateInternalUserMutation } from "../../../api/Usuarios_Portal";
import InputTextField from "../../../components/Forms/input";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { FormatCuil, ValidateCuit, ValidatePassword } from "../../../helpers/validators";
import { SetInformation } from "../../../redux/slices/modalSlice";
import styles from "../styles";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import PropTypes from 'prop-types';

const RegisterInterno = (props) => {
  const dispatch = useDispatch();
  const classes = styles();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const { handleSubmit, control, watch, formState: { errors } } = useForm({
    mode: "onChange",
    defaultValues: {
      profileType: props.profile || '',
      sigafUser: "",
      password: "",
      inputCUIT: "",
    }
  });

  const [createInternalUser, { isLoading }] = useCreateInternalUserMutation();

  const onSubmit = async (data) => {
    data.inputCUIT = data.inputCUIT.replace(/-/g, '');
    await createInternalUser(data).unwrap().then((e) => {
      navigate('/register/confirmation');
    }).catch((e) => {
      dispatch(SetInformation({
        title: 'Error',
        description: e.data
      }));
    })
  }

  const handleShowPassword = () => setShowPassword(!showPassword);

  RegisterInterno.propTypes = {
    profile: PropTypes.string,
  };

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <form
        className={classes.form}
        style={{ width: "60%" }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid
          container
          style={{ display: "flex", justifyContent: "center" }}
          spacing={2}
        >
          <Grid item xs={12} sm={12} md={6}>
            <InputTextField
              id="idInpCUser"
              required
              name="sigafUser"
              label="Usuario SIGAF"
              fullWidth
              variant="outlined"
              control={control}
              InputLabelProps={{ shrink: true }}
              rules={{
                required: "Campo obligatorio"
              }}
              error={!!errors.sigafUser}
              helperText={errors.sigafUser?.message}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="inputCUIT"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Campo obligatorio"
                },
                validate: (value) => {
                  return ValidateCuit(value) ? null : "CUIT/CUIL inválido";
                }
              }}
              render={(field) => (
                <TextField
                  required
                  label="CUIT/CUIL"
                  id="idInpCUIT"
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    maxLength: 13,
                    type: 'text',
                  }}
                  error={!!errors.inputCUIT}
                  helperText={errors.inputCUIT?.message}
                  value={FormatCuil(field.value.toString())}
                  onChange={(e) => {
                    let numericValue = e.target.value.replace(/[^\d]/g, '');
                    let formattedValue = FormatCuil(numericValue.toString());
                    field.onChange(formattedValue);
                  }}
                />
              )}
            />
          </Grid>
          <Grid item container spacing={2} direction="row">
            <Grid item xs={12} sm={12} md={6}>
              <Controller
                name="password"
                control={control}
                rules={{
                  required: 'Campo obligatorio',
                  validate: (value) => {
                    return ValidatePassword(value) ? null : "La contraseña debe ser alfanumérica y tener al menos 8 dígitos y una mayúscula";
                  }
                }}
                render={(field) => (
                  <TextField
                    required
                    label="Nueva contraseña"
                    id="idInpPassword"
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    type={showPassword ? "text" : "password"}
                    InputLabelProps={{ shrink: true }}
                    onPaste={(e) => {
                      e.preventDefault();
                    }}
                    InputProps={{
                      autoComplete: "off",
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleShowPassword}
                          // onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    error={!!errors.password}
                    helperText={errors.password?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Controller
                name="repeatPassword"
                control={control}
                rules={{
                  required: 'Campo obligatorio',
                  validate: (value) => {
                    return watch("password", "") == value ? null : "Las contraseñas no coinciden";
                  }
                }}
                render={(field) => (
                  <TextField
                    required
                    variant="outlined"
                    margin="normal"
                    label="Repita contraseña"
                    id="idInpRepeatPassword"
                    type={showPassword ? "text" : "password"}
                    fullWidth
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    InputLabelProps={{ shrink: true }}
                    onPaste={(e) => {
                      e.preventDefault();
                    }}
                    InputProps={{
                      autoComplete: "off",
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleShowPassword}
                          // onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.repeatPassword}
                    helperText={errors.repeatPassword?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button
            id="idBtnRegistrar"
            type="submit"
            variant="outlined"
            color="primary"
            className={classes.button}
            fullWidth
            required
          >
            Registrar
          </Button>
        </Grid>
      </form>
    </>

  );
}

export default RegisterInterno;