import React, { useState, useReducer } from 'react';
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';


import { ValidateCuit, ValidateEmail } from '../../../helpers/validators';

import Styles from "../styles";

import { Grid, Button, TextField, Divider, Typography } from '@material-ui/core';


import Autocomplete from '@material-ui/lab/Autocomplete';
import { unwrapResult } from '@reduxjs/toolkit';
import { updateUserData } from '../../../redux/slices/manageuserSlice';
import { SetFilterData, userTypes } from '../../../redux/slices/usersSlice';
import { documentTypes } from '../../../redux/slices/profileSlice';
import { SetInformation } from '../../../redux/slices/modalSlice';

export function ManageUsers(params) {
  const style = Styles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { handleSubmit, register } = useForm({});

  const [errorCUIT, setErrorCUIT] = useState(false);
  const [leyendaCUIT, setLeyendaCUIT] = useState("");

  const [codigoUsuario, setCodigoUsuario] = useState('');
  const [nombreCompleto, setNombrecompleto] = useState('');
  const [tipoUsuario, setTipoUsuario] = useState([]);
  const [nombreUsuario, setNombreUsuario] = useState('');
  const [tipoDoc, setTipoDoc] = useState([]);
  const [labelDocumento, setLabelDocumento] = useState('');
  const [nDocumento, setNDocumento] = useState('');
  const [nTelefono, setNTelefono] = useState('');
  const [domicilio, setDomicilio] = useState('');
  const [email, setEmail] = useState('');
  const [intentosFallidos, setIntentosFallidos] = useState('');
  const [fechaBaja, setFechaBaja] = useState([]);
  const [motivoBaja, setMotivoBaja] = useState([]);

  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
  const [ignoredValue, setIgnoredValue] = useState(0);
  const [tipoUser, setTipoUser] = useState('');
  const [tipoValue, setTipoValue] = useState('');

  const [errorEmail, setErrorEmail] = useState(false);
  const [leyendaEmail, setLeyendaEmail] = useState("");

  React.useEffect(() => {
    if (ignored === ignoredValue) {
      if (params.data != null && codigoUsuario !== params.data.o_USUARIO) {
        let _tipoUsuario = {
          label: params.data.t_PERFIL.trim(),
          value: params.data.t_PERFIL.trim().substring(0, 1)
        };
        let _tipoDocumento = {
          label: params.data.t_DOCUMENTO.trim(),
          value: params.data.t_DOCUMENTO.trim().slice(-1)
        };
        let _fechaBaja = params.data.fH_BAJA != null ? new Date(params.data.fH_BAJA).toISOString().slice(0, 10) : '';
        setCodigoUsuario(params.data.o_USUARIO);
        setNombrecompleto(params.data.xC_USER);
        setTipoUsuario(_tipoUsuario);
        setNombreUsuario(params.data.c_USER);
        setTipoDoc(_tipoDocumento);
        setNDocumento(params.data.n_DOCUMENTO);
        setNTelefono(params.data.n_TELEFONO);
        setDomicilio(params.data.xL_DOMICILIO);
        setEmail(params.data.xL_EMAIL);
        setIntentosFallidos(params.data.n_LOGIN_FALLIDO);
        setFechaBaja(_fechaBaja);
        setMotivoBaja(params.data.xL_MOTIVO_BAJA);

        setTipoUser(params.data.t_DOCUMENTO.trim());
        setTipoValue(params.data.t_DOCUMENTO.trim().slice(-1));
        setLabelDocumento(params.data.t_DOCUMENTO.trim());

        setIgnoredValue(ignored);
      }
    }
  }, [ignored]);

  const onSubmit = () => {

    if (errorEmail)
      return;
    let d = {};
    d.o_usuario = codigoUsuario;
    d.xc_user = nombreCompleto;
    d.t_perfil = tipoUsuario;
    d.c_user = nombreUsuario;
    d.t_documento = labelDocumento;
    d.n_documento = nDocumento;
    d.n_telefono = nTelefono;
    d.xl_domicilio = domicilio;
    d.xl_email = email;
    d.n_login_fallido = intentosFallidos;

    dispatch(updateUserData(d))
      .then(unwrapResult)
      .then(() => {
        dispatch(SetInformation(
          {
            title: 'Operación realizada exitosamente',
            description: "El usuario ha sido modificado exitosamente."
          }
        ));
      }).catch(() => {
        dispatch(SetInformation(
          {
            title: 'Error',
            description: "Ha ocurrido un error al modificar el usuario."
          }));
      });
  };

  const onCuitChange = (e) => {
    setNDocumento(e.target.value)
    if (e.target.value && !ValidateCuit(e.target.value)) {
      setErrorCUIT(true);
      setLeyendaCUIT("CUIT/CUIL inválido");
    } else {
      setErrorCUIT(false);
      setLeyendaCUIT("");
    }
  };

  const onChangeDocumentType = (documentType) => {
    if (documentType) {
      setLabelDocumento(documentType.label);
      setTipoUser(documentType.label);
      setTipoValue(documentType.value);
    } else {
      setLabelDocumento('');
      setTipoUser('');
      setTipoValue('');
    }
  };

  function onVolver() {
    dispatch(SetFilterData(null));
    navigate('/queryUsers');
  }

  return (
    <form style={{ display: 'flex', justifyContent: 'center', minWidth: '600px', width: '100%', padding: 0 }}
      onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'center', width: '600px', minWidth: '600px' }}>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography style={{ fontSize: '20px' }}>Administrar Usuario</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            id="fullName"
            label="Apellido / Nombre / Razon Social"
            name="fullName"
            //autoComplete="userName"
            fullWidth
            className={style.textField}
            inputprops={{ style: { fontSize: 14 } }}
            InputLabelProps={{ style: { fontSize: 14 } }}
            value={nombreCompleto}
            onChange={(e) => setNombrecompleto(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            id="tags-standard"
            options={userTypes}
            getOptionLabel={(option) => option.label}
            fullWidth
            disabled
            renderInput={(userTypes) => (
              <TextField

                {...userTypes}
                label="Tipo usuario"
                name="userType"
                id='userType'
                variant="outlined"
                fullWidth
              //inputRef={register}
              />
            )}
            style={{ marginBottom: '0', marginTop: '0' }}
            value={!tipoUsuario ? "" : { label: tipoUsuario.label, value: tipoUsuario.value }}
            onChange={(event, value) => {
              setTipoUsuario(value)
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            id="userName"
            label="Nombre de Usuario"
            name="userName"
            //autoComplete="userName"
            fullWidth
            disabled
            className={style.textField}
            inputprops={{ style: { fontSize: 14 } }}
            InputLabelProps={{ style: { fontSize: 14 } }}
            value={nombreUsuario}
            onChange={(e) => setNombreUsuario(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            id="tags-standard"
            options={documentTypes}
            getOptionLabel={(option) => option.label}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                label="Tipo Documento"
                name="tipoDoc"
                id='tipoDoc'
                variant="outlined"
                fullWidth
              //inputRef={register}
              />
            )}
            style={{ marginBottom: '0', marginTop: '0' }}
            value={tipoUser == '' ? null : { label: tipoUser, value: tipoValue }}
            // defaultValue={tipoDoc}
            onChange={(e, value) => onChangeDocumentType(value)}
            getOptionSelected={(option, value) => option.value === value.value}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            id="nDocumento"
            label={labelDocumento}
            name="nDocumento"
            autoComplete="nDocumento"
            fullWidth
            disabled
            {...register("nDocumento")}
            className={style.textField}
            inputprops={{ style: { fontSize: 14 } }}
            InputLabelProps={{ style: { fontSize: 14 } }}
            value={nDocumento}
            onChange={onCuitChange}
            error={errorCUIT}
            helperText={leyendaCUIT}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            id="nTelefono"
            label="Nº Teléfono"
            name="nTelefono"
            autoComplete="nTelefono"
            fullWidth
            className={style.textField}
            inputprops={{ style: { fontSize: 14 } }}
            InputLabelProps={{ style: { fontSize: 14 } }}
            value={nTelefono}
            onChange={(e) => setNTelefono(e.target.value)}

          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            id="domicilio"
            label="Domicilio"
            name="domicilio"
            autoComplete="domicilio"
            fullWidth
            className={style.textField}
            inputprops={{ style: { fontSize: 14 } }}
            InputLabelProps={{ style: { fontSize: 14 } }}
            value={domicilio}
            onChange={(e) => setDomicilio(e.target.value)}

          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            fullWidth
            className={style.textField}
            inputprops={{ style: { fontSize: 14 } }}
            InputLabelProps={{ style: { fontSize: 14 } }}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value)
              if (!ValidateEmail(e.target.value)) {
                setErrorEmail(true);
                setLeyendaEmail("Ingrese un email válido");
              } else {
                setErrorEmail(false);
                setLeyendaEmail("");

              }

            }
            }
            error={errorEmail}
            helperText={leyendaEmail}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            id="failedAttempts"
            label="Login Fallidos"
            name="failedAttempts"
            //autoComplete="intentosFallidos"
            fullWidth
            className={style.textField}
            inputprops={{ style: { fontSize: 14 } }}
            InputLabelProps={{ style: { fontSize: 14 } }}
            value={intentosFallidos}
            onChange={(e) => setIntentosFallidos(e.target.value)}
          />
        </Grid>


        <Grid item xs={12} style={{ marginTop: 15, marginBottom: 15 }}>
          <Divider />
        </Grid>


        <Grid item xs={6} style={{ width: '100%', textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
          <Button
            size="large"
            type="button"
            variant="outlined"
            onClick={onVolver}
            className={style.buttonVolver}
            fullWidth>
            Volver
          </Button>
        </Grid>
        <Grid item xs={6} style={{ width: '100%', textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
          <Button
            size="large"
            type="submit"
            variant="outlined"
            className={style.buttonGuardar}
            fullWidth>
            Guardar
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}