import moment from "moment";
import 'moment/locale/es';
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux"; 
import { Button, Divider, Grid, TextField, Typography } from "@material-ui/core";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { esUsuarioRRHH } from "../../../helpers/UsuarioHelper";
import { CuitMask } from "../../../helpers/maskedImputs";
import { CurrentDate, ValidateCuit, ValidateDateRange } from "../../../helpers/validators";
import { SetFilterPayslips } from "../../../redux/slices/dashboardSlice";
import Colors from "../../../theme/colors";
import Styles from "../styles";
import { useLocation } from "react-router-dom";


const FilterPayslipsAdmin = () => {
  const style = Styles();
  const dispatch = useDispatch();
  const location = useLocation();
  const currentPath = location.pathname.toLowerCase();

  const { handleSubmit, control, reset, register, setValue } = useForm({});
  const user = useSelector((state) => state.user.user);

  const [error, setError] = useState(false);
  const [leyenda, setLeyenda] = useState("");
  const [cuitError, setCuitError] = useState(false);
  const [leyendaCuit, setLeyendaCuit] = useState("");
  const [username, setUsername] = useState(user.n_DOCUMENTO.toString());
  const [isLimpiar, setIsLimpiar] = useState(false);
  const [fromYearError, setFromYearError] = useState(false);
  const [toYearError, setToYearError] = useState(false);

  moment.locale('es');
  const minDate = moment('2000-01-01');
  const defaultDateValue = CurrentDate.format('MM/YYYY');

  const setDate = (data) =>{
    if (isLimpiar)
      return CurrentDate;
    return data == undefined ? CurrentDate : data;
  } 

  const onSubmit = (data) => {
    if (toYearError || fromYearError)
      return

    if (esUsuarioRRHH(user) && !ValidateCuit(username))
      return

    if (esUsuarioRRHH(user) && username == '') {
      
      setLeyendaCuit("Campo obligatorio");
      return;
    } else {
      
      setLeyendaCuit("");
    }

    data.dateFrom = setDate(data.dateFrom);
    data.dateTo = setDate(data.dateTo);

    if (!ValidateDateRange(data.dateFrom, data.dateTo)) {
      setError(true);
      setLeyenda("El Período Desde debe ser menor o igual que el Período Hasta");
      return;
    } else {
      setError(false);
      setLeyenda("");
    }

    let dateFromDateFrom = data.dateFrom.toDate();
    let dateToDateTo = data.dateTo.toDate();

    const dataFilter = {
      cuit: username == "" ? user.n_DOCUMENTO.toString() : username,
      fromMonth: (dateFromDateFrom.getMonth() + 1).toString().padStart(2, "0"),
      fromYear: dateFromDateFrom.getFullYear(),
      toMonth: (dateToDateTo.getMonth() + 1).toString().padStart(2, "0"),
      toYear: dateToDateTo.getFullYear(),
      consultar: true
    };
    dispatch(SetFilterPayslips(dataFilter));
  };

  const onClearData = () => {
    setError(false);
    setCuitError('');
    setLeyendaCuit('');
    setUsername('');
    setIsLimpiar(true);
    setValue("dateFrom", moment(defaultDateValue, 'MM/YYYY'));
    setValue("dateTo", moment(defaultDateValue, 'MM/YYYY'));

    reset();
    document.activeElement.blur();

    const dataFilter = {
      cuit: user.n_DOCUMENTO.toString(),
      fromMonth: (CurrentDate.toDate().getMonth() + 1).toString().padStart(2, "0"),
      fromYear: CurrentDate.toDate().getFullYear(),
      toMonth: (CurrentDate.toDate().getMonth() + 1).toString().padStart(2, "0"),
      toYear: CurrentDate.toDate().getFullYear(),
      limpiar: true
    };

    dispatch(SetFilterPayslips(dataFilter));
  };

  const onCuitChange = (e) => {
    if (!ValidateCuit(e.target.value)) {
      setCuitError(true);
      setLeyendaCuit("CUIT/CUIL inválido");
    } else {
      setCuitError(false);
      setLeyendaCuit("");
    }

    setUsername(e.target.value);
  };

  const isYearValid = (date, setErrorState) => {
    const selectedYear = moment(date).year();
    const isValid = selectedYear >= 1900;
    setErrorState(!isValid);
    return isValid;
  };

  useEffect(() => {
    onClearData()
  }, []);

  return (
    <form
      className={style.form}
      style={{
        display: "flex",
        justifyContent: "center",
        minWidth: "400px",
        width: "100%",
        marginTop: 10,
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid
        container
        spacing={2}
        style={{
          display: "flex",
          justifyContent: "center",
          width: "400px",
          minWidth: "400px",
        }}
      >
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Typography style={{ fontSize: "20px" }}>Consulta de Recibos</Typography>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="dateFrom"
            control={control}
            render={(field) => (
              <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="es">
                <DatePicker
                  defaultValue={moment(defaultDateValue, 'MM/YYYY')}
                  value={field.value || moment(defaultDateValue, 'MM/YYYY')}
                  views={['year', 'month']}
                  openTo="year"
                  inputFormat="MM/yyyy"
                  label="Fecha Desde"
                  sx={{ width: "100%" }}
                  minDate={minDate}
                  onChange={(date) => {
                    isYearValid(date, setFromYearError)
                    setIsLimpiar(false);
                    field.onChange(date);
                  }}
                />
              </LocalizationProvider>
            )}
          />
          {fromYearError && (
            <Typography variant="caption" color="error">
              Año no válido
            </Typography>
          )}

        </Grid>
        <Grid item xs={12}>
          <Controller
            name="dateTo"
            control={control}
            render={(field) => (
              <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="es">
                <DatePicker
                  defaultValue={moment(defaultDateValue, 'MM/YYYY')}
                  value={field.value || moment(defaultDateValue, 'MM/YYYY')}
                  views={['year', 'month']}
                  openTo="year"
                  inputFormat="MM/yyyy"
                  label="Fecha Hasta"
                  sx={{ width: "100%" }}
                  minDate={minDate}
                  onChange={(date) => {
                    isYearValid(date, setToYearError)
                    setIsLimpiar(false);
                    field.onChange(date);
                  }}
                />
              </LocalizationProvider>
            )}
          />
          {toYearError && (
            <Typography variant="caption" color="error">
              Año no válido
            </Typography>
          )}
          {error && <Typography variant="body2" color="error">{leyenda}</Typography>}
        </Grid>
        { esUsuarioRRHH(user) && currentPath === '/dashboard/payslipsmanager' &&
          <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="normal"
            id="username"
            label="CUIT/CUIL"
            name="username"
            autoComplete="username"
            fullWidth
            className={style.textField}
            onBlur={onCuitChange}
            value={username}
            inputRef={register}
            error={cuitError}
            helperText={leyendaCuit}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              inputComponent: CuitMask,
            }}
          />
          </Grid>
        }
        <Grid
          item
          xs={6}
          style={{
            width: "100%",
            textDecoration: "none",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Button
            size="large"
            type="button"
            variant="outlined"
            onClick={onClearData}
            style={{
              margin: 0,
              backgroundColor: Colors.white,
              border: "2px solid",
              borderColor: Colors.blue,
              color: Colors.blue,
              maxHeight: "50px",
              minHeight: "50px",
            }}
            fullWidth
          >
            Limpiar
          </Button>
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            width: "100%",
            textDecoration: "none",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Button
            size="large"
            type="submit"
            variant="outlined"
            style={{
              margin: 0,
              backgroundColor: Colors.blue,
              border: "2px solid",
              borderColor: Colors.blue,
              color: Colors.white,
              maxHeight: "50px",
              minHeight: "50px",
            }}
            fullWidth
          >
            Consultar
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{ marginBottom: "15px" }} />
        </Grid>
      </Grid>
    </form>
  );
}

export default FilterPayslipsAdmin;