import React from "react";
import { useDispatch } from "react-redux";
import FilterPayslipsAdmin from "./FilterPayslipsAdmin"
import PayslipsTableAdmin from "./PayslipsTableAdmin";
import { breadcrumbs } from "../../../settings";
import { setBreadcrumbs } from "../../../redux/slices/generalSlice";
const EmployeePayslips = () => {
  const dispatch = useDispatch();
  dispatch(setBreadcrumbs(breadcrumbs.salary));
  return (
    <>
      <FilterPayslipsAdmin />
      <PayslipsTableAdmin />
    </>
  );
}

export default EmployeePayslips;