import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Grid,
  Typography,
  Box,
  Hidden,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import styles from "../styles";

import {
  ValidatePassword,
} from "../../../helpers/validators";

import Copyright from "../../../components/Copyrigth";
import { ChangePassUser } from "../../../redux/slices/passwordSlice";
import InputTextField from "../../../components/Forms/input"
import { TemplateNewPassword } from "../../../components/FormComponents";

const Logo = require("./../../../static/img_ban.png");

const NewPassword = () => {
  const classes = styles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { handleSubmit, watch, formState: { errors }, control, setValue, setError, clearErrors, trigger } = useForm();

  const [validPassword, setValidPassword] = useState(false);

  const { id } = useParams();

  const onSubmit = (data) => {
    let d = { username: id, password: data.password };
    dispatch(ChangePassUser(d)).then((r) => {
      if (r.type == "/recovery/changePassword/fulfilled")
        navigate("/recoverPasswordConfirmation");
    });
  };

  const handleValidatePassword = (e) => {
    setValue("password", e.target.value);

    if (!ValidatePassword(e.target.value)) {
      setError("password", {
        type: "custom",
        message: "La contraseña debe ser alfanumérica y tener al menos 8 dígitos y una mayúscula",
        shouldFocus: true
      });
      setValidPassword(false);
    } else {
      setValidPassword(true);
    }
    const repeatPassword = watch('repeatPassword', '');
    if (repeatPassword !== e.target.value) {
      setError("repeatPassword", {
        type: "custom",
        message: "Las contraseñas no coinciden",
        shouldFocus: false
      });
    } else {
      clearErrors("repeatPassword");
    }
  };

  return (
    <Grid container component="main" className={classes.root}>

      <Grid item xs={12} sm={8} md={6} >
        <div className={classes.paper}>
          <form
            className={classes.form}
            style={{ width: "30%" }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <TemplateNewPassword/>

            <Grid
              container
              spacing={2}
              direction="row"
              style={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "center",
              }}
            >

              <Grid item xs={12}>
                <InputTextField
                  className={classes.textField}
                  fullWidth
                  name="password"
                  type="password"
                  variant="outlined"
                  control={control}
                  label="Nueva contraseña"
                  error={!validPassword && !!errors.password}
                  helperText={!validPassword ? errors.password?.message : ""}
                  onChange={handleValidatePassword}
                />
              </Grid>
              <Grid item xs={12}>
                <InputTextField
                  className={classes.textField}
                  fullWidth
                  name="repeatPassword"
                  type="password"
                  variant="outlined"
                  control={control}
                  label="Repetir contraseña"
                  error={!!errors.repeatPassword}
                  helperText={errors.repeatPassword?.message}
                  onChange={(e) => {
                    setValue("repeatPassword", e.target.value);
                    trigger("repeatPassword"); // Disparar validación del campo de repetir contraseña
                  }}
                  rules={{
                    validate: (value) => {
                      const password = watch('password', '');
                      return password === value ? null : 'Las contraseñas no coinciden';
                    }
                  }}
                />
              </Grid>

              <Button
                type="submit"
                variant="outlined"
                color="primary"
                className={classes.button}
                fullWidth
              >
                Continuar
              </Button>

              <Typography
                className={classes.label}
                style={{ textAlign: "center" }}
              >
                Para finalizar te enviaremos un correo electrónico confirmando
                el cambio de la contraseña
              </Typography>
            </Grid>

            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
      <Hidden smDown>
        <Grid xs={false} md={6}>
          <img alt= "logo" style={{ width: '100%', height: '100%' }} src={Logo} />
        </Grid>
      </Hidden>
    </Grid>
  );
}

export default NewPassword;