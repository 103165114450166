import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../api";


// -----------------------------------------------------------
// ----------------------- DATASETS --------------------------
// -----------------------------------------------------------

export const dateDefaultValues = {
  defaultInitDate: new Date().getFullYear() - 5 + "-01-01",
  defaultEndDate: new Date().getFullYear() + 5 + "-12-31",
  today:
    new Date().getFullYear() +
    "-" +
    ("0" + (new Date().getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + new Date().getDate()).slice(-2),
};

export const userTypes = [
  { label: "Interno", value: 0 },
  { label: "Afiliado", value: 1 },
  { label: "Prestador", value: 2 },
  { label: "Otro", value: 3 },
];

export const visibilidad = [
  { label: "Privada", value: "R" },
  { label: "Pública", value: "P" },
];

export const payslipsStates = [
  { label: "Aceptado", value: "A" },
  { label: "Pendiente", value: "P" },
  { label: "Rechazado", value: "R" },
];

// -----------------------------------------------------------
// ------------------- TEMP DATA PROVIDER --------------------
// -----------------------------------------------------------
//------------------------------------------------------------

export const PayslipsDataset = [

];

/* DATOS  */

export const GetFolderTypes = createAsyncThunk("/getFolderType", async(data, thunkAPI)=>{
  try
  {
    const response = await api.get("/commons/get-tipocarpeta");
    return response.data;
  }
  catch (e)
  {
    return thunkAPI.rejectWithValue(e);
  }

});

export const GetStateTypes = createAsyncThunk("/getStateType", async(data, thunkAPI)=>{
  try
  {
    const response = await api.get("/commons/get-estadoscarpeta");
    return response.data;
  }
  catch (e)
  {
    return thunkAPI.rejectWithValue(e);
  }

});

export const GetDocTypes = createAsyncThunk("/getDocType", async(data, thunkAPI)=>{
  try
  {
    const response = await api.get("/commons/get-tipodocumento");
    return response.data;
  }
  catch (e)
  {
    return thunkAPI.rejectWithValue(e);
  }
});

export const GetSectorTypes = createAsyncThunk("/getSectorType", async(data, thunkAPI)=>{
  try
  {
    const response = await api.get("/commons/get-sector");
    return response.data;
  }
  catch (e)
  {
    return thunkAPI.rejectWithValue(e);
  }
});

/* FIN DE DATOS */

// -----------------------------------------------------------
// ------------------------ FOLDER ---------------------------
// -----------------------------------------------------------

export const GetFolders = createAsyncThunk(
  "/folders",
  async (data, thunkAPI) => {
    try {
      let isInternal;

      if (data.usercuit.c_ROL_USUARIO === 'OSBA_PERSONA_RRHH') {
        isInternal = false;
      } else {
        isInternal =
          data.usercuit.t_PERFILES
            ? data.usercuit.t_PERFILES.some((p) => p === 'Interno')
            : data.usercuit.t_PERFIL === 'Interno';
      }
      const cuit =
        data.usercuit != null && isInternal
          ? 0
          : data.usercuit.n_DOCUMENTO;

      const response = await api.post("/folders/list/" + cuit +"?pageNumber="+ data.PageNumber + "&pageSize=" + data.PageSize, {
        Estado: data.Estado,
        TipoCarpeta: data.TipoCarpeta,
        CodCarpeta: Number(data.CodCarpeta),
        Anio: Number(data.Anio),
        CUIT: Number(data.CUIT),
        FechaDesde: new Date(data.FechaDesde),
        FechaHasta: new Date(data.FechaHasta),
        ContieneAmparo : data.ContieneAmparo,
      }, null);

      return response.data;
    }
    catch (e) {
      return thunkAPI.rejectWithValue(e);
    }

  }
);

export const CreateFolderRequest = createAsyncThunk(
  "/folders/createfolder",
  async (data, thunkAPI) => {
    try {
      const response = await api.post("/folders", data);
      return response.data
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const UpdateFolder = createAsyncThunk(
  "/folders/update",
  async (data, thunkAPI) => {

    try {
      const folderTypes = thunkAPI.getState().dashboard.folderTypes.data;
      let tCarpeta = '';
      for (const folderType of folderTypes) {
        if (folderType.label === data.TipoCarpeta) {
          tCarpeta = folderType.value;
          break;
        }
      }

      const requestData = {
        Id: data.Id,
        Anio: data.Anio,
        Factura: data.Factura,
        Amparo: data.Amparo,
        Observaciones: data.Observaciones,
        ObservacionesObsba: data.ObservacionesObsba,
        Estado: data.Estado,
        TipoCarpeta: tCarpeta,
        Titulo: data.Titulo,
        nDocumento: data.nDocumento,
        FechaPrestacion: data.fechaPrestacion,
        CUITBenef: data.cuitBenf,
        ImportePrestacion: data.importe,
      };

      if (data.hasOwnProperty('Visibilidad')) {
        requestData.VisibilidadObservacionesObsba = data.Visibilidad;
      }
      
      const response = await api.put("/folders", requestData);

      return response
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const UpdateStateFolder = createAsyncThunk(
  "/folders/updatestate",
  async (data, thunkAPI) => {
    try {
      const response = await api.put("/folders/UpdateState", {
        Id: data.Id,
        Anio: data.Anio,
        Estado: data.Estado,
        TipoCarpeta: data.TipoCarpeta,
        Titulo: data.Titulo,
        nDocumento: data.nDocumento,
      });

      return response
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const downloadExcel = createAsyncThunk(
    "/files/download",
    async (data, thunkAPI) => {
            try {
        let d = new Date();
        let month = ('' + (d.getMonth() + 1)).padStart(2, '0');
        let day = ('' + d.getDate()).padStart(2, '0');
        let year = d.getFullYear();
        const dataFiltered = {
          Estado: data.Estado,
          TipoCarpeta: data.TipoCarpeta,
          CodCarpeta: Number(data.CodCarpeta),
          Anio: Number(data.Anio),
          CUIT: Number(data.CUIT),
          FechaDesde: new Date(data.FechaDesde),
          FechaHasta: new Date(data.FechaHasta),
        };

        await api
            .post("/folders/ExportExcel", dataFiltered, {
              responseType: "arraybuffer",
              headers: {
                "Content-Type": "application/json",
              },
              timeout: 300000,
            })
            .then((response) => {
              const type = response.headers["content-type"];
              const blob = new Blob([response.data], {
                type: type,
                encoding: "UTF-8",
              });
              const link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = year + month + day + ' Folders';
              link.click();
            });
      } catch (e) {
        return  thunkAPI.rejectWithValue(e);
      }
    }
);



export const downloadReporte = createAsyncThunk(
  "/files/downloadReporte",
  async (data, thunkAPI) => {
          try {
      await api
          .post("/folders/informeCredito", null, {
            responseType: "arraybuffer",
            headers: {
              "Content-Type": "application/json",
            },
            timeout: 300000,
          })
          .then((response) => {
            const type = response.headers["content-type"];
            const blob = new Blob([response.data], {
              type: type,
              encoding: "UTF-8",
            });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download =  `Cuenta Corriente - ${data}`;
            link.click();
          });
    } catch (e) {
      
      return  thunkAPI.rejectWithValue(e);
    }
  }
);


// -----------------------------------------------------------
// ------------------------- FILES ---------------------------
// -----------------------------------------------------------

export const GetFiles = createAsyncThunk("/files", async (data, thunkAPI) => {
  try {
    const response = await api.get("/folders/"+ data.anio + "/" + data.id);
    return response.data;
   } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const GetFilesByFolder = createAsyncThunk("/filesFolders", async (data, thunkAPI) => {
  try {
    const response = await api.get(`/files/getFiles/${Number(data.anio)}/${Number(data.id)}?pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`);
    
    return response.data;
   } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});


export const GetFilesPreviewByFolder = createAsyncThunk("/filesPreviewFolders", async (data, thunkAPI) => {
  try {
    const response = await api.get(`/files/getFilesPreview/${Number(data.anio)}/${Number(data.id)}`);
    
    return response.data;
   } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const UploadFileRequest = createAsyncThunk(
  "/files/upload",
  async (data, thunkAPI) => {
    const fileTypes = thunkAPI.getState().dashboard.docTypes.data;
    let tipoDocumento = "";

    for (const fileType of fileTypes) {
      if (data.fileType === fileType.label) {
        tipoDocumento = fileType.value;
        break;
      }
    }

    const formData = new FormData();
    formData.append("AnioCarpeta", data.Anio);
    formData.append("CarpetaId", data.CarpetaId);
    formData.append("Titulo", data.fileTitle);
    formData.append("Descripcion", data.fileDescription);
    formData.append("TipoDocumento", tipoDocumento);
    formData.append("Usuario", data.Usuario);
    formData.append("Visibilidad", data.visibilidad);
    formData.append("UsuarioId", data.UsuarioId);
    for (const file of data.file) {
      formData.append("FormFile", file);
    }
  
    try {
      const response = await api.post("/files", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return response.data
    } catch (e) {
      api.defaults.headers.common["Content-Type"] = "application/json;charset=UTF-8";
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const deleteFile = createAsyncThunk(
  "/files/delete",
  async (data, thunkAPI) => {
    try {
      const response = await api.delete("/files/" + data.id);
      return response.data
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const downloadFile = createAsyncThunk(
  "/files/download",
  async (data, thunkAPI) => {
        try {
      await api
        .get("/files/Download?fileUrl=" + data.url, {
          responseType: "arraybuffer",
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          const type = response.headers["content-type"];
          const blob = new Blob([response.data], {
            type: type,
            encoding: "UTF-8",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = data.file;
          link.click();
        });
    } catch (e) {
            return thunkAPI.rejectWithValue(e);
    }
  }
);

export const previewFile = createAsyncThunk(
  "/files/preview",
  async (data, thunkAPI) => {
    try {
      const response = await api
        .get("/files/Download?fileUrl=" + data.url, {
          responseType: "arraybuffer",
          headers: {
            "Content-Type": "application/json",
          },
        })
        let pathArray = data.file.split(".");
        return {data: response.data, ex: pathArray[pathArray.length - 1]}
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// -----------------------------------------------------------
// ----------------------- PAYSLIPS --------------------------
// -----------------------------------------------------------

export const GetPayslips = createAsyncThunk("/payslips", async (data, thunkAPI) => {
  try {
    const response = await api.get("/Paylips/GetPaylips?cuil=" + data.cuit + "&desde=" + data.fromMonth + "-" + data.fromYear + "&hasta=" + data.toMonth + "-" + data.toYear + "&pageNumber=" + data.pageNumber + "&pageSize=" + data.pageSize);
    return response.data.content;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const DownloadPayslips = createAsyncThunk(
  "/payslips/download", async (data, thunkAPI) => {
    try {
      
      await api.get("/Paylips/Download?filePath=" + data.filePath, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
      const type = response.headers["content-type"];
      const blob = new Blob([response.data], {
        type: type,
        encoding: "UTF-8",
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${data.fileName}`;
      link.click();
});
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const PreviewPayslip = createAsyncThunk(
  "/preview/payslip",
  async (data, thunkAPI) => {
    try {
      const response = await api.get("/Paylips/Download?filePath=" + data.filePath, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
        },
      })
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const SetPayslips = createAsyncThunk("/setPayslips", async (data, thunkAPI) => {
  try {
    thunkAPI.dispatch(SetPayslipsFiltered(data));
    return data;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

// -----------------------------------------------------------
// ------------------------- SLICE ---------------------------
// -----------------------------------------------------------

const initialState = {
  loading: false,
  error: null,
  success: false,
  folder: {
    data: {
      pendientes: null,
      rechazados: null,
      data: [],
      RecordsCount: null
    },
    error: {},
    loading: "idle",
    success: false,
  }  ,
  file: {
    data: {
      files: null,
      cantidadFiles: null,
      cantDoc: null
    },
    actualizar: false,
    files: null,
    loading: "idle",
    error: null,
    success: false,
  },
  payslips: {
    data: {
      listaResultado: null,
      cantidadResultado: null,
      cantidadTotal: null
    },
    loading: "idle",
    error: null,
    success: false,
  },
  createFolder: {
    data: [],
    loading: "idle",
    error: null,
    success: false,
  },
  updateFolder: {
    data: [],
    loading: "idle",
    error: null,
    success: false,
  },
  updateStateFolder: {
    data: null,
    loading: "idle",
    error: null,
    success: false,
  },
  uploadFile: {
    data: [],
    loading: "idle",
    error: null,
    success: false,
  },
  deleteFile: {
    data: [],
    loading: "idle",
    error: null,
    success: false,
  },
  downloadFile: {
    data: [],
    loading: "idle",
    error: null,
    success: false,
  },
  downloadPayslips: {
    data: [],
    loading: "idle",
    error: null,
    success: false,
  },
  previewFile: {
    loading: "",
    error: null,
    success: false,
  },
  previewPayslips: {
    loading: null,
    error: null,
    success: false,
  },
  folderFiltered: { data: null },
  payslipsFiltered: { data: null },
  filterFolder: null,
  filterPayslips: null,
  timerActive: true,
  folderTypes: {
    data: null,
    loading: false,
    error: null,
    success: false,
  },
  folderStates: {
    data: null,
    loading: false,
    error: null,
    success: false,
  },
  docTypes: {
    data: null,
    loading: false,
    error: null,
    success: false,
  },
  sectorTypes: {
    data: null,
    loading: false,
    error: null,
    success: false,
  }
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    SetActualizar : (state, action) =>{
      state.file.actualizar = action.payload
    },
    SetFilterPayslips: (state,action)=>{
      state.filterPayslips = action.payload;
    },
    SetFilterFolder: (state,action)=>{
      state.filterFolder = action.payload;
    },
    CleanUpFolder: (state, action) => {
      state.folder = { ...initialState.folder };
    },
    FolderSuccess: (state, action) => {
      state.folder = action.payload;
    },
    SetFolderFiltered: (state, action) => {
      state.folderFiltered = {};
      state.folderFiltered = action.payload;
    },
    CreateFolderSuccess: (state, action) => {
      state.createFolder = action.payload;
    },
    UpdateFolderSuccess: (state, action) => {
      state.updateFolder = action.payload;
    },
    FileSuccess: (state, action) => {
      state.file = action.payload;
    },
    UploadFileSuccess: (state, action) => {
      state.uploadFile = action.payload;
      api.defaults.headers.common["Content-Type"] =
        "application/json;charset=UTF-8";
    },
    DeleteSuccess: (state, action) => {
      state.deleteFile = action.payload;
    },
    DownloadSuccess: (state, action) => {
      state.downloadFile = action.payload;
    },
    GetPayslipsSuccess: (state, action) => {
      state.payslips = action.payload;
    },
    SetPayslipsFiltered: (state, action) => {
      state.payslips = action.payload;
    },
    DownloadPayslipsSuccess: (state, action) => {
      state.downloadPayslips = action.payload;
    },
    SetActiveTimer: (state, action) =>{
      state.timerActive = action.payload;
    },
    cleanExcelExport : (state, action) =>{
      state.downloadFile = {
        data: [],
        loading: "idle",
        error: false,
        success: false,
        message: null
      }
    },
    CleanFoldersPendientes : (state, action) =>{
      state.folder.data.pendientes = null;
      state.folder.data.rechazados = null;
    },
    clearData: () => initialState,
    
  },
  extraReducers: (builder) => {
    builder
    .addCase(GetSectorTypes.pending, (state) => {
      state.sectorTypes.loading = true;
    })
    .addCase(GetSectorTypes.fulfilled, (state, action) => {
      state.sectorTypes.data = action.payload.map(item => ({
        label: item.label,
        value: item.value
      }));
      state.sectorTypes.loading = false;
      state.sectorTypes.success = true;
    })
    .addCase(GetSectorTypes.rejected, (state, action) => {
      state.sectorTypes.error = action.error;//action.payload
      state.sectorTypes.loading = false;
    })
    .addCase(GetFolderTypes.pending, (state) => {
      state.folderTypes.loading = true;
    })
    .addCase(GetFolderTypes.fulfilled, (state, action) => {
      state.folderTypes.data = action.payload.map(item => ({
        label: item.label,
        value: item.value
      }));
      state.folderTypes.loading = false;
      state.folderTypes.success = true;
    })
    .addCase(GetFolderTypes.rejected, (state, action) => {
      state.folderTypes.error = action.error;//action.payload
      state.folderTypes.loading = false;
    })
    .addCase(GetStateTypes.pending, (state) => {
      state.folderStates.loading = true;
    })
    .addCase(GetStateTypes.fulfilled, (state, action) => {
      state.folderStates.data = action.payload.map(item => ({
        label: item.label,
        value: item.value
      }));
      state.folderStates.loading = false;
      state.folderStates.success = true;
    })
    .addCase(GetStateTypes.rejected, (state, action) => {
      state.folderStates.error = action.error;//action.payload
      state.folderStates.loading = false;
    })
    .addCase(GetDocTypes.pending, (state) => {
      state.docTypes.loading = true;
    })
    .addCase(GetDocTypes.fulfilled, (state, action) => {
      state.docTypes.data = action.payload.map(item => ({
        label: item.label,
        value: item.value
      }));
      state.docTypes.loading = false;
      state.docTypes.success = true;
    })
    .addCase(GetDocTypes.rejected, (state, action) => {
      state.docTypes.error = action.error;//action.payload
      state.docTypes.loading = false;
    })
    .addCase(GetFolders.pending, (state) => {
      state.folder.loading = "pending";
    })
    .addCase(GetFolders.fulfilled, (state, action) => {
      state.folder.data = action.payload;
      state.folder.loading = "idle";
      state.folder.success = true;
    })
    .addCase(GetFolders.rejected, (state, action) => {
      state.folder.error = 'Token Expirado';
      state.folder.loading = "idle";
    })
    .addCase(CreateFolderRequest.pending, (state) => {
      state.createFolder.loading = "pending";
    })
    .addCase(CreateFolderRequest.fulfilled, (state, action) => {
      state.createFolder.data = action.payload;
      state.createFolder.loading = "idle";
      state.createFolder.success = true;
    })
    .addCase(CreateFolderRequest.rejected, (state, action) => {
      state.createFolder.error = action.error;
      state.createFolder.loading = "idle";
    })
    .addCase(UpdateFolder.pending, (state) => {
      state.updateFolder.loading = "pending";
    })
    .addCase(UpdateFolder.fulfilled, (state, action) => {
      state.updateFolder.data = action.payload;
      state.updateFolder.loading = "idle";
      state.updateFolder.success = true;
    })
    .addCase(UpdateFolder.rejected, (state, action) => {
      state.updateFolder.error = action.error;
      state.updateFolder.loading = "error";
    })
    .addCase(UpdateStateFolder.pending, (state) => {
      state.updateStateFolder.loading = "pending";
    })
    .addCase(UpdateStateFolder.fulfilled, (state, action) => {
      state.updateStateFolder.data = action.payload;
      state.updateStateFolder.loading = "idle";
      state.updateStateFolder.success = true;
    })
    .addCase(UpdateStateFolder.rejected, (state, action) => {
      state.updateStateFolder.error = action.error;
      state.updateStateFolder.loading = "error";
    })
    .addCase(GetFiles.pending, (state) => {
      state.file.loading = "pending";
    })
    .addCase(GetFiles.fulfilled, (state, action) => {
      state.file.data = action.payload;
      state.file.loading = "idle";
      state.file.success = true;
    })
    .addCase(GetFiles.rejected, (state, action) => {
      state.file.error = 'Token Expirado';
      state.file.loading = "idle";
    })
    .addCase(GetFilesByFolder.pending, (state) => {
      state.file.loading = "pending";
    })
    .addCase(GetFilesByFolder.fulfilled, (state, action) => {
      state.file.files = action.payload;
      state.file.loading = "idle";
      state.file.success = true;
    })
    .addCase(GetFilesByFolder.rejected, (state, action) => {
      state.file.error = 'Token Expirado';
      state.file.loading = "idle";
    })
    .addCase(UploadFileRequest.pending, (state) => {
      state.uploadFile.loading = "pending";
    })
    .addCase(UploadFileRequest.fulfilled, (state, action) => {
      state.uploadFile.data = action.payload;
      state.uploadFile.loading = "idle";
      state.uploadFile.success = true;
      api.defaults.headers.common["Content-Type"] = "application/json;charset=UTF-8";
    })
    .addCase(UploadFileRequest.rejected, (state, action) => {
      state.uploadFile.error = action.error;
      state.uploadFile.loading = "idle";
    })
    .addCase(deleteFile.pending, (state) => {
      state.deleteFile.loading = "pending";
    })
    .addCase(deleteFile.fulfilled, (state, action) => {
      state.deleteFile.data = action.payload;
      state.deleteFile.loading = "idle";
      state.deleteFile.success = true;
    })
    .addCase(deleteFile.rejected, (state, action) => {
      state.deleteFile.error = action.error;
      state.deleteFile.loading = "idle";
    })
    .addCase(downloadFile.pending, (state) => {
      state.downloadFile.loading = "pending";
    })
    .addCase(downloadFile.fulfilled, (state, action) => {
      state.downloadFile.data = action.payload;
      state.downloadFile.loading = "idle";
      state.downloadFile.success = true;
    })
    .addCase(downloadFile.rejected, (state, action) => {
      const response =JSON.parse(new TextDecoder("utf-8").decode(action.payload.response.data))
      state.downloadFile.error = true;
      state.downloadFile.message = response.message;
      state.downloadFile.loading = "error";
    })
    .addCase(downloadReporte.pending, (state) => {
      
    })
    .addCase(downloadReporte.fulfilled, (state, action) => {
      
    })
    .addCase(downloadReporte.rejected, (state, action) => {
      
    })
    .addCase(GetPayslips.pending, (state) => {
      state.payslips.loading = "pending";
    })
    .addCase(GetPayslips.fulfilled, (state, action) => {
      state.payslips.data = action.payload;
      state.payslips.loading = "idle";
      state.payslips.success = true;
      state.payslips.error = action.payload.cantidadResultado == 0 ? "No hay recibos para el periodo consultado" : null;
    })
    .addCase(GetPayslips.rejected, (state, action) => {
      state.payslips.error = "Error inesperado en servicio de recibos de sueldo";
      state.payslips.loading = "error";
    })
    .addCase(DownloadPayslips.pending, (state) => {
      state.downloadPayslips.loading = "pending";
    })
    .addCase(DownloadPayslips.fulfilled, (state, action) => {
      state.downloadPayslips.data = action.payload;
      state.downloadPayslips.loading = "idle";
      state.downloadPayslips.success = true;
    })
    .addCase(DownloadPayslips.rejected, (state, action) => {
      state.downloadPayslips.error = action.error;
      state.downloadPayslips.loading = "error";
    })
    .addCase(previewFile.pending, (state) => {
      state.previewFile.loading = "pending";
    })
    .addCase(previewFile.fulfilled, (state, action) => {
      state.previewFile.loading = "idle";
      state.previewFile.success = true;
    })
    .addCase(previewFile.rejected, (state, action) => {
            state.previewFile.error = action.error;
      state.previewFile.loading = "error";
    })
    .addCase(PreviewPayslip.pending, (state) => {
      state.previewPayslips.loading = "pending";
    })
    .addCase(PreviewPayslip.fulfilled, (state, action) => {
      state.previewPayslips.loading = "idle";
      state.previewPayslips.success = true;
    })
    .addCase(PreviewPayslip.rejected, (state, action) => {
      state.previewPayslips.error = action.error;
      state.previewPayslips.loading = "error";
    })
  }
});

export const {
  SetActualizar,
  CleanUpFolder,
  FolderSuccess,
  CreateFolderSuccess,
  UpdateFolderSuccess,
  FileSuccess,
  UploadFileSuccess,
  clearData,
  DownloadSuccess,
  DeleteSuccess,
  SetFolderFiltered,
  SetPayslipsFiltered,
  SetFilterFolder,
  SetFilterPayslips,
  SetActiveTimer,
  cleanExcelExport,
  CleanFoldersPendientes,
  
} = dashboardSlice.actions;

export const selectState = (state) => state.dashboard;
export const selectFolder = (state) => state.dashboard.folder;
export const selectFile = (state) => state.dashboard.file;
export const selectTimerActive = (state) => state.dashboard.timerActive;
export const folderTypesBDescriptor = (state) => state.dashboard.folderTypes;
export const stateTypesBDescriptor = (state) => state.dashboard.folderStates;
export const docTypesBDescriptor = (state) => state.dashboard.docTypes;

export default dashboardSlice.reducer;