import { useState } from "react";
import { CustomModal } from "../../../components/FormComponents";
import PropTypes from 'prop-types';

export const FolderNotSend = (props) => {
  const [showMessage2, setShowMessage2] = useState(false); // Initially, showMessage2 is set to false
  const title1 = "Información";
  const message1 = "Estimado usuario, queremos avisarle que tiene carpetas rechazadas. ¡Gracias por su colaboración!";
  const title2 = "Información";
  const message2 =
    "Estimado usuario, queremos recordarle que tiene carpetas pendientes de enviar. Por favor, revise su lista de carpetas y asegúrese de enviar todas aquellas que sean necesarias para evitar retrasos o inconvenientes. ¡Gracias por su colaboración!";

  const handleCloseMessage1 = () => {
    if (props.data.pendientes) {
      setShowMessage2(true); // Set showMessage2 to true when message1 is closed if there are pending folders
    }
  };

  if (props.data.pendientes || props.data.rechazados) {
    return (
      <>
        {!showMessage2 && props.data.rechazados && (
          <CustomModal titleError={title1} messageError={message1} onClose={handleCloseMessage1} />
        )}
        {(props.data.pendientes && !props.data.rechazados || showMessage2) &&
          <CustomModal titleError={title2} messageError={message2} />}
    </>
  );
  }

  FolderNotSend.propTypes = {
    data: PropTypes.shape({
      pendientes: PropTypes.bool,
      rechazados: PropTypes.bool,
    }),
  };

  return null; // Return null if the condition is not met
};