import { Divider, Typography, Backdrop, Fade, Box, Modal } from "@material-ui/core";
import { modalStyles } from "../styles";
import Colors from "../../theme/colors";
import { useDispatch, useSelector } from "react-redux";
import { SetClose } from "../../redux/slices/modalSlice";
import { useNavigate } from "react-router-dom";


const ModalProvider = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const open = useSelector((state) => state.modal.open);
  const message = useSelector((state) => state.modal.message);
  const modalAsync = useSelector((state) => state.modal.modalAsync);

  const handleClose = () =>{
    if(modalAsync)
      navigate('/dashboard')
    
    dispatch(SetClose());
  }

  return (
    <>
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      id="modalObsba"
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 1500,
      }}
    >
      <Fade in={open}>
        <Box sx={modalStyles} id="modalObsba">
          <Typography
            id="transition-modal-title"
            variant="h6"
            component="h2"
            style={{ marginBottom: "5px", fontSize: "20px" }}
          >
            {message.title}
          </Typography>
          <Divider
            style={{ marginBottom: "15px", backgroundColor: Colors.blue }}
          />
          <Typography
            id="transition-modal-description"
            sx={{ mt: 2 }}
            style={{ fontSize: "15px", fontWeight: "300" }}
          >
            {message.description}
          </Typography>
        </Box>
      </Fade>
    </Modal>
      {props.children}
    </>
    
  );
};

export default ModalProvider;