import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField
} from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { SetInformation } from "../../redux/slices/modalSlice";
import PropTypes from 'prop-types';

import { DeleteUser, GetUsers } from "../../redux/slices/usersSlice";

const DialogDeleteUser = (props) => {
  const { control, handleSubmit, formState: { errors }, } = useForm();
  const dispatch = useDispatch(); 

  const onSubmit = (data) => {
    dispatch(DeleteUser({
      Id: props.dataToModify.o_USUARIO,
      Motivo: data.motivoBaja,
      FH_BAJA: data.fechaBaja
    })).then((e) => {
      dispatch(SetInformation(
        {
          title:'Operación realizada exitosamente',
          description:e.payload
        }
      ));
      const emptyFilter = {
        pageNumber: 0,
        pageSize: 5,
      };

      dispatch(GetUsers(emptyFilter)).then((e) => {
        props.setPaginacion({
          page: 0,
          pageSize: 5
        });
        props.action(e.payload.listaResultado);
      });
      props.handleClose()
    })

  };

  DialogDeleteUser.propTypes = {
    dataToModify: PropTypes.shape({
      o_USUARIO: PropTypes.string, 
      t_PERFIL: PropTypes.string, 
      n_DOCUMENTO: PropTypes.string
    }).isRequired,
    setPaginacion: PropTypes.func.isRequired,
    action: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
  };

  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      open={props.isOpen}
      onClose={() => props.handleClose()}
    >
      <DialogTitle id="alert-dialog-title">
        ¿Está seguro de dar de baja el perfil {props.dataToModify.t_PERFIL} del usuario {props.dataToModify.n_DOCUMENTO}?
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="fechaBaja"
                control={control}
                rules={{
                  required: "Campo obligatorio",
                  validate: (value) =>{
                    const pickedDate = new Date(value);
                    const currentDate = new Date();
                    return (
                      pickedDate.getTime() >
                      currentDate.getTime() ? null :
                      'La fecha de baja debe ser superior a la actual'
                      );
                  }
                }}
                render={({ field, ref, onChange }) => (
                  <TextField
                    name="fechaBaja"
                    inputRef={ref}
                    type="date"
                    onChange={onChange}
                    {...field}
                    fullWidth
                    placeholder="Motivo de baja"
                    error={!!errors.fechaBaja}
                    helperText={errors.fechaBaja?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="motivoBaja"
                control={control}
                rules={{
                  required: "Campo obligatorio",
                }}
                render={({ field, ref, onChange }) => (
                  <TextField
                    name="motivoBaja"
                    inputRef={ref}
                    {...field}
                    onChange={onChange}
                    fullWidth
                    label="Motivo de baja"
                    error={!!errors.motivoBaja}
                    helperText={errors.motivoBaja?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button onClick={() => props.handleClose()}>CANCELAR</Button>
            <Button type="submit">CONFIRMAR</Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default DialogDeleteUser;
