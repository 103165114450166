export const errorMessages = {
    nombreApellido: {
      empty: "Por favor, ingresa tu nombre y apellido. Este campo no puede estar vacío.",
      maxLength: "El nombre y apellido no pueden exceder los 90 caracteres en total."
    },
    telefono: {
      invalidFormat: "Ingresa solo números en el campo de teléfono, sin espacios ni caracteres especiales.",
      maxLength: "El número de teléfono no puede tener más de 20 caracteres."
    },
    domicilio: {
      empty: "Por favor, proporciona tu dirección. Este campo no puede estar vacío.",
      maxLength: "La dirección no puede exceder los 90 caracteres."
    },
    email: {
      valid: "Por favor, Ingrese un mail válido.",
    },
    cuit:{
      valid:"Por favor ingrese un tipo de documento"
    }
  };