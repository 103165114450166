import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FileTable from "./FileTable";
import FolderState from "./FolderState";

const FileDashboard = () => {
  const { folderId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!sessionStorage.getItem("folderId")) {
      sessionStorage.setItem("folderId", folderId);
    } else if (folderId !== sessionStorage.getItem("folderId")) {
      navigate("/error");
    }

    return () => {
      try {
        sessionStorage.removeItem("folderId");
      } catch (error) {
        console.error("Error al limpiar el sessionStorage", error);
      }
    };
  }, [folderId, navigate]);

  useEffect(() => {
    sessionStorage.setItem("folderId", folderId);
  }, [folderId]);

  return (
    <>
      <FolderState folderId={folderId} />
      <FileTable folderId={folderId} />
    </>
  );
};

export default FileDashboard;
