import { makeStyles } from "@material-ui/styles";
import backPicture2 from "../../static/img_ban.png";
import logoHoriIcon from "../../static/logo_horizontal_blanco.png";
import dotsIcon from "../../static/dots_icon.png";
import logoError from "../../static/logo_bco_obsba.png";
import Colors from "../../theme/colors";


export default makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    textErrorBox2: {
        color: '#325AA5',
        'font-size': '70px',
        'font-weight': 'bold',
        
        letterSpacing: 0,
        lineHeight: 1,
        textAlign: 'center'
        // 'margin-top': '-20px',
    },
    image: {
        backgroundImage: "url(" + backPicture2 + ")",
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
    },
    logo: {
        maxWidth: '100%',
        height: 'auto',
        width: 'auto',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%', // Adjust the size as needed
        },
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },   
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: '300px',
    },
    errorBox: {
        width: '260px',
        height: '210px',
        border: '2px solid #325AA5',
        'border-radius': '10px',
    },
    headerBox: {
        backgroundColor: '#325AA5',
        height: '35px',
    },
    errorHeadDots: {
        backgroundImage: "url(" + dotsIcon + ")",
        'background-repeat': 'no-repeat',
        'background-size': '30px 20px',
        'margin-top': '4px',
    },
    errorHeadImage : {
        backgroundImage: "url(" + logoHoriIcon + ")",
        'background-repeat': 'no-repeat',
        'background-size': '70px 20px',
        'margin-top': '5px',
    },
    errorMensajeForm: {
        fontSize: '10px',
        color: '#D40000',
        textAlign: 'center',
        display: 'block',
        marginBottom: '10px'
    },
    errorMensaje: {
        'font-size': '28px',
        color: '#325AA5',
        'text-align': 'center',
        'display': 'block',
        'font-weight': 'bold',
        'margin-bottom': '-36px'
    },
    error404: {
        'font-size': '110px',
        color: '#325AA5',
        'text-align': 'center',
        'display': 'block',
        'font-weight': 'bold',
        'margin-bottom': '-20px'
    },
    textErrorBox: {
        color: '#325AA5',
        'font-size': '84px',
        'font-weight': 'bold',
        width: 432,
        letterSpacing: 0,
        lineHeight: 1
        // 'margin-top': '-20px',
    },
    errorVertical: {
        'width': '130px',
        'height': '50px',
        'font-size': '45px',
        '-webkit-text-stroke': '2px #A4E5E1',
        '-webkit-text-fill-color': 'transparent',
        '-ms-transform': 'rotate(90deg)', /* IE 9 */
        '-moz-transform': 'rotate(90deg)', /* Firefox */
        '-webkit-transform': 'rotate(90deg)', /* Safari and Chrome */
        '-o-transform': 'rotate(90deg)' /* Opera */
    },
    logoObSBA: {
        backgroundImage: "url(" + logoHoriIcon + ")",
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'right',
        width: '40%',
        height: '40px',
        zIndex: "101",
    },
    buttonInicio: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: '#325AA5',
        border: '2px solid',
        borderColor: '#3086B4',
        color: Colors.white,
        maxHeight: '40px', 
        minHeight: '40px',
        'margin-top': '5px',
        width: '50%',
    },
    submit: {
        width: 328,
        height: 56,
       margin: theme.spacing(3, 0, 2),
        backgroundColor: Colors.blue,
        borderColor: Colors.blue,
        color: Colors.white,
        maxHeight: '40px', 
        minHeight: '40px',
        '&:hover': {
            backgroundColor: Colors.hoverblue,
        },
    },
    button: {
        width: 328,
        margin: theme.spacing(3, 0, 2),
        backgroundColor: Colors.white,
        border: '2px solid',
        borderColor: Colors.blue,
        color: Colors.blue
    },
    textField: {
        width: 328, 
        "& .MuiInputBase-root": {
            height: '100%'
        },
        '& label.Mui-focused': {
            color: Colors.blue,
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: Colors.blue,
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: Colors.grey,
            },
            '&:hover fieldset': {
              borderColor: Colors.blue,
            },
            '&.Mui-focused fieldset': {
              borderColor: Colors.blue,
            },
        },
    },
    tokenField: {
        "& .MuiInputBase-root": {
            height: '100%'
        },
        '& label.Mui-focused': {
            color: Colors.blue,
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: Colors.blue,
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: Colors.green,
            },
            '&:hover fieldset': {
              borderColor: Colors.blue,
            },
            '&.Mui-focused fieldset': {
              borderColor: Colors.blue,
            },
        },
    },
    buttonText: {
        textDecorationStyle: 'none'
    },
    link: {
        fontFamily:'Roboto, Medium',
        textDecorationStyle: 'none',
        color: Colors.blue,
        fontSize: '14px'
    },
    loginTitle: {
        fontSize: '54px',
        fontFamily: 'Sans-Serif',
        color: Colors.blue,
        fontWeight: 'bold'
    },    
    loginSubtitle: {
        fontSize: '17px',
        letterSpacing: '0.16px',
        fontFamily: 'EndodeSans-SemiExpanded',
        color: Colors.black,
        marginTop: '-4px',
        marginBottom: '15px',
        marginLeft: '80px'
    },    
    subtitle: {
        fontSize: '12px',
        fontFamily: 'Sans-Serif',
        color: Colors.black,
        marginBottom: '15px',
        marginTop: '5px'
    },
    label: {
        fontSize: '12px',
        fontFamily: 'Sans-Serif',
        color: Colors.black,
    },
    otpField: {
        borderColor: Colors.green,
        border: 'solid 1px',
        borderRadius: '5px',
        height: '50px',
        width: '50px',
        fontSize: '20px',
        textAlign: 'center',
        "&:hover": {
            borderColor: Colors.blue,
        },
        "&:focus": {
            outline: 'none',
            border: 'solid 1px',
            borderRadius: '5px',
            borderColor: Colors.blue,
        },
    },
    fullbar: {
        width: '100%',
        minHeight: '123px'
    },
    bar: {
        width: '100%', 
        height: '123px', 
        backgroundColor: Colors.blue,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    // barContainer: {
    //     width: '95%',
    // },
    paper2: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },   
    logo2: {
        backgroundImage: "url(" + logoError + ")",
        width: '212px',
        height: '59px',
        marginLeft: '50px',
        backgroundColor: '#325AA5'
    },
    fondoNavbar: {
        width: '100%',
        height: '123px',
        backgroundColor: '#325AA5'
    },
}));
