import { Box, Divider, Grid, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { BsBoxArrowRight, BsChevronDown, BsFolder, BsGraphUp, BsPeople, BsPerson } from 'react-icons/bs';
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { NavegarHome, esInternoEmpleado, soloEmpleado, soloInterno } from '../../helpers/UsuarioHelper';
import { GetDocTypes, GetFolderTypes, GetSectorTypes, GetStateTypes, SetActiveTimer, clearData, downloadReporte } from '../../redux/slices/dashboardSlice';
import { selectBreadcrumbs } from '../../redux/slices/generalSlice';
import { RefreshDataUser, selectUser } from "../../redux/slices/signInSlice";
import Colors from '../../theme/colors';
import Styles from './styles';
import { ViewItemDashboard } from '../../helpers/validators';
import { SetInformation } from '../../redux/slices/modalSlice';
import LoadingSpinner from '../LoadingSpinner';

const Routebar = () => {
    const dispatch = useDispatch();
    const style = Styles();
    const user = useSelector(selectUser).user;
    const [anchorEl, setAnchorEl] = useState(null);
    const [working, setWorking] = useState(false);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    let redirect = ""
    const viewPaylisps = user?.t_PERFIL == 'Empleado' || user?.t_PERFILES?.includes("Empleado");
    const breadcrumbs = useSelector(selectBreadcrumbs);
    const itemInformeCredito = soloEmpleado(user) || soloInterno(user) || esInternoEmpleado(user);

    const handleReporteCredito = () => {
        handleClose();
        setWorking(true);
        dispatch(downloadReporte(user.n_DOCUMENTO)).then((e) => {

            if (e.error)
                if (JSON.parse(new TextDecoder("utf-8").decode(e.payload.response.data)).message == "Código de error: 6550")
                    dispatch(SetInformation({
                        title: '¡Atención! Error de conexión',
                        description: JSON.parse(new TextDecoder("utf-8").decode(e.payload.response.data)).message
                    }))
                else
                    dispatch(SetInformation({
                        title: '¡Atención!',
                        description: JSON.parse(new TextDecoder("utf-8").decode(e.payload.response.data)).message
                    }))
            else
                dispatch(SetInformation({
                    title: '¡Reporte generado exitosamente!',
                    description: 'Reporte Cuenta Corriente del Beneficiario'
                }))
        }).catch((e) => {
            dispatch(SetInformation({
                title: '¡Atención! Error de conexión',
                description: 'Consultar con administrador'
            }))
        }).finally((e) => {
            setWorking(false);
        })
    }




    return (
        <>
            {working && <LoadingSpinner />}
            <div className={style.barRoute}>
                <Grid className={style.barRouteContainer} container spacing={2}>
                    <Grid item xs={8} style={{ display: 'flex', alignItems: 'center' }}>
                        <Link className={style.linkRoute} style={{ display: 'flex', alignItems: 'center' }} to={NavegarHome(user)} >
                            {
                                user.c_USER ?
                                    <Typography className={style.linkRoute} style={{ marginRight: '5px', color: Colors.blue }}>
                                        {user.c_USER} - {user.n_DOCUMENTO}
                                    </Typography> :
                                    <Typography className={style.linkRoute} style={{ color: Colors.blue }}>
                                        {user.n_DOCUMENTO}
                                    </Typography>
                            }

                        </Link>
                        <Typography className={style.linkRoute} style={{ marginLeft: '20px', marginRight: '10px' }}>
                            {">"}
                        </Typography>
                        <Link className={style.linkRoute} style={{ display: 'flex', alignItems: 'center' }} to={redirect}>
                            <Typography className={style.linkRoute} style={{ marginLeft: '10px', marginRight: '10px' }}>
                                {breadcrumbs}
                            </Typography>
                        </Link>
                    </Grid>
                    <Grid item xs={4} style={{ display: 'flex', alignItems: 'right' }}>
                        <div className={style.barContent}>
                            <IconButton
                                onClick={handleClick}
                                aria-controls={open ? user.c_USER : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}>
                                <Typography className={style.subtitle} style={{ color: Colors.black, marginRight: 10, fontSize: 12 }}>
                                    {user.c_USER}
                                </Typography>
                                <BsChevronDown id="idSvgLogout" className={style.subtitle} style={{ color: Colors.black, fontSize: 12 }} />
                            </IconButton>
                            <Menu
                                style={{ marginTop: '102px' }}
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={open}
                                onClose={handleClose}
                                PaperProps={{
                                    elevation: 0,
                                    
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >

                                <MenuList style={{ padding: 0 }}>
                                    <Divider style={{ marginBottom: 10 }} />
                                    <MenuItem id="idLiMiPerfil" style={{ height: '32px'}}> 
                                        <ListItemIcon>
                                            <BsPerson  size='20' />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Link onClick={handleClose} to='/profile' variant="body2" className={style.link} style={{ textDecoration: 'none', color: Colors.black, fontSize: 12 }}>Mi Perfil</Link>
                                        </ListItemText>
                                    </MenuItem>


                                    {ViewItemDashboard(user) &&
                                        <>

                                            <Divider style={{ marginTop: 10, marginBottom: 10 }} />

                                            <MenuItem id="idLiDashboard" style={{ height: '32px' }}>
                                                <ListItemIcon>
                                                    <BsFolder  size='20' />
                                                </ListItemIcon>
                                                <ListItemText>
                                                    <Link onClick={handleClose} to='/dashboard' variant="body2" className={style.link} style={{ textDecoration: 'none', color: Colors.black, fontSize: 12 }}>Dashboard</Link>
                                                </ListItemText>
                                            </MenuItem>
                                        </>
                                    }

                                    {
                                        !itemInformeCredito &&
                                        <>
                                            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                                            <MenuItem id="idInformeCredito" style={{ height: '32px' }}> 
                                                <ListItemIcon>
                                                    <BsFolder  size='20' />
                                                </ListItemIcon>
                                                <ListItemText>
                                                    <Typography onClick={handleReporteCredito} className={style.link} style={{ textDecoration: 'none', color: Colors.black, fontSize: 12 }}>Reporte Cuenta Corriente del Beneficiario</Typography>
                                                </ListItemText>
                                            </MenuItem>
                                        </>
                                    }

                                    {user.c_ROL_USUARIO == 'OSBA_PERSONA_ADMIN' &&
                                        <div>
                                            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                                            <MenuItem id="idLiAdministracionUsuario" style={{ height: '32px' }}>
                                                <ListItemIcon>
                                                    <BsPeople  size='20' />
                                                </ListItemIcon>
                                                <ListItemText>
                                                    <Link onClick={handleClose} to='/queryUsers' variant="body2" className={style.link} style={{ textDecoration: 'none', color: Colors.black, fontSize: 12 }}>Administración de Usuario</Link>
                                                </ListItemText>
                                            </MenuItem>
                                        </div>

                                    }

                                    {
                                        user.c_ROL_USUARIO == 'OSBA_PERSONA_RRHH' &&
                                        <div>
                                            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                                            <MenuItem style={{ height: '32px' }}>
                                                <ListItemIcon>
                                                    <BsGraphUp size='20' />
                                                </ListItemIcon>
                                                <ListItemText>
                                                    <Link onClick={handleClose} to='/dashboard/payslipsManager' variant="body2" className={style.link} style={{ textDecoration: 'none', color: Colors.black, fontSize: 12 }}>Recibos de Sueldo RR HH</Link>
                                                </ListItemText>
                                            </MenuItem>
                                        </div>
                                    }

                                    {
                                        viewPaylisps &&
                                        <div>
                                            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                                            <MenuItem style={{ height: '32px' }}>
                                                <ListItemIcon>
                                                    <BsGraphUp  size='20' />
                                                </ListItemIcon>
                                                <ListItemText>
                                                    <Link onClick={handleClose} to='/dashboard/payslips' variant="body2" className={style.link} style={{ textDecoration: 'none', color: Colors.black, fontSize: 12 }}>Recibos de Sueldo</Link>
                                                </ListItemText>
                                            </MenuItem>
                                        </div>
                                    }

                                    <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                                    <MenuItem id="idLiCerrarSesion" style={{ height: '32px' }}>
                                        <ListItemIcon>
                                            <BsBoxArrowRight  size='20' />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Link onClick={() => {
                                                localStorage.clear();
                                                dispatch(SetActiveTimer(true));
                                                dispatch(clearData());
                                            }}
                                                to='/login' variant="body2" className={style.link} style={{ textDecoration: 'none', color: Colors.black, fontSize: 12 }}>
                                                Cerrar Sesión
                                            </Link>
                                        </ListItemText>
                                    </MenuItem>
                                    <Divider style={{ marginTop: 10 }} />
                                </MenuList>
                            </Menu>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}

const Layout = () => {
    const style = Styles();
    const navigate = useNavigate();
    const user = useSelector(selectUser).user;

    const token = localStorage.getItem("token_app");
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    
    useEffect(() => {


        const d = async () => {


            if (token == null || token == undefined)
                navigate('/login')
            else {




                dispatch(RefreshDataUser()).then((e) => {
                    if (e.error)
                        navigate('/login')
                });

                dispatch(GetFolderTypes());
                dispatch(GetStateTypes());
                dispatch(GetDocTypes());
                dispatch(GetSectorTypes());
            }
        }
        d();
    }, [token, pathname])

    const onClickLogo = () => {
        navigate('/');
    }

    return (
        <>
            {
                user != null &&
                <div className={style.fullbar}>
                    <div className={style.bar}>
                        <Grid className={style.barContainer} container spacing={2}>
                            <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
                                <div className={style.logo} onClick={onClickLogo} />
                            </Grid>
                        </Grid>
                    </div>
                    <Routebar />
                    <Grid container component="main" className={style.root}>
                        <Grid item className={style.paper}>
                            <Outlet />
                        </Grid>
                    </Grid>

                    <footer>
                        <Box >
                            <Typography
                                color="primary"
                                align="center"
                                style={{ padding: "10px" }}
                            >
                                Soporte ObSBA: asis.soporte@obsba.org.ar
                            </Typography>
                            <Typography color="primary" align="center">
                                Teléfono: 0800-348-1014
                            </Typography>
                        </Box>
                    </footer>

                </div>

            }
        </>
    );
}

export default Layout;