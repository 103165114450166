import { Button, Grid, IconButton, InputAdornment, TextField } from "@material-ui/core";
import InputTextField from "../../../components/Forms/input";
import { Controller, useForm } from "react-hook-form";
import { FormatCuil, ValidateCuit, ValidatePassword, ValidatePhone } from "../../../helpers/validators";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useState } from "react";
import { useCreateExternalUserMutation } from "../../../api/Usuarios_Portal";
import LoadingSpinner from "../../../components/LoadingSpinner";
import styles from '../styles'
import { useDispatch } from "react-redux";
import { SetInformation } from "../../../redux/slices/modalSlice";
import PropTypes from 'prop-types';

const RegisterNoInterno = (props) => {
  const dispatch = useDispatch();
  const classes = styles();

  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassowrd = () => setShowPassword(!showPassword);

  const [createExternal, { isLoading }] = useCreateExternalUserMutation();

  const { handleSubmit, control, formState: { errors }, watch } = useForm({
    mode: "onChange",
    defaultValues: {
      sigafUser: "",
      fullName: "",
      email: "",
      phone: "",
      street: "",
      password: "",
      cuitCuil: "",
    }
  });
  const onSubmit = async(data) => {
    data.profileType = props.profile;
    data.cuitCuil = data.cuitCuil.replace(/-/g, '');
    await createExternal(data).unwrap().then((e)=>{
      window.location.href = '/register/confirmation';
    }).catch((e)=>{
      dispatch(SetInformation({
        title:'Error',
        description: e.data
      }))
    })
  }

  RegisterNoInterno.propTypes = {
    profile: PropTypes.string,
  };

  return (
    <>
      { isLoading && <LoadingSpinner />}
      <form
        className={classes.form}
        style={{ width: "60%" }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container style={{ marginTop: "10px" }}>
          <Grid item container spacing={2} direction="row" style={{ marginTop: 5 }}>
            <Grid item xs={12} sm={12} md={6}>
              <InputTextField
                required
                variant="outlined"
                margin="normal"
                id="idInpNombreRazon"
                name="fullName"
                label="Nombre y apellido / Razon social"
                fullWidth
                control={control}
                style={{ marginTop: "0" }}
                rules={{
                  required: "Campo obligatorio"
                }}
                InputLabelProps={{ shrink: true }}
                error={!!errors.fullName}
                helperText={errors.fullName?.message}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Controller
                name="cuitCuil"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio"
                  },
                  validate: (value) => {
                    return ValidateCuit(value) ? null : "CUIT/CUIL inválido";
                  }
                }}
                render={(field) => (
                  <TextField
                    required
                    label="CUIT/CUIL"
                    id="idInpCUIT"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      maxLength: 13,
                      type: 'text',
                    }}
                    value={FormatCuil(field.value.toString())}
                    error={!!errors.cuitCuil}
                    helperText={errors.cuitCuil?.message}
                    onChange={(e) => {
                      let value = e.target.value.replace(/[^\d]/g, '');
                      field.onChange(FormatCuil(value.toString()));
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={2} direction="row">
            <Grid item xs={12} sm={12} md={6}>
              <InputTextField
                required
                variant="outlined"
                margin="normal"
                id="idInpEmail"
                name="email"
                label="Correo electrónico"
                fullWidth
                control={control}
                InputLabelProps={{ shrink: true }}
                rules={{
                  required: "Campo obligatorio",
                  pattern: {
                    value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
                    message: "Correo electrónico inválido"
                  }
                }}
                error={!!errors.email}
                helperText={errors.email?.message}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Controller
                name="emailConfirmation"
                control={control}
                rules={{
                  required: 'Campo obligatorio',
                  validate: (value) => {
                    return value == watch("email","") ? null : "Los emails no coinciden";
                  }
                }}
                render={(field) => (
                  <TextField
                    required
                    label="Repita correo electrónico"
                    id="idInpEmailRep"
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    onPaste={(e) => {
                      e.preventDefault();
                    }}
                    inputProps={{ autoComplete: "off" }}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    error={!!errors.emailConfirmation}
                    helperText={errors.emailConfirmation?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={2} direction="row">
            <Grid item xs={12} sm={12} md={6}>
              <Controller
                name="phone"
                control={control}
                rules={{
                  required: "Campo obligatorio",
                  validate: (value) => {
                    return ValidatePhone(value) ? null : "Telefono inválido";
                  }
                }}
                render={(field) => (
                  <TextField
                    required
                    label="Teléfono"
                    id="idInpTelefono"
                    type="number"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    error={!!errors.phone}
                    helperText={errors.phone?.message}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <InputTextField
                required
                variant="outlined"
                margin="normal"
                id="idInpDomicilio"
                name="street"
                label="Domicilio legal"
                fullWidth
                control={control}
                style={{ marginTop: "0" }}
                rules={{
                  required: "Campo obligatorio"
                }}
                InputLabelProps={{ shrink: true }}
                error={!!errors.street}
                helperText={errors.street?.message}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={2} direction="row">
            <Grid item xs={12} sm={12} md={6}>
              <Controller
                name="password"
                control={control}
                rules={{
                  required: 'Campo obligatorio',
                  validate: (value) => {
                    return ValidatePassword(value) ? null : "La contraseña debe ser alfanumérica y tener al menos 8 dígitos y una mayúscula";
                  }
                }}
                render={(field) => (
                  <TextField
                    required
                    label="Nueva contraseña"
                    id="idInpPassword"
                    name="password"
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    type={showPassword ? "text" : "password"}
                    InputLabelProps={{ shrink: true }}
                    onPaste={(e) => {
                      e.preventDefault();
                    }}
                    InputProps={{
                      autoComplete: "off",
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton aria-label="toggle password visibility" onClick={handleShowPassowrd} >
                            {showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    error={!!errors.password}
                    helperText={errors.password?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Controller
                name="repeatPassword"
                control={control}
                rules={{
                  required: 'Campo obligatorio',
                  validate: (value) => {
                    return watch("password","") == value ? null : "Las contraseñas no coinciden";
                  }
                }}
                render={(field) => (
                  <TextField
                    required
                    variant="outlined"
                    margin="normal"
                    label="Repita contraseña"
                    id="idInpRepeatPassword"
                    type={showPassword ? "text" : "password"}
                    fullWidth
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    InputLabelProps={{ shrink: true }}
                    onPaste={(e) => {
                      e.preventDefault();
                    }}
                    InputProps={{
                      autoComplete: "off",
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleShowPassowrd}
                          >
                            {showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.repeatPassword}
                    helperText={errors.repeatPassword?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} justifyContent="center">
            <Button
              id="idBtnRegistrar"
              type="submit"
              variant="outlined"
              color="primary"
              className={classes.button}
              fullWidth
              required
            >
              Registrar
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

export default RegisterNoInterno;