import { Grid, IconButton, Tooltip } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState } from "react";
import { BsDownload, BsFileEarmarkExcel, BsFileEarmarkPdf, BsTrash } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useDeleteFileMutation, useGetFilesQuery } from "../../../api/Files";
import { folderAPI } from "../../../api/Folders";
import LoadingSpinner from "../../../components/LoadingSpinner";
import CustomFooter from "../../../components/Table/CustomFooter";
import { esInterno } from "../../../helpers/UsuarioHelper";
import { downloadFile } from "../../../redux/slices/dashboardSlice";
import Colors from "../../../theme/colors";
import CustomPagination from "../../../components/Table/CustomPagination";
import { SetInformation } from "../../../redux/slices/modalSlice";
import { commonAPI } from "../../../api/Common";

let optionsDate = { year: "numeric", month: "short", day: "numeric" };
let optionsHour = { hour: "2-digit", minute: "2-digit" };

const FileTable = (props) => {
  const dispatch = useDispatch();
  const [deleteFile, { isLoading: deleteLoading }] = useDeleteFileMutation();
  const [paginacion, setPaginacion] = useState({
    page: 0,
    pageSize: 5
  });
  const user = useSelector((state) => state.user.user);

  const folderTypeStatus = useSelector(commonAPI.endpoints.getTipoCarpeta.select()).status;
  const folderStateStatus = useSelector(commonAPI.endpoints.getEstadosCarpeta.select()).status
  
  const render = folderTypeStatus === 'fulfilled' && folderStateStatus === 'fulfilled' 

  const { data, isLoading } = useGetFilesQuery({
    yearid: props.folderId.split(" ")[1],
    idfolder: props.folderId.split(" ")[0],
    pageNumber: paginacion.page + 1,
    pageSize: paginacion.pageSize
  });

  const columnsGrid = [
    {
      field: "icon",
      headerName: "",
      type: "actions",
      renderCell: (params) => {
        let extension = params.row.rutaFile?.split(".");
        let ext = extension[extension.length - 1].toUpperCase();
        return (
          <Grid>
            <Tooltip
              title={
                <label
                  style={{
                    color: "white",
                    fontSize: "12px",
                    fontWeight: "300",
                  }}
                >
                  {params.row.tipoDocumento}
                </label>
              }
            >
              {ext == "PDF" ? (
                <BsFileEarmarkPdf size={20} color={Colors.red} />
              ) : (
                <BsFileEarmarkExcel size={20} color={Colors.darkGreen} />
              )}
            </Tooltip>
          </Grid>
        );
      }
    },
    { field: "tipoDocumento", headerName: "Tipo", flex: 1, minWidth: 100 },
    { field: "titulo", headerName: "Título", flex: 1, minWidth: 150 },
    { field: "descripcion", headerName: "Observaciones", flex: 1, minWidth: 150 },
    {
      field: "initMember",
      headerName: "Iniciador",
      flex: 1, minWidth: 150
    },
    {
      field: "actualizationDate",
      headerName: "Última Actualización",
      flex: 1, minWidth: 150,
      renderCell: (params) => {
        return (
          new Date(params.row.creation).toLocaleDateString(
            "es-AR",
            optionsDate
          ) +
          ", " +
          new Date(params.row.creation).toLocaleTimeString("es-AR", optionsHour)
        );
      },
    },
    {
      field: "rutaFile",
      headerName: "Archivo",
      flex: 1
    },
    {
      field: "visibilidad",
      headerName: "Visibilidad",
      flex: 1, minWidth: 100,
      renderCell: (params) => {
        let visibility = params.row.visibilidad == null ? '' : params.row.visibilidad;
        switch (visibility) {
          case 'P': visibility = "Publica"; break;
          case 'R': visibility = "Privada"; break;
        }
        return visibility;
      },
    },
    {
      field: "size",
      headerName: "Tamaño",
      flex: 1,
      renderCell: (params) => {
        let tamanio = parseFloat(params.row.tamanio) / 1024;
        return `${tamanio.toFixed(2)} KB`;
      },
    },
    {
      field: "actions",
      headerName: "Acciones",
      flex: 1,
      type: "actions",
      renderCell: (params) => {       
        let extension = params.row.rutaFile?.split(".");
        let extNom = extension[extension.length - 1];
        let primerSpliteo = params.row?.rutaFile.split(`.${extNom}`);
        let segundoSplit = primerSpliteo[0]?.split("/");
        const file =
          segundoSplit[segundoSplit.length - 1].replace(
            `-${params.row.id}`,
            ""
          ) +
          "." +
          extNom;

        const deleteButton = (
          params.row.puedeEliminar == 'S' ?
            <Tooltip
              title={
                <label
                  style={{ color: "white", fontSize: "12px", fontWeight: "300" }}
                >
                  Eliminar {file}
                </label>
              }
            >
              <IconButton onClick={() => onDelete(params.row.id)}>
                <BsTrash size={20} />
              </IconButton>
            </Tooltip>
            : ""
        );

        const downloadButton = (
          <Tooltip
            title={
              <label
                style={{ color: "white", fontSize: "12px", fontWeight: "300" }}
              >
                Descargar {file}
              </label>
            }
          >
            <IconButton
              onClick={() => onDownload(params.row.id, file)}
            >
              <BsDownload size={20} />
            </IconButton>
          </Tooltip>
        );

        return (
          <> {params.row.mostrarAcciones == "S" && downloadButton } {params.row.mostrarAcciones == "S" && deleteButton}</>
        );
      },
    },
  ];

  const onDelete = async (id) => {
    await deleteFile(id).unwrap().then((e) => {
      dispatch(folderAPI.util.invalidateTags(['folder.get']))
    });
  }

  function onDownload(url, file) {
    let d = { url: url, file: file };
    dispatch(downloadFile(d)).then((e) => {
      if (e.error)
        dispatch(SetInformation({
          title: '¡Atención!',
          description: 'El documento solicitado no está disponible en este momento. Por favor, intenta nuevamente más tarde. Si persiste el problema, contacta con el soporte técnico'
        }))
    }).catch((e) => {

    })

  }

  return (
    <>
      {!render || isLoading || (deleteLoading && <LoadingSpinner />)}
      {render && (
        <DataGrid
          getRowHeight={() => "auto"}
          autoHeight={true}
          rows={
            esInterno(user)
              ? data?.data || []
              : data?.data.filter((e) => e.visibilidad == "P") || []
          }
          getRowId={(row) => row?.id + row?.anioCarpeta}
          columns={columnsGrid}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          disableSelectionOnClick
          localeText={{
            footerRowSelected: CustomPagination,
            noRowsLabel: "Sin datos",
            noResultsOverlayLabel: "Sin resultados",
          }}
          components={{
            Footer: CustomFooter,
          }}
          paginationMode="server"
          paginationModel={paginacion}
          onPaginationModelChange={setPaginacion}
          rowCount={data?.recordsCount || 0}
          columnVisibilityModel={{
            visibilidad: esInterno(user),
          }}
        />
      )}
    </>
  );
}

export default FileTable;