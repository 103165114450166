import React, {
  useState,
  useEffect,
  useCallback,
  useRef
} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { Divider, Typography, Backdrop, Fade, Box, Modal} from "@material-ui/core";

import { ShowErrorPage } from "./errorPage";
import {modalStyles} from "../styles";
import Colors from "../../theme/colors";

import moment from 'moment';


export function ErrorPage() {
  const { code } = useParams();

  let msg = code === '0' ? 'No posée permisos para ingresar a esta sección' : 'Ante cualquier inconveniente comunicarse con los canales de soporte habilitados';
  
  return (
      <div>
          <ShowErrorPage msg={msg}/>        
      </div>
  );
}

export function IdleTimer() {
  const [events] = useState(['click', 'load', 'scroll']);
  const [isOpen, setOpen] = useState(false);

  const isAuthenticated = useSelector(state => state.user.success);
  const navigate = useNavigate();
      
  let warningInactiveInterval = useRef();
  let startTimerInterval = useRef();
  
  let timeStamp;

  let timeChecker = () => {
      startTimerInterval.current = setTimeout(() => {
          let storedTimeStamp = sessionStorage.getItem('lastTimeStamp');
          warningInactive(storedTimeStamp);
      }, 60000);
  };

  let warningInactive = (timeString) => {
      clearTimeout(startTimerInterval.current);

          warningInactiveInterval.current = setInterval(() => {
              const maxTime = 14;

              const diff = moment.duration(moment().diff(moment(timeString)));
              const minPast = diff.minutes();

              if (minPast === maxTime) {
                  clearInterval(warningInactiveInterval.current);
                  setOpen(true);
                  sessionStorage.removeItem('lastTimeStamp');
              }
          }, 30000);

      };

      let resetTimer = useCallback(() => {
          clearTimeout(startTimerInterval.current);
          clearInterval(warningInactiveInterval.current);
  
          if (isAuthenticated) {
              timeStamp = moment();
              sessionStorage.setItem('lastTimeStamp', timeStamp);
          } else {
              clearInterval(warningInactiveInterval.current);
              sessionStorage.removeItem('lastTimeStamp');
          }
          timeChecker();
          setOpen(false);
      }, [isAuthenticated]);

      useEffect(() => {
          events.forEach((event) => {
              window.addEventListener(event, resetTimer);
          });
  
          timeChecker();
  
          return () => {
              clearTimeout(startTimerInterval.current);
          };
      }, [resetTimer, events, timeChecker]);

      const handleClose = () => {
          setOpen(false);
          resetTimer();
          navigate("/")
          window.location.reload(true);
      };

  return (
      <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={isOpen}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                  timeout: 500,
              }}
              onClick={() => handleClose()}
          >
              <Fade in={isOpen}>
                  <Box sx={modalStyles}>
                      <Typography id="transition-modal-title" variant="h6" component="h2" style={{ marginBottom: '5px', fontSize: '20px' }}>
                          Ha excedido el tiempo de inactividad en la plataforma.
                      </Typography>
                      <Divider style={{ marginBottom: '15px', backgroundColor: Colors.blue }} />
                      <Typography id="transition-modal-description" sx={{ mt: 2 }} style={{ fontSize: '15px', fontWeight: '300' }}>
                          Vuelva a iniciar sesión
                      </Typography>
                  </Box>
              </Fade>
          </Modal>
  );
}