import {
  Grid,
  Typography
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styles from "../styles";
import { useLocation } from "react-router-dom";

const MantenimientoImg = require('../../../static/mantenimiento.png');

const Maintenance = () => {
  const classes = styles();
  const location = useLocation();

  const [error, setError] = useState();

  useEffect(() => {
    if (location.state.error != null)
      setError(location.state.error)
  }, [location])

  return (
    <Grid container>
      <Grid container item className={classes.fondoNavbar}>
        <Grid container item xs={12} alignContent="center">
          <div className={classes.logo2} />
        </Grid>
      </Grid>

      <Grid container justifyContent="center" alignContent="center" style={{ marginTop: 100 }}>
        <Grid item xs={12}>
          <div className={classes.paper2}>
            <Typography className={classes.textErrorBox2} >
              Portal fuera de servicio.
            </Typography>


            <Typography
              className={classes.label}
              style={{ textAlign: "center", marginTop: "5px" }}
            >
              <img src={MantenimientoImg} className={classes.logo} />
              <Typography className={classes.subtitle2}>
                Estaremos de vuelta pronto.
              </Typography>
              {error &&
                <Typography >
                  Código interno: {error}
                </Typography>
              }
              <Typography style={{ fontWeight: "bold" }}>
                0800-348-1014{" "}
              </Typography>
              <Typography
                variant="caption"
                color="primary"
                align="center"
                style={{ fontWeight: "bold" }}
              >
                {" "}
                Soporte ObSBA:{" "}
              </Typography>{" "}
              <span style={{ color: "#000000" }}>
                asis.soporte@obsba.org.ar
              </span>
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Grid >
  );

}

export default Maintenance;