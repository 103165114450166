import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { ErrorPage } from "./components/ErrorPage/index";
import RegisterComplete from "./pages/public/RegisterComplete";

/* Nuevas Pantallas */
/* Privadas */
import EmployeePayslips from "./pages/private/EmployeePayslips";
import FileDashboard from "./pages/private/FileDashboard";
import FolderFilter from "./pages/private/FolderFilter";
import { ManageUsers } from "./pages/private/UsersFilter/ManagerUsers";
import MyProfile from "./pages/private/MyProfile";
import PreviewFileFolder from "./pages/private/PreviewFileFolder";
import { QueryUsers } from "./pages/private/UsersFilter/index";
import { UserManageUser } from "./pages/private/UsersFilter/UserManageUser";


/* Publicas */
import ForgotPassword from "./pages/public/ForgotPassword";
import NewPassword from "./pages/public/NewPassword";
import NewPasswordConfirmation from "./pages/public/NewPasswordConfirmation";
import RegisterAccount from "./pages/public/RegisterAccount";
import RegisterConfirmation from "./pages/public/RegisterConfirmation";
import Login from "./pages/public/login";
/* Layout */
import Layout from "./components/Layout";
import RecoverPassword from "./pages/public/RecoverPassword";

import { appRoutes } from "./settings";
import Maintenance from "./pages/public/Maintenance";
import CreateFolder from "./pages/private/CreateFolder";
import { PayslipsPreview } from "./pages/private/EmployeePayslips/PayslipsPreview";

function App() {
  return (
    <Routes>
      <Route path="*" element={<Navigate to="/error" />} />
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<Navigate to='login' replace />} />
        <Route path="/dashboard" element={<FolderFilter />} />
        <Route path="/dashboard/preview/:folderId" element={<PreviewFileFolder />} />
        <Route path="/dashboard/folder/:folderId" element={<FileDashboard />} />
        <Route path="/dashboard/createfolder" element={<CreateFolder />} />
        <Route path="/dashboard/payslips" element={<EmployeePayslips />} />
        <Route path="/dashboard/payslipsmanager" element={<EmployeePayslips />} />
        <Route path="/dashboard/payslips/preview" element={ <PayslipsPreview />  }/>
        <Route path="/profile" element={<MyProfile />} />
        <Route path="/queryUsers" element={<QueryUsers />} />
        <Route path="/manageUser" element={<ManageUsers />} />
        <Route path="/manageUser/:n_documento" element={<UserManageUser />} />
      </Route>

      <Route path={appRoutes.public.login} element={<Login />} />
      <Route path={appRoutes.public.register} element={<RegisterAccount />} />
      <Route path="/register/confirmation" element={<RegisterConfirmation />} />
      <Route path="/register/complete/:cuit/:token" element={<RegisterComplete />} />
      <Route path="/forgotPassword" element={<ForgotPassword />} />
      <Route path="/newPassword/:id" element={<NewPassword />} />
      <Route path="/recoverPassword/:id" element={<RecoverPassword />} />
      <Route path="/recoverPasswordConfirmation" element={<NewPasswordConfirmation />} />
      <Route path="/error" element={<ErrorPage />} />
      <Route path="/error/:code" element={<ErrorPage />} />
      <Route path={appRoutes.public.maintenance} element={<Maintenance />} />
      
    </Routes>
  );
}
export default App;