import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../api";

// -----------------------------------------------------------
// ----------------------- RECOVERY --------------------------
// -----------------------------------------------------------

export const cuilExist = createAsyncThunk("/recovery/cuilexist", async (data, dispatch) => {
  try {
    const response = await api.get("/Usuario_Portal/cuilExist?cuil=" + data);
    dispatch.dispatch(cuitSuccess(response));
    return response.data;
  } catch (e) {
    return dispatch.rejectWithValue(e);
  }
});

export const requestToken = createAsyncThunk("/recovery/requestToken", async (data, dispatch) => {
  try {
    const response = await api.put("/usuario_portal/RequestChangePassword?idPersona=" + data.username, {
      idPersona: data.username,
    });

    dispatch.dispatch(cuitSendedSuccess(response));
  } 
  catch (e) 
  {
    return dispatch.rejectWithValue(e);
  }
});

export const validateSendedToken = createAsyncThunk("/recovery/validateToken", async (data, dispatch) => {
  try {

    const response = await api.put("/usuario_portal/validateSendToken?idPersona=" + data.username + "&token=" + data.token, {
      idPersona: data.username,
      token: data.token
    });

    return response

  } 
  catch (e) 
  {
    return dispatch.rejectWithValue(e);
  }
});

export const ChangePassUser = createAsyncThunk("/recovery/changePassword", async (data, dispatch) => {
  try {
    const response = await api.put("/usuario_portal/ChangePassUser?idPersona=" + data.username + "&newPass=" + data.password, {
      idPersona: data.username,
      newPass: data.password
    });

    dispatch.dispatch(passwordSuccess(response));
  } 
  catch (e) 
  {
    return dispatch.rejectWithValue(e);
  }
});

// -----------------------------------------------------------
// ------------------------- SLICE ---------------------------
// -----------------------------------------------------------

const initialState = {
  loading: false,
  exist: false,
  cuitSended: null,
  token: null,
  password: null,
  error: null,
  success: false,
};

const changePasswordSlice = createSlice({
  name: "recovery",
  initialState,
  reducers: {
    cuitSuccess: (state, action) => {
      state.exist = action.payload.data;
    },
    cuitSendedSuccess: (state, action) => {
      state.cuitSended = action.payload.data;
    },
    tokenSuccess: (state, action) => {
      state.token = action.payload.data;
    },
    passwordSuccess: (state, action) => {
      state.password = action.payload.data;
    },
    
  },
  extraReducers: {
      [cuilExist.pending]: (state) => {
        state.loading = true;
        state.error = null;
      },
      [cuilExist.fulfilled]: (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
      },
      [cuilExist.rejected]: (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload;
      },
      [validateSendedToken.pending]: (state) => {
        state.loading = true;
      },
      [validateSendedToken.fulfilled]: (state, action) => {
        state.loading = false;
        state.token = action.payload;
        state.success = true;
      },
      [validateSendedToken.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload.response.data;
      },
      [ChangePassUser.pending]: (state) => {
        state.loading = true;
        state.error = null;
      },
      [ChangePassUser.fulfilled]: (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
      },
      [ChangePassUser.rejected]: (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload;
      },
  },
});

const { cuitSuccess, cuitSendedSuccess, tokenSuccess, passwordSuccess } = changePasswordSlice.actions;

export const selectRecovery = (state) => state.recovery;

export default changePasswordSlice.reducer;
