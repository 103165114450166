import { Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {
  DataGrid
} from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { BsDownload, BsFileEarmarkPdf } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LoadingSpinner from "../../../components/LoadingSpinner";
import CustomFooter from "../../../components/Table/CustomFooter";
import CustomPagination from "../../../components/Table/CustomPagination";
import { CurrentDate, meses } from "../../../helpers/validators";
import { DownloadPayslips, GetPayslips, SetFilterPayslips } from "../../../redux/slices/dashboardSlice";
import { SetInformation } from "../../../redux/slices/modalSlice";
import Colors from "../../../theme/colors";

const PayslipsTableAdmin = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.user);
  const statusPayslips = useSelector((state) => state.dashboard.payslips.loading);
  const downloadPayslips = useSelector((state) => state.dashboard.downloadPayslips.loading);
  const dataFilter = useSelector((state) => state.dashboard.filterPayslips);
  
  const [row, setRow] = useState([]);
  const [total, setTotal] = useState(useSelector((state) => state.dashboard.payslips.data.cantidadTotal))
  const [paginacion, setPaginacion] = useState({ page: 0, pageSize: 5 })

  function onDownloadPaycheck(file, urlStorage) {
    let d = { filePath: urlStorage, fileName: file.match(/\/([^/]+)$/)[1] };
    dispatch(DownloadPayslips(d)).then((resp) => {
      if (resp.error != null && resp.error.message === "Rejected")
        dispatch(SetInformation(
          {
            title: '¡ERROR!',
            description: "Archivo no disponible"
          }
        ))
      else
        dispatch(SetInformation(
          {
            title: '¡Exito!',
            description: "Archivo descargado"
          }
        ));
    });
  }

  const payslipResponse = (e) => {
    const isEmptyResults = e.payload.cantidadTotal === 0;
    const hasError = e?.error;
    
    if (isEmptyResults) {
      setModal('¡Atención!', 'No hay recibos para el periodo consultado.');
    } else if (hasError) {
      setModal('Error!', 'Error inesperado en servicio de recibos de sueldo.');
    }

    setRow(e.payload.listaResultado || []);
    setTotal(e.payload.cantidadTotal);
  };

  const setModal = (title, message) => {
    dispatch(SetInformation(
      {
        title: title,
        description: message
      }
    ))
  };

  const fetchData = (requestData, clear) => {
    dispatch(GetPayslips(requestData)).then((response) => {
      if (clear) {
        const payload = response.payload;
        setRow(payload.listaResultado ? payload.listaResultado : []);
        setTotal(payload.cantidadTotal);
      } else {
        payslipResponse(response);
      }
    });
  };

  useEffect(() => {
    const dataFilter = {
      cuit: user.n_DOCUMENTO.toString(),
      fromMonth: (CurrentDate.toDate().getMonth() + 1).toString().padStart(2, "0"),
      fromYear: CurrentDate.toDate().getFullYear(),
      toMonth: (CurrentDate.toDate().getMonth() + 1).toString().padStart(2, "0"),
      toYear: CurrentDate.toDate().getFullYear(),
      consultar: false,
    };
    dispatch(GetPayslips({ ...dataFilter, pageNumber: 0, pageSize: 5 })).then(e => {
      dispatch(SetFilterPayslips({ ...dataFilter, pageNumber: 0, pageSize: 5 }));
      setRow(e.payload.listaResultado?e.payload.listaResultado:[]);
      setTotal(e.payload.cantidadTotal)
    })
  }, []);

  useEffect(() => {
    if (dataFilter && (dataFilter.consultar || dataFilter.limpiar)) {
      const requestData = {
        cuit: dataFilter.cuit,
        fromMonth: dataFilter.fromMonth,
        fromYear: dataFilter.fromYear,
        toMonth: dataFilter.toMonth,
        toYear: dataFilter.toYear,
        pageNumber: paginacion.page,
        pageSize: paginacion.pageSize,
      };
      if (dataFilter.consultar) {
        fetchData(requestData, false);
      } else if (dataFilter.limpiar) {
        fetchData(requestData, true);
      }
    }
  }, [paginacion, dataFilter]);

  return (
    <>
      <div style={{ minHeight: '100%', width: "100%" }}>

        <DataGrid
          getRowHeight={() => 'auto'}
          autoHeight={true}
          rows={row}
          getRowId={(row) => row?.id + row?.idRecibo}
          columns={[
            { field: "tipo", headerName: "Tipo", flex: 1, minWidth: 100 },
            {
              field: "periodoMes",
              headerName: "Mes",
              flex: 1, minWidth: 150,
              renderCell: (params) => {
                return meses[params.row.periodoMes - 1];
              },
            },
            {
              field: "periodoAnio",
              headerName: "Año",
              flex: 1,
              minWidth: 150,
              renderCell: (params) => {
                const periodoAnio = params.row.periodoAnio;
                const formattedAnio = periodoAnio < 100 ? `20${periodoAnio}` : `${periodoAnio}`;
                return formattedAnio;
              },
              sortComparator: (v1, v2) => {
                const parseAndAdjust = (val) => (val < 100 ? 2000 + val : val);
                const parsedVal1 = parseAndAdjust(parseInt(v1, 10));
                const parsedVal2 = parseAndAdjust(parseInt(v2, 10));

                return parsedVal1 - parsedVal2;
              },
            },
            {
              field: "file",
              headerName: "Archivo",
              flex: 1, minWidth: 150,
              renderCell: (params) => {
                let split = params.row.urlstorage?.split("/");
                if (split != null) return split[split.length - 1];
              },
            },
            {
              field: "icon",
              headerName: "Ext",
              flex: 1,
              type: "actions",
              renderCell: (params) => (
                <Grid>
                  <Tooltip
                    title={
                      <label
                        style={{ color: "white", fontSize: "12px", fontWeight: "300" }}
                      >
                        {params.row.fileType}
                      </label>
                    }
                  >
                    <IconButton disabled>
                      <BsFileEarmarkPdf size={20} color={Colors.red} />
                    </IconButton>
                  </Tooltip>
                </Grid>
              ),
            },
            {
              field: "descargar",
              headerName: "Descargar",
              flex: 1,
              type: "actions",
              renderCell: (params) => (
                <Grid>
                  <Tooltip
                    title={
                      <Typography
                        style={{ color: "white", fontSize: "12px", fontWeight: "300" }}
                      >
                        Descargar
                      </Typography>
                    }
                  >
                    <IconButton
                      onClick={() =>
                        onDownloadPaycheck(
                          `${params.row.urlstorage}`,
                          `${params.row.urlstorage}`
                        )
                      }
                    >
                      <BsDownload size={20} />
                    </IconButton>
                  </Tooltip>
                </Grid>
              ),
            },
            {
              field: "ver",
              headerName: "Ver",
              flex: 1,
              type: "actions",
              renderCell: (params) => (
                <Grid>
                  <Tooltip
                    title={
                      <Typography
                        style={{ color: "white", fontSize: "12px", fontWeight: "300" }}
                      >
                        Previsualización
                      </Typography>
                    }
                  >
                    <IconButton component={Link} to={`/dashboard/payslips/preview`}>
                      <VisibilityIcon size={20} />
                    </IconButton>
                  </Tooltip>
                </Grid>
              ),
            },
          ]}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          disableSelectionOnClick
          localeText={{
            footerRowSelected: CustomPagination,
            noRowsLabel: 'Sin datos',
            noResultsOverlayLabel: 'Sin resultados'

          }}
          slots={{
            footer: CustomFooter,
          }}
          paginationMode="server"
          paginationModel={paginacion}
          onPaginationModelChange={setPaginacion}
          rowCount={total}
        />
      </div>

      {statusPayslips == "pending" || downloadPayslips == "pending" ? (
        <LoadingSpinner />
      ) : (
        <></>
      )}
    </>
  );
}

export default PayslipsTableAdmin;