import { makeStyles } from "@material-ui/styles";
import Colors from "../../theme/colors";

export default makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '0px',
        minWidth: '1070px'
    },
    paper: {
        width: '95%',
        minHeight: '800px',
    },
    form: {
        padding: '8px',
    },
    checkBox: {
        color: '#325AA5'
    },

    prueba1: {
        borderColor: 'red !important'
    },
    prueba2: {
        fontSize: '10px'
    },
    textField: {
        '& label.Mui-focused': {
            color: Colors.blue,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: Colors.blue,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: Colors.grey,
            },
            '&:hover fieldset': {
                borderColor: Colors.blue,
            },
            '&.Mui-focused fieldset': {
                borderColor: Colors.blue,
            },
        },
        '& .MuiInputBase-root.Mui-disabled': {
            color: Colors.darkGrey,
        }
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: Colors.blue,
        borderColor: Colors.blue,
        color: Colors.white,
        maxHeight: '50px',
        minHeight: '50px',
        border: '2px solid',
        '&:hover': {
            backgroundColor: Colors.hoverblue,
        },
    },
    button: {
        backgroundColor: Colors.white,
        borderColor: Colors.blue,
        color: Colors.blue,
        maxHeight: '5opx',
        minHeight: '5opx',
        '&:hover': {
            backgroundColor: Colors.hoverblue,
        },
    },
    buttonVolver: {
        margin: 0,
        backgroundColor: Colors.white,
        border: '2px solid',
        borderColor: Colors.blue,
        color: Colors.blue,
        maxHeight: '50px',
        minHeight: '50px'
    },
    buttonGuardar:{
        margin: 0,
        backgroundColor: Colors.blue,
        border: '2px solid',
        borderColor: Colors.blue,
        color: Colors.white,
        maxHeight: '50px',
        minHeight: '50px',
        '&:hover': {
            backgroundColor: Colors.blue,
        },
    },
    header: {
        fontWeight: '450',
        fontSize: '15px',
        padding: "0 !important",
        height: '40px !important'
    },
    column: {
        width: "max-content",
        fontWeight: 300,
        fontSize: '15px',
        padding: '0 !important',
        height: '20px !important'
    },
    row: {
        '&:last-child td, &:last-child th': { border: 0 },
        height: '20px !important'
    },
    cell: {
        padding: "0px 0px"
    },
    canvas_container: {
        width: "100%",
        overflow: "auto",
        background: "#333",
        align: "center",
        border: "solid 3px"
    },
    visibility_internal: {
        visibility: "visible"
    },
    visibility_nointernal: {
        visibility: "hidden"
    },
}));
