import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectUser } from "../../../redux/slices/signInSlice";
import Styles from "../styles";
import Colors from "../../../theme/colors";
import { Typography, Box, IconButton, List, ListItem, ListItemIcon, ListItemText, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import {
  BsFileEarmarkPdf,
  BsDownload,
  BsFileEarmarkExcel,
  BsFillArrowLeftCircleFill,
  BsFillArrowRightCircleFill,
} from "react-icons/bs";
import { read, utils } from "xlsx";
import { GetFilesPreviewByFolder, downloadFile, previewFile } from "../../../redux/slices/dashboardSlice";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { unwrapResult } from "@reduxjs/toolkit";
import { breadcrumbs } from "../../../settings";
import { setBreadcrumbs } from "../../../redux/slices/generalSlice";
import { SetInformation } from "../../../redux/slices/modalSlice";

const PreviewFileFolder = () => {
  const dispatch = useDispatch();
  const style = Styles();
  dispatch(setBreadcrumbs(breadcrumbs.principal));
  const [rows, setRows] = useState([]);
  //
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pdfFile, setPdfFile] = useState(null);
  const [activeSheetIndex, setActiveSheetIndex] = useState(0);
  const [sheets, setSheets] = useState([]);
  //
  const [dataa, setData] = useState([]);
  const [preview, setPreview] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [extension, setExtension] = useState("");

  const { folderId } = useParams();

  const user = useSelector(selectUser).user;
  const isInternalUser = user.t_PERFIL.trim() === "Interno" || (user.t_PERFILES != null && user.t_PERFILES.length > 0 &&
    user.t_PERFILES.some((perfil) => perfil.trim() == "Interno")) ? true : false;
  const filesStatus = useSelector((state) => state.dashboard.file.loading);
  const fileDownloadStatus = useSelector((state) => state.dashboard.downloadFile.loading);
  const filePreviewStatus = useSelector(state => state.dashboard.previewFile.loading);




  useEffect(() => {
    if (preview != null) {
      dispatch(previewFile(preview))
        .then(unwrapResult)
        .then(r => {
          
          setExtension(r.ex)
          if (r.ex.toLowerCase() === "xlsx") {
            const workbook = read(r.data, { type: 'array' });
            const sheetNames = workbook.SheetNames;
            const sheets = sheetNames.map(name => {
              const sheet = workbook.Sheets[name];
              const data = utils.sheet_to_json(sheet);
              return { name: name, data: data };
            });
            setData(sheets[0].data); // Muestra la primera hoja por defecto
            setSheets(sheets); // Almacena todas las hojas en el estado
          }
          else if (r.ex.toLowerCase() === "pdf") {
            const reader = new FileReader();
            const blob = new Blob([r.data], { type: 'application/pdf' });
            reader.readAsArrayBuffer(blob);
            reader.onloadend = () => {
              setPdfFile(reader.result);
              setPageNumber(1); // Reset page number when a new file is selected
            };
          } else {
            setPdfFile(null);
          }
        })
        .catch(err => {
          
          dispatch(SetInformation({
            title: '¡Atención!',
            description: 'El documento solicitado no está disponible en este momento. Por favor, intenta nuevamente más tarde. Si persiste el problema, contacta con el soporte técnico'
          }))
        });
    }
    return () => {
      setPreview(null)
      setPdfFile(null)
    };
  }, [preview, dataa, extension])

  useEffect(() => {
    dispatch(GetFilesPreviewByFolder(dataGetFiles)).then(({ payload }) => {
      if (payload != null && rows.length !== payload.length) {
        let docs = !isInternalUser
          ? payload.filter((doc) => doc.visibilidad != "R")
          : payload;
        setRows(docs);
      }
    });


  }, []);

  const dataGetFiles = {
    id: folderId.split(" ")[0],
    anio: folderId.split(" ")[1],
  };

  function onPreview(title, id) {
    setSelectedIndex(id);
    let d = { url: id, file: title };
    setPreview(d);
  }

  function onDownload(title, id) {
    let d = { url: id, file: title };

    dispatch(downloadFile(d)).then((e)=>{
      if (e.error)
      dispatch(SetInformation({
        title: '¡Atención!',
        description: 'El documento solicitado no está disponible en este momento. Por favor, intenta nuevamente más tarde. Si persiste el problema, contacta con el soporte técnico'
      }))
    })
  }

  const handlePrevSheet = () => {
    if (activeSheetIndex > 0) {
      const prevIndex = activeSheetIndex - 1;
      setActiveSheetIndex(prevIndex);
      setData(sheets[prevIndex] && sheets[prevIndex].data);

    }
  };

  const handleNextSheet = () => {
    if (activeSheetIndex < sheets.length - 1) {
      const nextIndex = activeSheetIndex + 1;
      setActiveSheetIndex(nextIndex);
      setData(sheets[nextIndex].data);
    }
  };

  return (
    <>
      {filesStatus === "pending" || fileDownloadStatus === "pending" || filePreviewStatus == 'pending' ? (
        <LoadingSpinner />
      ) : (
        <Box
          style={{
            display: "flex",
            minWidth: "100vh",
            padding: 0,
          }}
        >
          <List
            component="nav"
            aria-label="main mailbox folders"
            flexdirection="row"
          >
            {rows.length === 0 ? (
              <Typography
                style={{
                  margin: "auto",
                  marginTop: "20px",
                  position: "fixed",
                  left: "42%",
                }}
              >
                No hay archivos para mostrar
              </Typography>
            ) : (
              rows.map((f) => {
                return (
                  <ListItem button key={f.id} selected={selectedIndex === f.id}>
                    {f.rutaFile.split('.')[f.rutaFile.split(".").length - 1].toUpperCase() == "PDF" ? (
                      <BsFileEarmarkPdf
                        size={20}
                        color={Colors.red}
                        style={{ marginRight: "10px" }}
                      />
                    ) : f.rutaFile.split('.')[f.rutaFile.split(".").length - 1].toUpperCase() == "XLS" ||
                      f.rutaFile.split('.')[f.rutaFile.split(".").length - 1].toUpperCase() == "XLSX" ? (
                      <BsFileEarmarkExcel
                        size={20}
                        color={Colors.darkGreen}
                        style={{ marginRight: "10px" }}
                      />
                    ) : (
                      ""
                    )}
                    <ListItemText
                      primary={
                        f.rutaFile.split("/")[f.rutaFile.split("/").length - 1].replace(`-${f.id}`, '')
                      }
                      onClick={() =>
                        onPreview(
                          f.rutaFile.split("/")[
                          f.rutaFile.split("/").length - 1
                          ],
                          f.id
                        )
                      }
                    />
                    <ListItemIcon>
                      <IconButton
                        onClick={() =>
                          onDownload(
                            f.rutaFile.split("/")[f.rutaFile.split("/").length - 1].replace(`-${f.id}`, ''),
                            f.id
                          )
                        }
                      >
                        <BsDownload size={20} />
                      </IconButton>
                    </ListItemIcon>
                  </ListItem>
                );
              })
            )}
          </List>
          {extension.toLowerCase() === "pdf" ? (
            pdfFile && (
              <div className="container_pdf">
                <Document
                  file={pdfFile}
                  onLoadSuccess={(pdf) => { setNumPages(pdf.numPages); setTotalPages(pdf.numPages); }}
                >
                  <Page pageNumber={pageNumber} />
                </Document>
                <div style={{ 'display': 'flex', 'justify-content': 'center', 'align-items': 'center' }}>
                  {pageNumber !== 1 ?
                    <IconButton onClick={() => setPageNumber(prevPage => Math.max(prevPage - 1, 1))}><BsFillArrowLeftCircleFill /></IconButton>
                    :
                    <IconButton disabled><BsFillArrowLeftCircleFill /></IconButton>
                  }

                  {pageNumber}-{totalPages}

                  {
                    pageNumber < totalPages
                      ?
                      <IconButton onClick={() => setPageNumber(prevPage => prevPage + 1)}><BsFillArrowRightCircleFill /></IconButton>
                      :
                      <IconButton disabled><BsFillArrowRightCircleFill /></IconButton>
                  }
                </div>
              </div>
            )
          ) : extension.toLowerCase() === "xlsx" ||
            (extension.toLowerCase() === "xls" &&
              dataa.length > 0 &&
              typeof dataa[0] === "object") ? (

            <div style={{ position: "relative" }}>
              <div className={style.canvas_container}>
                <TableContainer style={{ width: "80%", margin: "auto" }}>
                  <Table
                    style={{ background: "#FFFFFF" }}
                    aria-label="a dense table"
                    border={1}
                  >
                    <TableHead>
                      <TableRow>
                        {dataa[0] != null && dataa[0] != undefined
                          ? Object.keys(dataa[0]).map((key) => (
                            <TableCell key={key}>{key}</TableCell>
                          ))
                          : ""}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataa.map((row, index) => (
                        <TableRow key={index}>
                          {Object.values(row).map((cell, cellIndex) => (
                            <TableCell key={cellIndex}>{cell}</TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <div
                style={{
                  position: "absolute",
                  bottom: "-13%",
                  left: "50%",
                  transform: "translateX(-50%)",
                  textAlign: "center",
                  zIndex: "1",
                }}
              >
                <IconButton onClick={handlePrevSheet} disabled={activeSheetIndex <= 0}>
                  <BsFillArrowLeftCircleFill />
                </IconButton>
                <span>{`${activeSheetIndex + 1}-${sheets.length}`}</span>
                <IconButton onClick={handleNextSheet} disabled={activeSheetIndex >= sheets.length - 1}>
                  <BsFillArrowRightCircleFill />
                </IconButton>
              </div>
            </div>

          ) : (
            ""
          )}
        </Box>
      )}
    </>
  );
}

export default PreviewFileFolder;