import { Box, Button, Grid, Hidden, IconButton, InputAdornment, TextField, Tooltip, Typography, } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Copyright from "../../../components/Copyrigth";
import { ValidateCuit, ValidatePassword } from "../../../helpers/validators";
import { login } from "../../../redux/slices/signInSlice";
import Colors from "../../../theme/colors";
import styles from "../styles";
import { NavegarHome } from "../../../helpers/UsuarioHelper";
import { CuitMask } from "../../../helpers/maskedImputs";
import { SetInformation } from "../../../redux/slices/modalSlice";
import axios from "axios";
import { SetActiveTimer } from "../../../redux/slices/dashboardSlice";
import { IoMdHelpCircleOutline } from "react-icons/io";

const obsBALogo = require("../../../static/color-horizontal.png")

const Login = () => {
  const classes = styles();
  const { handleSubmit, register } = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [leyenda, setLeyenda] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState({
    password: "",
    showPassword: false,
  });

  const [errorPassword, setErrorPassword] = useState(false);
  const [leyendaPassword, setLeyendaPassword] = useState("");

  const handleLoginSuccess = (token, usuario) => {
    dispatch(SetActiveTimer(false));
    localStorage.setItem("token_app", token);
    navigate(NavegarHome(usuario));
  };
  
  const handleLoginError = (response) => {
    dispatch(SetInformation({
      title: '¡Atención!',
      description: response.data
    }));
  };

  const onSubmit = (data) => {
    if (errorPassword) {
      return;
    }
  
    if (username !== "" && data.password !== "") {
      if (ValidateCuit(username)) {
        data.username = username.toString().replaceAll("-", "");
        setUsername(data.username);
  
        dispatch(login(data))
          .then((e) => {
            if (e.error) {
              handleLoginError(e.payload.response);
            } else {
              handleLoginSuccess(e.payload.token, e.payload.usuario);
            }
          });
      }
    }
  };

  const handleClickShowPassword = () => {
    setPassword({ ...password, showPassword: !password.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = (prop) => (event) => {
    setPassword({ ...password, [prop]: event.target.value });
    if (!ValidatePassword(event.target.value)) {
      setErrorPassword(true);
      setLeyendaPassword("La contraseña debe ser alfanumérica y tener al menos 8 dígitos y una mayúscula");
    } else {
      setErrorPassword(false);
      setLeyendaPassword("");
    }
  };

  const onCuitChange = (e) => {
    if (e.target.value && !ValidateCuit(e.target.value)) {
      setError(true);
      setLeyenda("CUIT/CUIL inválido");
    } else {
      setError(false);
      setLeyenda("");
    }

    setUsername(e.target.value);
  };


  useEffect( () => {
    async function d () {
      await axios.get(`${process.env.REACT_APP_OBSBA_API_URL}/Commons/EstadoMantenimiento`)
      .then((e) => {
        if(e.data)
          navigate('/enmantenimiento', {state:{ error: e.data.result }});
      });
      await axios.get(`${process.env.REACT_APP_OBSBA_API_URL}/Commons/check`)
          .then((e) => {
            if(e.data.result != 0)
              navigate('/enmantenimiento', {state:{ error: e.data.result }});
          });
    }
    d();
},[])


  return (
    <Grid container className={classes.root}>
      <Grid
        container
        item
        xs={12}
        md={6}
        elevation={6}
        justifyContent="center"
        alignContent="center"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container justifyContent='center' alignItems='center'>
            <Grid item>
              <Box>
                <img src={obsBALogo} alt="obsBA Logo" className={classes.logo} />
              </Box>
              <Typography className={classes.loginSubtitle}>
                Portal de Gestión de Carpeta Digital
              </Typography>
              <Box display='flex' justifyContent='center'>
                <Typography component="h1" variant="h4" style={{ marginTop: '15px', marginBottom: '15px' }}>
                  Iniciar Sesión
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid item container justifyContent="center" alignContent="center" >
            <TextField
              variant="outlined"
              margin="normal"
              id="idInpCuil"
              label="CUIT/CUIL"
              name="username"
              autoComplete="username"
              autoFocus
              
              required
              className={classes.textField}
              onChange={onCuitChange}
              value={username}
              inputRef={register}
              error={error}
              helperText={leyenda}
              InputProps={{
                inputComponent: CuitMask,
              }}
            />
          </Grid>

          <Grid item container justifyContent="center" alignContent="center">
            <TextField
              variant="outlined"
              margin="normal"
              id="idInpPass"
              name="password"
              label="Contraseña"
              type={password.showPassword ? "text" : "password"}
              autoComplete="current-password"
              
              required
              error={errorPassword}
              helperText={leyendaPassword}
              className={classes.textField}
              inputRef={register}
              onChange={handlePasswordChange("password")}
              inputprops={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {password.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item container justifyContent="center" alignContent="center">
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              id="idBtnIngresar"
              className={classes.submit}
            >
              Ingresar
            </Button>
          </Grid>

          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "5px",
              marginBottom: "40px",
            }}
          >
            <Grid item style={{ textAlign: "right" }}>
              <Link
                to="/forgotPassword"
                variant="body2"
                className={classes.link}
                style={{ textDecoration: "none" }}
              >
                Olvidé mi contraseña <br />
                ¿Desea desbloquear su usuario?
              </Link>
            </Grid>
          </Grid>
          <hr
            style={{
              color: Colors.detailBlack,
              backgroundColor: Colors.detailBlack,
              height: "0.5",
              width: "100%",
            }}
          ></hr>

          <Grid item xs={12}>
            <Typography style={{ fontSize: 24 }} align="center" component="h1" variant="h4">
              ¿No tenés cuenta?
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography align="center" className={classes.subtitle}>
              Si es tu primera vez o necesitás un usuario
            </Typography>
          </Grid>

          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            alignContent="center"
          >
            <Button
              type="button"
              variant="outlined"
              color="primary"
              className={classes.button}
              onClick={() => {
                navigate("/register");
              }}
            >
              REGISTRATE
            </Button>
            <Tooltip
                title={
                  <Typography
                    style={{
                      color: "white",
                      fontSize: "12px",
                      fontWeight: "300",
                    }}
                  >
                    Videotutorial de ingreso y registro
                  </Typography>
                }
              >
                <Button
                  component="a"
                  href="https://www.youtube.com/watch?v=bn3Qk2DACDQ"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "grey",
                    marginTop: "10px",
                  }}
                >
                  <IoMdHelpCircleOutline size={35} />
                </Button>
              </Tooltip>
          </Grid>

          <Grid container item xs={12} justifyContent="center" alignContent="center">
            <Copyright />
          </Grid>
        </form>

      </Grid>
      <Hidden smDown>
        <Grid md={6} className={classes.image}>
        </Grid>
      </Hidden>
    </Grid>
  );
}

export default Login;