import React from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
  CssBaseline,
  Grid,
  Typography,
  Button,
  Hidden,
} from "@material-ui/core";

import {
  validateRegister,
} from "../../../redux/slices/registerSlice";

import styles from "../styles";
import InputTextField from "../../../components/Forms/input";
import { useForm } from "react-hook-form";

const obsBALogo = require("../../../static/color-horizontal.png")

const RegisterComplete = () => {
  const classes = styles();
  const dispatch = useDispatch();
  
  const { cuit, token } = useParams();

  const { control } = useForm({
    defaultValues: {
      cuit: cuit.substring(0, 2) +
      "-" +
      cuit.substring(2, 10) +
      "-" +
      cuit[cuit.length - 1],
    },
  });
  
  React.useEffect(() => {
    let data = {};
    data.cuit = cuit;
    data.token = token;

    dispatch(validateRegister(data));
  }, []);
  
    return (
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid container item xs={12} sm={12} md={6} justifyContent="center">
          <div className={classes.paper}>
            <form className={classes.form} noValidate style={{ width: "30%" }}>
              <img src={obsBALogo} alt="obsBA Logo" className={classes.logo} />
              <Typography className={classes.loginSubtitle}>
                Portal de Gestión de Carpeta Digital
              </Typography>
              <Typography
                component="h1"
                variant="h4"
                style={{ marginTop: "15px", marginBottom: "15px" }}
              >
                Gracias por su Registro
              </Typography>
  
              <InputTextField
                variant="outlined"
                margin="normal"
                id="cuit"
                label="CUIT/CUIL"
                name="cuit"
                autoComplete="CUIT/CUIL"
                fullWidth
                disabled
                control={control}
                InputLabelProps={{
                  shrink: true,
                }}
              />
  
              <Link to="/" style={{ textDecoration: "none" }}>
                <Button
                  type="button"
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                  fullWidth
                >
                  VOLVER
                </Button>
              </Link>
  
              <Grid
                container
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginTop: "5px",
                }}
              >
                <Grid item>
                  <Typography className={classes.label}>
                    ¿Ya tenés cuenta?
                  </Typography>
                </Grid>
                <Grid item>
                  <Link
                    to="/"
                    variant="body2"
                    className={classes.link}
                    style={{
                      textDecoration: "none",
                      fontWeight: "bold",
                      marginLeft: "5px",
                    }}
                  >
                    Iniciar sesión
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
        </Grid>
        <Hidden smDown>
          <Grid md={6} className={classes.image}></Grid>
        </Hidden>
      </Grid>
    );
}

export default RegisterComplete;