import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const folderAPI = createApi({
  reducerPath: "folderApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_OBSBA_API_URL}/folders`
  }),
  endpoints: (builder) => ({
    getFolder: builder.query({
      query: (args) => {
        const { anio, id } = args;
        const token = localStorage.getItem("token_app");
        return { url: `/${anio}/${id}`,
          headers:{"Authorization": `Bearer ${token}` }
         };
      },
      providesTags:["folder.get"]
    }),
    sendFolder: builder.mutation({
      query: (args) =>{
        const token = localStorage.getItem("token_app");
        return({
          method: "PUT",
          url: "/UpdateState",
          body: args,
          headers:{"Authorization": `Bearer ${token}` }
        })
      },
      invalidatesTags: ["folder.get"]
    }),
    updateFolder: builder.mutation({
      query: (args) =>{
        const token = localStorage.getItem("token_app");
        return({
          headers:{"Authorization": `Bearer ${token}` },
          method: "PUT",
          body: args
        })
      },
      invalidatesTags: ["folder.get"]
    })
  }),
});

export const { useGetFolderQuery, useSendFolderMutation, useUpdateFolderMutation } = folderAPI;
