import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DownloadPayslips, GetPayslips, PreviewPayslip } from "../../../redux/slices/dashboardSlice";
import LoadingSpinner  from "../../../components/LoadingSpinner";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { BsDownload, BsFileEarmarkPdf } from 'react-icons/bs';
import Colors from './../../../theme/colors';
import { unwrapResult } from "@reduxjs/toolkit";
import { Document, Page, pdfjs } from "react-pdf";
import "./index.css";
import { SetInformation } from "../../../redux/slices/modalSlice";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export function PayslipsPreview() {
  const dispatch = useDispatch();

  const payslipsStatus = useSelector((state) => state.dashboard.payslips.loading);
  const payslipsDownloadStatus = useSelector((state) => state.dashboard.downloadPayslips.loading);
  const previewStatus = useSelector((state) => state.dashboard.previewPayslips.loading);

  const dataFilter = useSelector((state) => state.dashboard.filterPayslips);

  const [rows, setRows] = useState([]);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfFile, setPdfFile] = useState(null);

  const [selectedIndex, setSelectedIndex] = useState(null);

  function saveDataFilterToLocalStorage(dataFilter) {
    localStorage.setItem("dataFilter", JSON.stringify(dataFilter));
  }

  useEffect(() => {
    const dataFilterFromLocalStorage = localStorage.getItem("dataFilter");
    let updatedDataFilter = { ...dataFilter, pageSize: 0, pageNumber: 0 };
    if (dataFilterFromLocalStorage) {
      updatedDataFilter = JSON.parse(dataFilterFromLocalStorage);
    }

    dispatch(GetPayslips(updatedDataFilter)).then(({ payload }) => {
      setRows(payload.listaResultado);
    });

    saveDataFilterToLocalStorage(updatedDataFilter);

    return () => {
      localStorage.removeItem("dataFilter");
    }
  }, [dataFilter, dispatch]);

  useEffect(() => {
    if (!previewUrl) return;
    dispatch(PreviewPayslip({ filePath: previewUrl }))
      .then(unwrapResult)
      .then(data => {
        const reader = new FileReader();
        const blob = new Blob([data], { type: 'application/pdf' });
        reader.readAsArrayBuffer(blob);
        reader.onloadend = () => {
          setPdfFile(reader.result);
          setPageNumber(1);

        };
      }).catch((err) => {
        dispatch(SetInformation(
          {
            title:'Error',
            description: 'No se puede llevar acabo la acción solicitada. Por favor, intente nuevamente más tarde.'
          }
        ));
      });
  }, [previewUrl]);

  useEffect(() => {
    if (payslipsDownloadStatus == "error") {
      dispatch(SetInformation(
        {
          title:'Error',
          description: 'No se puede llevar acabo la acción solicitada. Por favor, intente nuevamente más tarde.'
        }
      ));
    }
  }, [payslipsDownloadStatus]);

  const onPreview = (url, id) => {
    setSelectedIndex(id);
    setPreviewUrl(url);
  };

  const onDownload = async (url, id) => {
    setSelectedIndex(id);
    try {
      let fileName = url.match(/\/([^/]+)$/)[1];
      await dispatch(DownloadPayslips({ filePath: url, fileName: fileName }));
      dispatch(SetInformation(
        {
          title:'¡Exito!',
          description:"Archivo descargado"
        }
      ));
    } catch (error) {
      dispatch(SetInformation(
        {
          title:'¡ERROR!',
          description:"Archivo no disponible"
        }
      ))
    }
  };

  return (
    <>
      {
        payslipsStatus == "pending" || payslipsDownloadStatus == "pending" || previewStatus == "pending" ?
          <LoadingSpinner />
          :
          <Box style={{
            display: "flex",
            minWidth: "600px",
            width: "100%",
            padding: 0,
          }}
          >
            <List>
              {
                rows.map((f) => {
                  return (
                    <ListItem button key={f.id} selected={selectedIndex === f.id}>
                      <BsFileEarmarkPdf
                        size={20}
                        color={Colors.red}
                        style={{ marginRight: "10px" }}
                      />

                      <ListItemText
                        primary={f.urlstorage.split('/').pop() || ''}
                        onClick={() => onPreview(f.urlstorage, f.id)}
                      />

                      <ListItemIcon>
                        <IconButton
                          onClick={() => onDownload(f.urlstorage, f.id)}
                        >
                          <BsDownload size={20} />
                        </IconButton>
                      </ListItemIcon>
                    </ListItem>
                  )
                })
              }
            </List>
            {previewUrl &&
              <div className="container_pdf">
                {pdfFile && (
                  <Document
                    file={pdfFile}
                  >
                    <Page pageNumber={pageNumber} />
                  </Document>
                )}
              </div>
            }
          </Box>
      }
    </>
  );
}